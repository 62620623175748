/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

export interface IClientInformation {
  name: string;
  client_id: number;
  rpt_currency: string;
}

interface IAllClientsState {
  allClientsLoader: boolean,
  allClients: IClientInformation[],
  loaded: boolean,
  error: string,
}

const initialallClientsState: IAllClientsState = {
  allClientsLoader: false,
  allClients: [],
  loaded: false,
  error: "",
};

export const fetchallClients = createAsyncThunk(
  "allClients/fetchallClients",
  () => {
    const ethanToken = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/client/`, {
        headers: {
          Accept: "*/*",
          Authorization: (ethanToken as unknown) as string,
        },
      })
      .then((response) => response.data);
  }
);
const allClientsSlice = createSlice({
  name: "allClients",
  initialState: initialallClientsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchallClients.pending, (state) => {
      state.allClientsLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchallClients.fulfilled, (state, action) => {
      state.allClientsLoader = false;
      state.allClients = action.payload;
      state.loaded = true;
      state.error = "";
    });
    builder.addCase(fetchallClients.rejected, (state, action) => {
      state.allClientsLoader = false;
      state.allClients = [];
      state.loaded = false;
      state.error = action.error.message || "";
    });
  },
});

export default allClientsSlice.reducer;
