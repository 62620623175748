/* eslint-disable import/no-unresolved */
import React from "react";
import { Provider } from "react-redux";
import { init as initApm } from "@elastic/apm-rum";
import enUSIntl from "antd/es/locale/en_US";
import { ConfigProvider } from "antd";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import ProLayout from "layouts/ProLayout";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./routes";
import store from "./redux/app/store";

import "./Global.css";

const queryClient = new QueryClient();

// initApm({
//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: "relationship-dashboard",
//   serverUrl:
//     "https://92d75d412c9141f7acb2f2420253639f.apm.us-east-2.aws.elastic-cloud.com:443",
//   environment: process.env.REACT_APP_ENV || "local",
// });


const App = () => (
  <CookiesProvider>
    <Provider store={store}>
      <ConfigProvider locale={enUSIntl}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <ProLayout>
              <QueryClientProvider client={queryClient}>
                <AppRouter />
              </QueryClientProvider>
            </ProLayout>
          </BrowserRouter>
        </ChakraProvider>
      </ConfigProvider>
    </Provider>
  </CookiesProvider>
);

export default App;
