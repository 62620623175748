/* eslint-disable import/newline-after-import */
import './passwordStrength.css';
// eslint-disable-next-line import/no-named-as-default
import Parameter from './components/parameter';
const PasswordStrength = ({checks}) => {
  const {length,upper,lower,number,special} = checks;
  return(
    <div className="strength-container">
      <span className='strength-header'>Your password must have</span>
      <div className="parameters">
        <div className="parameter-col">
          <Parameter text="Minimum of 8 characters" value={length}/>
          <Parameter text="One uppercase character" value={upper}/>
        </div>
        <div className="parameter-col">
          <Parameter text="One lowercase character" value={lower}/>
          <Parameter text="One number" value={number}/>
          <Parameter text="One special character" value={special}/>
        </div>
      </div>
    </div>
  )
}

export default PasswordStrength;