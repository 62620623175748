/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { XMLParser } from "fast-xml-parser";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialtableauViewListState : any = {
  tableauViewListLoader: false,
  tableauViewList: [],
  error: "",
};

export const fetchtableauViewList = createAsyncThunk(
  "tableauViewList/fetchtableauViewList",
  ({ tableauToken, tableauSiteID } :  { tableauToken: string, tableauSiteID : string}) => fetch(
    `https://reports.ethan-ai.com/api/3.12/sites/${tableauSiteID}/views?filter=tags:in:[web,Web]`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "X-Tableau-Auth": tableauToken,
      },
    }
  )
    .then((response) => response.text())
    .then((data) => {
      const options = {
        ignoreAttributes: false,
        attributeNamePrefix: "",
      };
      const parser = new XMLParser(options);
      const jsonObj = parser.parse(data);
      const jsonView = jsonObj.tsResponse.views;
      jsonView.view = jsonView?.view?.length > 0 ? jsonView.view : [jsonView.view];
      return jsonView.view;
    })
);
const tableauViewListSlice = createSlice({
  name: "tableauViewList",
  initialState: initialtableauViewListState,
  reducers: {
    update: (state, action) => {
      state.tableauViewList[action.payload.attribute] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchtableauViewList.pending, (state) => {
      state.tableauViewListLoader = true;
    });
    builder.addCase(fetchtableauViewList.fulfilled, (state, action) => {
      state.tableauViewListLoader = false;
      state.tableauViewList = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtableauViewList.rejected, (state, action) => {
      state.tableauViewListLoader = false;
      state.tableauViewList = [];
      state.error = action.error.message;
    });
  },
});

export default tableauViewListSlice.reducer;
export const { update } = tableauViewListSlice.actions;
