import { Tag } from "antd";

export enum PROCESS_STATUS {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}



export const processStatus = {
  [PROCESS_STATUS.NOT_STARTED]: {
    color: 'volcano',
    text: 'Not Started',
  },
  [PROCESS_STATUS.PENDING]: {
    color: 'blue',
    text: 'Pending',
  },
  [PROCESS_STATUS.IN_PROGRESS]: {
    color: 'blue',
    text: 'In Progress',
  },
  [PROCESS_STATUS.COMPLETED]: {
    color: 'green',
    text: 'Completed',
  },
  [PROCESS_STATUS.FAILED]: {
    color: 'red',
    text: 'Failed',
  },
};


export default (props: { status: PROCESS_STATUS }) => {
  const { status } = props;
  if(!processStatus?.[status]){
    return <p>{status}</p>;
  }
  return (
    <Tag color={processStatus?.[status]?.color}>
      {processStatus?.[status]?.text}
    </Tag>
  );
}
