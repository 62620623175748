/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import { getCookie } from "redux/utils/helpers";


const initialRPT = {
  loader: false,
  rpt: "",
  error: "",
};

export const clientCcy = createAsyncThunk(
  "client_ccy/",
  async() => {
    const ethan_token = getCookie("ethan_token");
    const response= await  axios.get(`${TRANSACTIONS_API_ROOT}/client`,{
      headers: {
        "Accept": '*/*',
        "Authorization": ethan_token,
      },
    })
    
    const data = await response.data;
    const obj = {}
    data.forEach(item => {
      obj[item?.client_id] = item?.rpt_currency
    })

    return obj;
  }

  
);
const clientCCySlice = createSlice({
  name: "clientCCy",
  initialState: initialRPT,
  extraReducers: (builder) => {
    builder.addCase(clientCcy.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(clientCcy.fulfilled, (state, action) => {
      state.loader = false;
      state.rpt = action.payload;
      state.error = "";
    });
    builder.addCase(clientCcy.rejected, (state, action) => {
      state.loader = false;
      state.rpt = "";
      state.error = action.error.message;
    });
  },
});

export default clientCCySlice.reducer;