export const badgeStyles = {
  components: {
    Badge: {
      sizes: {
        md: {
          width: "65px",
          height: "25px"
        }
      },
      baseStyle: {
        textTransform: "capitalize"
      }

    }
  }
}