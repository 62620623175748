import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EthanTrackerEvent } from "../../../redux/utils/helpers";
import CustomButton from "../../../components/custom-button/custom-button";
import BrandingComponent from "./BrandingComponent";
import styles from "./Auth.module.css";

const ResetPasswordComponent = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const { getUser } = props;

  const handleReset = (e) => {
    e.preventDefault();
    EthanTrackerEvent({
      category: "User",
      action: "Reset Password",
      label: "Reset Password",
    });
    getUser(username);
  };

  return (
    <div className={styles.parent}>
      <BrandingComponent />
      <div className={styles.auth__section}>
        <div className={styles.welcome__section}>
          <span className={styles.title__regular}>
            Welcome to <span className={styles.title__bold}>ethan</span>{" "}
          </span>
          <span className={styles.subtitle}>Please enter your credentials</span>
        </div>
        <div className={styles.credentials__section}>
          <form className={styles.auth__form}>
            <input
              className={styles.auth__input}
              type="text"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Enter Username or Email ID"
            />
            <div className={styles.group__box}>
              <CustomButton
                buttonType="authButton"
                onClick={(e) => handleReset(e)}
              >
                Set New Password
              </CustomButton>
              <button
                className={styles.redirection__btn}
                type="submit"
                onClick={() => {
                  EthanTrackerEvent({
                    category: "Window Switch",
                    action: "/sign-in",
                    label: "/sign-in",
                  });
                  navigate("/sign-in");
                }}
              >
                Try Login Again?{" "}
                <span className={styles.redirection__bold}>Log In </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
