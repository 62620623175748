/* eslint-disable no-alert */
/* eslint-disable import/no-unresolved */
// /* eslint-disable no-console */
// /* eslint-disable no-alert */
// /* eslint-disable no-underscore-dangle */
// /* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import axios from "axios";
import { AUTH_HOST, RELATIONSHIP_HOST } from "api/api-config";
import { setCookie } from "redux/utils/helpers";
import { message } from "antd";
import OtpComponent from "./components/OtpComponent";
import ResetPasswordComponent from "./components/ResetPassword";
import NewPasswordComponent from "./components/Newpassword/NewPassword";

const ResetPassword = () => {
  const [code, setCode] = useState("");
  const [verified, setVerified] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [validated, setValidated] = useState(false);

  const verifyCode = async (otp) => {
    try {
      const {data, status} = await axios.post(`${AUTH_HOST}/api/verify-otp`, {
        otp,
        user_id: code,
      });
      if (status === 200) {
        setOtpVerified(true);
        // acess token should expire in 1 hours
        setCookie("access_token", data?.access_token, 1/24); // Set access token cookie for 1 hour
        setCookie("refresh_token", data?.refresh_token, 5); // Set refresh token cookie for 2 days
        setCookie("ethan_token", `Bearer ${data?.access_token}`,1/24); // Set Ethan token cookie for 1 hour        
      }
      else{
        message.error("OTP verification failed, Please try again!");
      }
    } catch (error) {
      message.error("OTP Entered is Incorrect");
    }

   
  };

  const getUser = async (user) => {
    try {
      const response = await axios.post(
        `${AUTH_HOST}/api/forgot-password`, 
        {
          username: user,
        }
      );
      setCode(response?.data?.user_id)
      setVerified(true);
    } catch (error) {
      message.error("Login Credentials are Incorrect, Please Try again!");
    }

  };

  const setNewPassword = async (pass, cpass) => {
    try {
      await axios.post(`${AUTH_HOST}/api/reset-password`, {
        new_password: pass,
        confirm_new_password: cpass,
      });
      setValidated(true);
    } catch (error) {
      setValidated(false);
      message.error("Password Reset Failed, Please Try again!");
    }
  };

  return (
    <div>
      {!otpVerified && !verified ? (
        <ResetPasswordComponent getUser={getUser} />
      ) : null}
      {verified && !otpVerified ? (
        <OtpComponent verifyOtp={verifyCode} />
      ) : null}
      {otpVerified ? (
        <NewPasswordComponent
          setNewPassword={setNewPassword}
          validated={validated}
        />
      ) : null}
    </div>
  );
};

export default ResetPassword;
