/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "redux/utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialtableauFirstTicketState = {
  tableauFirstTicketLoader: false,
  tableauFirstTicket: "",
  error: "",
};

export const fetchtableauFirstTicket = createAsyncThunk(
  "tableauFirstTicket/fetchtableauFirstTicket",
  (tableauCred) => fetch(`${TRANSACTIONS_API_ROOT}/tableau/`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Authorization": getCookie("ethan_token"),
    },
    body: JSON.stringify({
      username: tableauCred.credentials.name,
      site_id: tableauCred.credentials.site.contentUrl,
    }),
  })
    .then((response) => response.json())
    .then((data) => data.ticket)
);
const tableauFirstTicketSlice = createSlice({
  name: "tableauFirstTicket",
  initialState: initialtableauFirstTicketState,
  extraReducers: (builder) => {
    builder.addCase(fetchtableauFirstTicket.pending, (state) => {
      state.tableauFirstTicketLoader = true;
    });
    builder.addCase(fetchtableauFirstTicket.fulfilled, (state, action) => {
      state.tableauFirstTicketLoader = false;
      state.tableauFirstTicket = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtableauFirstTicket.rejected, (state, action) => {
      state.tableauFirstTicketLoader = false;
      state.tableauFirstTicket = "";
      state.error = action.error.message;
    });
  },
});

export default tableauFirstTicketSlice.reducer;
