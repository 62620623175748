/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialfirstLevelYtdState = {
  firstLevelYtdLoader: false,
  firstLevelYtd: [],
  error: "",
};
export const fetchfirstLevelYtd = createAsyncThunk(
  "firstLevelYtd/fetchfirstLevelYtd",
  ({ start_date, end_date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/portfolio_movement/`,
        {
          start_date,
          end_date,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const firstLevelYtdSlice = createSlice({
  name: "firstLevelYtd",
  initialState: initialfirstLevelYtdState,
  extraReducers: (builder) => {
    builder.addCase(fetchfirstLevelYtd.pending, (state) => {
      state.firstLevelYtdLoader = true;
    });
    builder.addCase(fetchfirstLevelYtd.fulfilled, (state, action) => {
      state.firstLevelYtdLoader = false;
      state.firstLevelYtd = action.payload;
      state.error = "";
    });
    builder.addCase(fetchfirstLevelYtd.rejected, (state, action) => {
      state.firstLevelYtdLoader = false;
      state.firstLevelYtd = [];
      state.error = action.error.message;
    });
  },
});

export default firstLevelYtdSlice.reducer;
