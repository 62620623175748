import { DrawerForm, ProDescriptions, ProFormText } from '@ant-design/pro-components';
import React from 'react'


interface DrawerProps{
    drawerVisit: any,
    setDrawerVisit?: (value: boolean) => void;
    data:any
}

function TradeDrawer(props: DrawerProps) {
  const {drawerVisit,setDrawerVisit,data}=props;
  return (
    <DrawerForm
      onOpenChange={setDrawerVisit}
      open={drawerVisit}
      onFinish={async (value) => 
        true
      }
      width={500}
    //   formRef={ref}
    >
      {Object.keys(data).map((item:string)=> (
        <ProDescriptions  bordered size='small' column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <ProDescriptions.Item label={item.toUpperCase().split('_').join(' ')} span={0} > {data[item]} </ProDescriptions.Item>
        </ProDescriptions>
          
      ))}
        
    </DrawerForm>
  )
}

export default TradeDrawer