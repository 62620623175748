/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialstructuredProductsState = {
  structuredProductsLoader: true,
  structuredProducts: [],
  error: "",
};

export const fetchstructuredProducts = createAsyncThunk(
  "structuredProducts/fetchstructuredProducts",
  () => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/structured_product/`, {
        headers: {
          "Authorization": ethan_token,
        },
      })
      .then((response) => response.data)
  }
);

const structuredProductsSlice = createSlice({
  name: "structuredProducts",
  initialState: initialstructuredProductsState,
  extraReducers: (builder) => {
    builder.addCase(fetchstructuredProducts.pending, (state) => {
      state.structuredProductsLoader = true;
    });
    builder.addCase(fetchstructuredProducts.fulfilled, (state, action) => {
      state.structuredProductsLoader = false;
      state.structuredProducts = action.payload;
      state.error = "";
    });
    builder.addCase(fetchstructuredProducts.rejected, (state, action) => {
      state.structuredProductsLoader = false;
      state.structuredProducts = [];
      state.error = action.error.message;
    });
  },
});

export default structuredProductsSlice.reducer;
