/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
// import { getCookie } from "redux/utils/helpers";


const initialCustodian = {
  loader: false,
  custodian: "",
  error: "",
};

export const selectedCustodian = createAsyncThunk(
  "selected_custodian/id",
  // eslint-disable-next-line arrow-body-style
  async(id:any) => {
    return id
  }

  
);
const selectedCustodianSlice = createSlice({
  name: "selected_custodian",
  initialState: initialCustodian,
  extraReducers: (builder) => {
    builder.addCase(selectedCustodian.pending, (state:any) => {
      state.loader = true;
    });
    builder.addCase(selectedCustodian.fulfilled, (state:any, action:any) => {
      state.loader = false;
      state.custodian = action.payload;
      state.error = "";
    });
    builder.addCase(selectedCustodian.rejected, (state:any, action:any) => {
      state.loader = false;
      state.custodian = "";
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default selectedCustodianSlice.reducer;