/* eslint-disable import/no-unresolved */
// library imports
import { ReactElement, useEffect, useState } from "react";
import { AppDispatch, useAppDispatch, useAppSelector } from "redux/app/store";
import { Form, Menu, Select, Typography } from "antd";

// redux store import
import {
  IClientInformation,
  fetchallClients,
} from "redux/features/uploads/allClientSlice";

// imports from source files
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import "./Blotter.css";
import { appFetch } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "components/PreLoader/PreLoader";
import { netWorthLastDate } from "redux/features/summary/newNetworth/networthDateSlice";
import {
  IActivePositionFetchPayload,
  IActivePositionFilterParams,
  IActivePositionQueryResponse,
  IActivePositionResponseRaw,
  IActivePositionTable,
  IAssetClassListResponse,
  IBlotterTrade,
  IBlotterTradeQueryResponse,
  IBlotterTradeTable,
  ICustodianInformation,
  IRelationInformation,
  ISecurityObject,
} from "./interfaces";

import BlotterTradesPage from "./pages/TradesPage";
import ActivePositionsPage from "./pages/ActivePositionsPage";
import {
  INDEX_POSITIONS_PAGE,
  INDEX_TRADE_PAGE,
  indexPageTabs,
} from "./constants";

const BlotterV2 = () => {
  // store client list, custodian list, client relations based on selected client
  const [
    selectedClient,
    setSelectedClient,
  ] = useState<IClientInformation | null>(null);
  const [clientList, setClientList] = useState<IClientInformation[]>([]);
  const [custodianList, setCustodianList] = useState<ICustodianInformation[]>(
    []
  );
  const [assetClassList, setAssetClassList] = useState<string[]>([]);
  const [clientRelations, setClientRelations] = useState<
    IRelationInformation[]
  >([]);
  const [securities, setSecurities] = useState<ISecurityObject[]>([]);

  const netWorthLastDateValue = useSelector(
    (state: any) => state?.networthDateReducer?.networthDate?.last_date
  );

  const showLoader = useSelector(
    (state: any) => state?.networthDateReducer?.lastDateLoader
  );

  // fetch and store active positions
  const [activePositions, setActivePositions] = useState<
    IActivePositionTable[]
  >([]);
  const [totalPositionsCount, setTotalPositionsCount] = useState<number>(0);
  const [
    positionFilterActive,
    setPositionFilterActive,
  ] = useState<IActivePositionFilterParams>();

  // fetch and store blotter trades
  const [blotterTrades, setBlotterTrades] = useState<IBlotterTradeTable[]>([]);
  const [blotterTradesCount, setTotalBlotterTrades] = useState<number>(0);

  // default values to decide the current page number
  // could not use TS enums because ant tabs require string value
  const [currentPage, setCurrentPage] = useState<string>(INDEX_POSITIONS_PAGE);

  // redux store; fetch clients once if not fetched yet, minimizes requests to the backend
  const allClients = useAppSelector((state) => state.allClients.allClients);
  const dispatch = useAppDispatch();
  const dispatch2 = useDispatch<AppDispatch>();

  // fetch custodian list
  const fetchCustodianList = () =>
    appFetch(`${TRANSACTIONS_API_ROOT}/custodian/`, {
      client_id: selectedClient?.client_id,
    });

  // fetch relations list
  const fetchClientRelations = () =>
    appFetch(`${TRANSACTIONS_API_ROOT}/relationship/`, {
      client_id: selectedClient?.client_id,
    });

  const fetchSecuritites = () =>
    appFetch(`${TRANSACTIONS_API_ROOT}/security/`, {
      client_id: selectedClient?.client_id, // removed custodian filter because of CRM issues for securities
    });

  const fetchAssetClasses = () =>
    appFetch(`${TRANSACTIONS_API_ROOT}/position_active/asset-classes`, {
      client_id: selectedClient?.client_id,
    });

  const updateActivePositions = async (
    payload: IActivePositionFetchPayload
  ) => {
    const res: IActivePositionQueryResponse = await appFetch(
      `${TRANSACTIONS_API_ROOT}/position_active/`,
      { ...payload, client_id: selectedClient?.client_id }
    );
    if (res) {
      setActivePositions(
        res.results.map((position: IActivePositionResponseRaw) => ({
          customerAccount: position.useraccount || position.relationship_number, // use relationship number when useraccount is not available
          securityID: position.security_id,
          securityName: position.sec_name,
          quantity: Number(position.position_qty),
          price: position.mtm_price,
          costPrice: position.position_avg_price,
          unrealizedPNL: position.unrealisedpl,

          // TODO: fix naming
          mtmLocal: position.mtm_local_ccy,
          mtmTpy: position.mtm_rpt_ccy,

          positionID: position.position_id,
          currency: position.ccy,
          custodian: position.custodian,
          assetClass: position.asset_class,
          custodianID: position.custodian_id,
          isin: position.security_id, // TODO isin should be returned from the backend
          meta: position.meta,
        }))
      );
      return res.count;
    }
    return -1;
  };

  // fetch blotter trades (paginated)
  const fetchBlotterTrades = (page: number) =>
    appFetch(`${TRANSACTIONS_API_ROOT}/trades/`, {
      client: selectedClient?.client_id,
      page,
    });

  // paginated fetch trades logic
  const updateBlotterTrades = async (page: number) => {
    const res: IBlotterTradeQueryResponse = await fetchBlotterTrades(page);
    if (res)
      setBlotterTrades(
        res.results.map((blotterTrade: IBlotterTrade) => ({
          ...blotterTrade,
          tags:
            blotterTrade.meta && blotterTrade.meta.tags
              ? blotterTrade.meta.tags
              : [],
        }))
      );
    return res.count;
  };

  useEffect(() => {
    // Other Redux calls...
    if (!netWorthLastDateValue) {
      dispatch2(netWorthLastDate(undefined));
    }
  }, []);

  function renderPage(page: string): ReactElement {
    if (page === INDEX_TRADE_PAGE) {
      return (
        <BlotterTradesPage
          blotterTrades={blotterTrades}
          updateBlotterTrades={updateBlotterTrades}
          totalBlotterTrades={blotterTradesCount}
        />
      );
    }

    return (
      <ActivePositionsPage
        selectedClient={selectedClient}
        custodianList={custodianList}
        clientRelations={clientRelations}
        assetClassList={assetClassList}
        activePositions={activePositions}
        updateActivePositions={updateActivePositions}
        totalPositionsCount={totalPositionsCount}
        setTotalPositionsCount={setTotalPositionsCount}
        updateBlotterTrades={updateBlotterTrades}
        securities={securities}
        positionFilterActive={positionFilterActive}
        setPositionFilterActive={setPositionFilterActive}
      />
    );
  }

  // TODO error handling on dispatch failure
  useEffect(() => {
    if (!allClients.length) {
      dispatch(fetchallClients());
    }
  }, []);

  // TODO maybe use a "dispatch completed?" logic here instead of checking for changes to allClients state?
  useEffect(() => {
    setClientList(allClients);
  }, [allClients]);

  // fetch information based on selected client change
  useEffect(() => {
    if (selectedClient) {
      // TODO cache these requests as well, define types for responses too
      fetchCustodianList().then((res) => {
        if (res) setCustodianList(res);
      });
      fetchClientRelations().then((res) => {
        if (res) setClientRelations(res);
      });
      fetchSecuritites().then((res) => {
        if (res) setSecurities(res);
      });
      fetchAssetClasses().then((res: IAssetClassListResponse | undefined) => {
        if (res) setAssetClassList(res.asset_class_list);
      });
      updateActivePositions({ page: 1 }).then((totalCount) =>
        setTotalPositionsCount(totalCount)
      );
      updateBlotterTrades(1).then((totalCount) =>
        setTotalBlotterTrades(totalCount)
      );
    }
  }, [selectedClient]);

  if (showLoader) {
    return <PreLoader isLoaderOpen={showLoader} />;
  }

  return (
    <div>
      <Typography.Title>Blotter Module</Typography.Title>

      <Typography.Title level={2}>Client</Typography.Title>
      <div style={{ marginBottom: "20px" }}>
        <Form.Item>
          <Select
            showSearch
            placeholder="Select Client..."
            onChange={(index) => setSelectedClient(clientList[index])}
          >
            {clientList.map((client, index) => (
              <Select.Option value={index} key={client.client_id}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      {selectedClient && (
        <Menu
          onClick={(e) => setCurrentPage(e.key)}
          selectedKeys={[currentPage.toString()]}
          mode="horizontal"
          items={indexPageTabs}
          style={{ marginBottom: "20px" }}
        />
      )}

      {renderPage(currentPage)}
    </div>
  );
};

export default BlotterV2;
