import { useState } from "react";
import { ProFormSelect } from "@ant-design/pro-components";
import { Typography } from "antd";
import { getCookie } from "redux/utils/helpers";
import request from "axios";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { useNavigate } from "react-router-dom";
import { IClient } from "../Upload/Components/FormFields/ClientFormField";
import cms from "./cms.png";

const Cms = () => {
  const navigate = useNavigate();
  const changeClientName = (value: any) => {
    navigate(`/crm/${value}`);
  };

  const ethanToken = getCookie("ethan_token");

  const config = {
    headers: {
      Accept: "*/*",
      Authorization: (ethanToken as unknown) as string,
    },
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography.Title>Client Relationship Management</Typography.Title>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3em",
        }}
      >
        <img src={cms} alt="cms" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3}>Please select a client</Typography.Title>
          <ProFormSelect
            style={{ width: "600px" }}
            name="client"
            request={async () => {
              const response = await request.get(
                `${TRANSACTIONS_API_ROOT}/api/client/`,
                config
              );
              const items = response.data.map((client: IClient) => ({
                value: client.name,
                label: client.name,
              }));
              return items;
            }}
            fieldProps={{
              onChange: changeClientName,
            }}
            placeholder="Select a client"
          />
        </div>
      </div>
    </div>
  );
};

export default Cms;
