/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialYtdPerformance = {
  ytdPerformanceLoader: false,
  ytdPerformance: [],
  error: "",
};
export const fetchytdPerformance = createAsyncThunk(
  "ytdPerformance/fetchytdPerformance",
  ({ startDate, lastDate }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/performance/?start_date=${startDate}&end_date=${lastDate}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const ytdPerformanceSlice = createSlice({
  name: "ytdPerformance",
  initialState: initialYtdPerformance,
  extraReducers: (builder) => {
    builder.addCase(fetchytdPerformance.pending, (state) => {
      state.ytdPerformanceLoader = true;
    });
    builder.addCase(fetchytdPerformance.fulfilled, (state, action) => {
      state.ytdPerformanceLoader = false;
      state.ytdPerformance = action.payload;
      state.error = "";
    });
    builder.addCase(fetchytdPerformance.rejected, (state, action) => {
      state.ytdPerformanceLoader = false;
      state.ytdPerformance = [];
      state.error = action.error.message;
    });
  },
});

export default ytdPerformanceSlice.reducer;
