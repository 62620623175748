/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialtableauCredState = {
  tableauCredLoader: false,
  tableauCred: {},
  error: "",
};

export const fetchtableauCred = createAsyncThunk(
  "tableauCred/fetchtableauCred",
  (tableauID) => axios
    .get(
      `https://login-server.ethan-ai.com/api/tableau/?tableau_id=${tableauID}`
    )
    .then((response) => response.data)
    .then((data) => {
      const obj = {};
      const cred = {};
      cred.name = data.response[data.response.length - 1].user_id;
      cred.password = data.response[data.response.length - 1].password;
      cred.site = {
        contentUrl: data.response[data.response.length - 1].sitename,
      };
      obj.credentials = cred;
      return obj;
    })
);
const tableauCredSlice = createSlice({
  name: "tableauCred",
  initialState: initialtableauCredState,
  extraReducers: (builder) => {
    builder.addCase(fetchtableauCred.pending, (state) => {
      state.tableauCredLoader = true;
    });
    builder.addCase(fetchtableauCred.fulfilled, (state, action) => {
      state.tableauCredLoader = false;
      state.tableauCred = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtableauCred.rejected, (state, action) => {
      state.tableauCredLoader = false;
      state.tableauCred = {};
      state.error = action.error.message;
    });
  },
});

export default tableauCredSlice.reducer;
