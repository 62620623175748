/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ProCard,
  ProList,
  ProFormDatePicker,
} from "@ant-design/pro-components";
import {
  Breadcrumb,
  Col,
  Divider,
  message,
  Row,
  Space,
  Statistic,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import RcResizeObserver from "rc-resize-observer";
import { getCookie } from "redux/utils/helpers";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import {
  CurrencyFlag,
  CurrencyFlagSymbol,
} from "views/Dashboard/Tables/statementPosition/utils";
import {
  CurrencyComponent,
  NumberValueShortComponent,
} from "views/Dashboard/Tables/statementPosition/components/CurrencyComponent";
import { MiniNetWorthPie } from "views/Dashboard/Tables/statementPosition/components/MiniNetWorthPie";
import { useStatePersist } from "use-state-persist";
import { useDispatch, useSelector } from "react-redux";
import request from "axios";
import { selectClientPositionStatement } from "redux/features/positionStatements/positionStatementSelector";
import {
  setPositionStatement,
  setClientList,
} from "redux/features/positionStatements/positionStatement.actions";
import { fetchLastDate } from "redux/features/summary/statistics/lastDateSlice";
import { AppDispatch } from "redux/app/store";
import PreLoader from "components/PreLoader/PreLoader";
import { netWorthLastDate } from "redux/features/summary/newNetworth/networthDateSlice";
import PositionList from "./components/PositionList";

const nameMapping = {
  net_worth: "Net Worth",
  assets: "Assets",
  liabilities: "Liabilities",
};

function StatementPositions() {
  const netWorthLastDateValue= useSelector((state:any) => state?.networthDateReducer?.networthDate?.last_date);
  // const [showLoader,setShowLoader]=React.useState<boolean>(true);
  const showLoader = useSelector((state:any) => state?.networthDateReducer?.lastDateLoader);
  const [responsive, setResponsive] = useState<any>(false);
  const [mockData, setMockData] = useState<any>([]);
  const ethanToken = getCookie("ethan_token");
  const [returnData, setReturnData] = useState<any>(1);
  const [clientId, setClientId] = useState<any>(null);
  const [clientname, setClientName] = useState<any>();
  const [firstDateOfAllStatement, setFirstDateOfAllStatement] = useState<any>(
    ""
  );
  const [lastDateOfAllStatement, setLastDateOfAllStatement] = useState<any>("");
  const [startOfMonth, setStartOfMonth] = useState<any>("");
  const [endOfMonth, setEndOfMonth] = useState<any>("");
  const [clientCurrency, setClientCurrency] = useState<any>("");
  const [filterValue, setFilterValue] = useStatePersist<any>(
    "@PositionListTableFilterValue",
    {}
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<any>();
  const [tableLoader, setTableLoader] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();
  const clientPositionData = useSelector(selectClientPositionStatement);

  const fetchCompanyData = async () => {

    const response = await request.get(
      `${TRANSACTIONS_API_ROOT}/positions/statements/overview/`,
      {
        headers: {
          Accept: "*/*",
          "Authorization": (ethanToken as unknown) as string,
        },
        params: {
          month_start_date: startOfMonth,
          month_end_date: endOfMonth,
        },
      }
    );
    return response.data;
  };

  const { isLoading: companyLoader, data: companyData } = useQuery(
    ["companyData", startOfMonth, endOfMonth],
    () => fetchCompanyData(),
    {
      enabled: !!startOfMonth && !!endOfMonth,
    }  
  );

  const fetchClientist = async () => {
    const response = await request.get(`${TRANSACTIONS_API_ROOT}/client/`, {
      headers: {
        Accept: "*/*",
        "Authorization": (ethanToken as unknown) as string,
      },
    });
    dispatch(setClientList(response?.data));
  };

  useEffect(() => {
    fetchClientist();
  }, []);


  const fetchData = async ({ lastDateParser }: { lastDateParser?: string }) => {
    setTableLoader(true);
    if (!lastDateParser) {
      message.error("No Statement Position data found");
    }
    const month_end_date = moment(lastDateParser).endOf("month").toISOString();

    const month_start_date = moment(lastDateParser)
      .startOf("month")
      .add(1, "days")
      .toISOString();

    setStartOfMonth(month_start_date);
    setEndOfMonth(month_end_date);

    const response = await request.get(
      `${TRANSACTIONS_API_ROOT}/positions/statements/clients/`,
      {
        headers: {
          Accept: "*/*",
          "Authorization": (ethanToken as unknown) as string,
        },
        params: {
          month_start_date,
          month_end_date,
        },
      }
    );
    if (response.status !== 200) {
      message.error("No data found");
      setTableLoader(false);
    } else {
      const data = await response.data;
      setTableLoader(false);
      dispatch(setPositionStatement(data?.data));
      return data?.data;
    }
  };


  const fetchDates = async () => {
    const response = await fetch(
      `${TRANSACTIONS_API_ROOT}/positions/statements/clients/date_parser`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Authorization": (ethanToken as unknown) as string,
        },
      }
    );
    const data = await response.json();
    setFirstDateOfAllStatement(data?.data?.first_date);
    setLastDateOfAllStatement(data?.data?.last_date);
    setDate(data?.data?.last_date);
    fetchData({
      lastDateParser: data?.data?.last_date,
    });

  };

  useEffect(() => {
    fetchDates();
  }, []);

  useEffect(() => {
    if (!netWorthLastDateValue) {
      dispatch(netWorthLastDate(undefined));
    }
  }, []);

  if(showLoader){
    return <PreLoader isLoaderOpen={showLoader} />
  }

  const Firstcomponent = () => (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => setReturnData(1)}>
          Statement Position
        </Breadcrumb.Item>
      </Breadcrumb>
      <ProCard
        style={{
          margin: "0 0 0 0",
          padding: "0",
          cursor: "pointer",
          marginBottom: "1em",
        }}
        loading={companyLoader}
        onClick={() => {
          setReturnData(2);
          setClientId(null);
          setClientCurrency(companyData?.company_data[0]?.company_currency);
          setClientName("ALL");
        }}
        bordered
        title={
          <Typography.Text style={{ fontSize: "2rem" }}>
            {companyData?.company_data[0]?.company_name}
          </Typography.Text>
        }
        extra={
          <CurrencyComponent
            currency={companyData?.company_data[0]?.company_currency}
          />
        }
      >
        <Row gutter={16}>
          <Col sm={24} md={12} lg={8}>
            <Statistic
              valueStyle={{ fontSize: "2.5rem" }}
              title="Net worth"
              valueRender={() => (
                <NumberValueShortComponent
                  value={companyData?.data?.net_worth}
                />
              )}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Statistic
              title="Asset"
              valueRender={() => (
                <NumberValueShortComponent value={companyData?.data?.assets} />
              )}
              valueStyle={{ color: "#3f8600", fontSize: "2.5rem" }}
            />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <Statistic
              title="Liabilities"
              valueRender={() => (
                <NumberValueShortComponent
                  value={companyData?.data?.liabilities}
                />
              )}
              valueStyle={{ color: "#cf1322", fontSize: "2.5rem" }}
            />
          </Col>
        </Row>
      </ProCard>
      <ProList<any>
        loading={tableLoader}
        toolBarRender={() => [
          <ProFormDatePicker.Month
            name="month"
            // initialValue={moment(lastDateOfAllStatement).month()}
            initialValue={moment(lastDateOfAllStatement).month()}
            allowClear={false}
            fieldProps={{
              format: "MMM-YYYY",
              value: date,
              // defaultValue: moment().subtract(1, "month"),
              disabledDate: (current) =>
                current > moment(lastDateOfAllStatement) ||
                current < moment(firstDateOfAllStatement),
              // value: lastDateOfAllStatement,
              onChange: async (value) => {
                // setLastDateOfAllStatement(value?.toISOString());
                setDate(value?.toISOString());
                await fetchClientist();
                await fetchData({
                  lastDateParser: value?.toISOString(),
                });
              },
            }}
            rules={[{ required: true, message: "Please select the month" }]}
            placeholder="Select Month"
          />,
        ]}
        dataSource={clientPositionData}
        style={{ padding: "0rem" }}
        rowKey="client_id"
        showExtra="hover"
        onItem={(_record: any) => ({
          onClick: () => {
            setReturnData(2);
            setClientId(_record?.client_id);
            setClientCurrency(_record?.rpt_currency);
            setClientName(_record?.client_name);
          },
        })}
        grid={{
          gutter: 16,
          column: 2,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        metas={{
          title: {
            dataIndex: "client_name",
            render: (text) => (
              <Typography.Title level={4}>{text}</Typography.Title>
            ),
          },
          actions: {
            render: (_dom, record) => (
              <CurrencyComponent currency={record?.rpt_currency} />
            ),
          },
          content: {
            render: (_text, record) => (
              <RcResizeObserver
                key="resize-observer"
                onResize={(offset) => {
                  setResponsive(offset.width < 596);
                }}
              >
                <ProCard.Group
                  direction={responsive ? "column" : "row"}
                  style={{ margin: "0", padding: "0" }}
                >
                  <ProCard style={{ margin: "0 0 0 0", padding: "0" }}>
                    <Row gutter={16}>
                      <Col sm={24} md={12} lg={8}>
                        <Statistic
                          title="Net worth"
                          valueRender={() => (
                            <NumberValueShortComponent
                              value={record?.net_worth}
                            />
                          )}
                        />
                      </Col>
                      <Col sm={24} md={12} lg={8}>
                        <Statistic
                          title="Asset"
                          valueRender={() => (
                            <NumberValueShortComponent value={record?.assets} />
                          )}
                          valueStyle={{ color: "#3f8600" }}
                        />
                      </Col>
                      <Col sm={24} md={12} lg={8}>
                        <Statistic
                          title="Liabilities"
                          valueRender={() => (
                            <NumberValueShortComponent
                              value={record?.liabilities}
                            />
                          )}
                          valueStyle={{ color: "#cf1322" }}
                        />
                      </Col>
                    </Row>
                  </ProCard>
                </ProCard.Group>
              </RcResizeObserver>
            ),
          },
        }}
      />
    </>
  );

  if (returnData === 1) {
    return <Firstcomponent />;
  }

  if (returnData === 2) {
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => setReturnData(1)}>
            Statement Position
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => setReturnData(2)}>
            {clientname}
          </Breadcrumb.Item>
        </Breadcrumb>
        <PositionList
          clientName={clientname}
          clientId={clientId}
          clientCurrency={clientCurrency}
          report_end_date={endOfMonth}
          report_start_date={startOfMonth}
        />
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default StatementPositions;
