/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable import/order */
import styles from "../Auth.module.css";
import './newPassword.less';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BrandingComponent from "../BrandingComponent";
import ReactGA from 'react-ga';
import PasswordStrength from "../PasswordStrength/PasswordStrength";
import Hide from '../../../../assets/svg/Filled.svg';
import Show from '../../../../assets/svg/View.svg';
import CheckCircleLarge from '../../../../assets/img/Scenario2-images/checkCircleXL.png';
import CircleBg from '../../../../assets/img/Scenario2-images/CircleBg.png';
import taiPasswordStrength from 'tai-password-strength';

const NewPasswordComponent = (props) => {
  const {validated} = props;
  const navigate = useNavigate();
  const defaultValues = {
    length: 0,
    upper: false,
    lower: false,
    number: false,
    special: false
  }

  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [checks, setChecks] = useState(defaultValues);

  // eslint-disable-next-line global-require
  const strengthTester = new taiPasswordStrength.PasswordStrength();

  const handleReset = () => {
    navigate("/sign-in");
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyUp = (e) => {
    const { charsets } = strengthTester.check(e.target.value);
    if (!charsets) {
      setChecks(defaultValues)
    } else {
      setChecks({
        length: password.length >= 8,
        upper: charsets.upper,
        lower: charsets.lower,
        number: charsets.number,
        special: charsets.symbol || charsets.punctuation
      })
    }
  };

  const handleValidation = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'User',
      action: "Set New Password",
      bel: "Set New Password"
    })
    // eslint-disable-next-line react/destructuring-assignment
    props.setNewPassword(password, password);
  }


  const keys = Object.keys(checks);
  const value = keys.every(key => checks[key]);


  return (
    <div className={styles["parent"]}>
      <BrandingComponent />
      <div className={styles["auth__section"]}>
        {
          !validated ?
            (
              <div className="reset-password-container">
                <div className="reset-password-header">
                  <h2>Reset Password</h2>
                  <span>Enter a new password for your account</span>
                </div>
                <div className="reset-password-input">
                  <input
                    type={`${show ? 'text' : 'password'}`}
                    value={password}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                  />
                  {show ? <img onClick={handleShow} alt="" src={Show} /> : <img onClick={handleShow} alt="" src={Hide} />}
                </div>
                <div className="reset-password-requirements">
                  <PasswordStrength checks={checks} />
                </div>
                <button
                  type="button"
                  className={`${value ? 'reset-button' : 'reset-button-disabled'}`}
                  disabled={!value}
                  onClick={(e) => handleValidation(e)}
                >
                  Update Password
                </button>
              </div>
            )
            :
            (
              <div className="reseted-password-container">
                <div className="validated-container">
                  <img alt="" src={CircleBg} />
                  <img src={CheckCircleLarge} alt="" className="validated-check-large" />
                </div>
                <p>You can now login to your account using your password</p>
                <button
                  type="button"
                  className="reset-button"
                  onClick={handleReset}
                >
                  Login
                </button>
              </div>
            )
        }
      </div>
    </div>
  );
};

export default NewPasswordComponent;
