let transactionHost;
let reportsHost;
let loginHost;
let relationshipHost;
let authServerHost;
let mode;
let analyticsHost;

const hostname = window && window.location && window.location.hostname;

const STAGING_RELATIONSHIP_HOST = "staging.ethan-ai.com"
const PROD_RELATIONSHIP_HOST = "wb.ethan-ai.com"
const DEV_RELATIONSHIP_HOST = "dev-wb.ethan-ai.com"
const PROD_ANALYTICS_HOST = "analytics.ethan-ai.com"
const PROD_AUTH_SERVER_HOST = "auth-server.ethan-ai.com"

if (hostname === "wb.ethan-ai.com") { // Prod
  transactionHost = "https://transactions.ethan-ai.com";
  reportsHost = "https://reports.ethan-ai.com";
  loginHost = "https://login-server.ethan-ai.com";
  relationshipHost = `https://${PROD_RELATIONSHIP_HOST}`;
  authServerHost = `https://${PROD_AUTH_SERVER_HOST}`;
  analyticsHost = `https://${PROD_ANALYTICS_HOST}`;
  mode = "prod";
} else if (hostname === "staging.ethan-ai.com") { // Staging
  transactionHost = "https://transactions.ethan-ai.com";
  reportsHost = "https://reports.ethan-ai.com";
  loginHost = "https://login-server.ethan-ai.com";
  relationshipHost = `http://${STAGING_RELATIONSHIP_HOST}`;
  authServerHost = `https://${PROD_AUTH_SERVER_HOST}`;
  analyticsHost = `https://${PROD_ANALYTICS_HOST}`;
  mode = "prod";
} else if (hostname === "dev-wb.ethan-ai.com") { // QA Environment
  transactionHost = "https://dev-transaction.ethan-ai.com";
  reportsHost = "https://reports.ethan-ai.com";
  loginHost = "https://login-server.ethan-ai.com";
  relationshipHost = `https://${DEV_RELATIONSHIP_HOST}`;
  analyticsHost = `https://${PROD_ANALYTICS_HOST}`;
  authServerHost = `https://${PROD_AUTH_SERVER_HOST}`;
  mode = "dev";
}
else if (hostname === "develop.wb.ethan-ai.com") { // new Dev env
  transactionHost = "https://dev-transaction.ethan-ai.com";
  reportsHost = "https://reports.ethan-ai.com";
  loginHost = "https://login-server.ethan-ai.com";
  relationshipHost = `https://${DEV_RELATIONSHIP_HOST}`;
  authServerHost = `https://${PROD_AUTH_SERVER_HOST}`;
  analyticsHost = `https://${PROD_ANALYTICS_HOST}`;
  mode = "dev";
}
else { // Dev
  // transactionHost = "https://dev-transaction.ethan-ai.com";
  transactionHost = "http://localhost:4000";
  reportsHost = "https://reports.ethan-ai.com";
  loginHost = "https://login-server.ethan-ai.com";
  relationshipHost = `https://${DEV_RELATIONSHIP_HOST}`;
  // authServerHost = `http://localhost:8000`
  authServerHost = `https://${PROD_AUTH_SERVER_HOST}`;
  analyticsHost = `http://localhost:5005`;
  // analyticsHost = `https://${PROD_ANALYTICS_HOST}`;
  mode = "dev";

}

export const TRANSACTIONS_API_ROOT = `${transactionHost}`;
export const REPORTS_API_ROOT = `${reportsHost}`;
export const LOGIN_API_ROOT = `${loginHost}`;
export const RELATIONSHIP_HOST = relationshipHost;
export const AUTH_HOST = `${authServerHost}`;
export const MODE = `${mode}`;
export const ANALYTICS_HOST = analyticsHost;
