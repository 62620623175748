/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialProcessingState = {
  processedFilesLoader: false,
  processedFiles: [],
  error: "",
};

export const fetchProcessedFiles = createAsyncThunk(
  "processedFiles/fetchProcessedFiles",
  () => {
    const ethanToken = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/processing_status/`, {
        headers: {
          "Authorization": ethanToken,
        },
      })
      .then((response) => response.data.statuses);
  }
);
const processedFilesSlice = createSlice({
  name: "processedFiles",
  initialState: initialProcessingState,
  extraReducers: (builder) => {
    builder.addCase(fetchProcessedFiles.pending, (state) => {
      state.processedFilesLoader = true;
    });
    builder.addCase(fetchProcessedFiles.fulfilled, (state, action) => {
      state.processedFilesLoader = false;
      state.processedFiles = action.payload;
      state.error = "";
    });
    builder.addCase(fetchProcessedFiles.rejected, (state, action) => {
      state.processedFilesLoader = false;
      state.processedFiles = [];
      state.error = action.error.message;
    });
  },
});

export default processedFilesSlice.reducer;
