/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  useState,
  useRef,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
} from "react";
import * as d3 from "d3";
import { Axis, Orient } from "d3-axis-for-react";
import { message, Select } from "antd";
import rawData from "./rawData.json"; // --> OFF
/* eslint no-use-before-define: 0 */ import "./indexChartStyles.css";

type Record = {
  date: Date;
  value: number;
  name: string;
};

interface IndexChartProps {
  chartData: any;
  checked?: boolean;
  showDropdown: boolean;
  options?: any;
  width: number;
  height: number;
  dropdownValue?: any[];
}

export default function IndexChart(props: IndexChartProps) {
  const {
    chartData,
    checked,
    showDropdown,
    options,
    width,
    height,
    dropdownValue,
  } = props;
  const [selectedLegends, setSelectedLegends] = useState<any[]>(
    dropdownValue || []
  );

  useEffect(() => {
    setSelectedLegends(dropdownValue || []);
  }, [dropdownValue]);

  let data;
  if (showDropdown) {
    data = chartData
      ?.filter((d: any) =>
        selectedLegends?.includes(`${checked ? d.security_id : d.asset_class}`)
      )
      .map((d: any) => ({
        date: new Date(d.report_date),
        value: Math.abs(d.net_worth),
        name: d?.asset_class || d?.security_id,
      })) as Record[];
  } else {
    data = chartData?.map((d: any) => ({
      date: new Date(d.report_date),
      value: Math.abs(d.net_worth),
      name: d?.asset_class || d?.security_id,
    })) as Record[];
  }
  // @ts-ignore
  const xDomain = d3.extent(data, (d) => d.date) as [Date, Date];

  const k = d3.max(
    // @ts-ignore
    d3.group(data, (d) => d.name),
    // @ts-ignore
    ([, group]) => {
      // @ts-ignore
      const max = d3.max(group, (d) => d.value) as number;
      // @ts-ignore
      const min = d3.min(group, (d) => d.value) as number;
      return max / min;
    }
  ) as number;

  const series = d3
    // @ts-ignore
    .groups(data, (d) => d.name)
    // @ts-ignore
    .map(([key, values]) => {
      const v = values[0].value;
      return {
        key,
        // @ts-ignore
        values: values.map(({ date, value }) => ({ date, value: value / v })),
      };
    });

  const [date, setDate] = useState<Date>(xDomain[1]);
  const svgRef = useRef<SVGElement | null>(null);

  // const width = 530;
  // const height = 400;
  const margin = { top: 20, right: 40, bottom: 30, left: 40 };

  const x = d3
    .scaleUtc()
    .domain(xDomain)
    .range([margin.left, width - margin.right])
    .interpolate(d3.interpolateRound)
    .clamp(true)
    .nice();

  const y = d3
    .scaleLog()
    .domain([1 / k, k] as [number, number])
    .rangeRound([height - margin.bottom, margin.top]);

  const z = d3
    .scaleOrdinal(d3.schemeCategory10)
    .domain(data.map((d) => d.name));

  const parseDate = d3.utcParse("%Y-%m-%d");

  const formatDate = d3.utcFormat("%B, %Y");

  // @ts-ignore
  const line = d3
    .line<Record>()
    // @ts-ignore
    .x((d) => x(d.date))
    // @ts-ignore

    .y((d) => y(d.value));
  // @ts-ignore

  const bisect = d3.bisector<Record, Date>((d) => d.date).left;

  function onMove(event: any, touchEvent: any) {
    const pointer = touchEvent
      ? d3.pointer(touchEvent, svgRef.current)
      : d3.pointer(event, svgRef.current);
    setDate(x.invert(pointer[0]));
    event.preventDefault();
  }

  // / Define the formatDate function
  function formatDate2(date: any) {
    const year = date?.getFullYear() || new Date().getFullYear();
    // eslint-disable-next-line no-unsafe-optional-chaining
    const month = `0${date?.getMonth() + 1}`.slice(-2);
    const day = `0${date?.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  }

  const xTickFormat = d3.timeFormat("%b %d,%Y");

  const handleLegendSelect = (value: any) => {
    console.log("value", value);
    if (value.length <= 5 && value.length > 1) {
      console.log("value", "jojj");
      // selectedLegends = value;
      setSelectedLegends(value);
    } else if (value.length === 1) {
      // message.info("Please select at least 5 asset classes");
      message.info("Atleast 2 asset classes must be selected");
    } else {
      message.info("You can only select up to 5 asset classes");
    }
  };

  const { Option } = Select;

  return (
    <div>
      {!showDropdown && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {z.domain().map((d: any, i: any) => (
            <div
              key={d}
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: `${z(d)}`,
                  width: "12px",
                  height: "12px",
                  marginRight: "5px",
                }}
              />
              <div>{d}</div>
            </div>
          ))}
        </div>
      )}
      {/* {!showDropdown &&
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {selectedLegends.map((d: any, i: any) => (
          <div key={d} style={{ display: "flex", alignItems: "center", marginRight: "20px", marginBottom: "10px" }}>
            <div style={{ backgroundColor: `${z(d)}`, width: "12px", height: "12px", marginRight: "5px" }} />
            <div>{d}</div>
          </div>
        ))}
      </div>} */}
      {showDropdown && (
        <Select
          // style={{ float:"right" }} // Set width of the dropdown menu
          showSearch
          style={{ width: "100%" }}
          size="small"
          mode="multiple" // Set mode to 'multiple' for multi-select
          value={selectedLegends} // Set the selected legends
          // value={dropdownValue}
          maxTagCount={5} // Set maximum number of selected tags to 5
          onChange={handleLegendSelect} // Provide a callback function for handling legend selection
          placeholder="Select Asset Class"
        >
          {options?.map(
            (
              d:
                | string
                | number
                | boolean
                | ReactElement<any, string | JSXElementConstructor<any>>
                | ReactFragment
                | ReactPortal
                | null
                | undefined,
              i: any
            ) => (
              // @ts-ignore
              <Option key={d} value={d}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: `${z(d)}`,
                      width: "12px",
                      height: "12px",
                      marginRight: "5px",
                    }}
                  />
                  <div>{d}</div>
                </div>
              </Option>
            )
          )}
        </Select>
      )}
      <svg
        // @ts-ignore
        ref={svgRef}
        onMouseMove={onMove as React.MouseEventHandler<SVGSVGElement>}
        onTouchStart={(event) => {
          event.preventDefault();
          onMove(event, event.touches[0]);
        }}
        onTouchMove={(event) => {
          event.preventDefault();
          onMove(event, event.touches[0]);
        }}
        onTouchEnd={(event) => {
          event.preventDefault();
        }}
        style={{
          WebkitTapHighlightColor: "transparent",
          touchAction: "none",
        }}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g transform={`translate(0,${height - margin.bottom})`}>
          <Axis
            scale={x}
            ticks={[5]}
            tickFormat={xTickFormat}
            tickSizeOuter={0}
            orient={Orient.bottom}
          />
        </g>

        <g transform={`translate(${margin.left},0)`}>
          <Axis
            // @ts-ignore
            tickArguments={[null, (x) => `${+x.toFixed(6)}x`]}
            scale={y}
            orient={Orient.left}
            // tickSizeInner={-width + margin.right + margin.left} // set tickSizeInner to the negative width of the chart area
            tickSizeOuter={0}
            tickSizeInner={0}
          />
        </g>
        <g width={width}>
          <line
            transform={`translate(${x(date) + 1}, 20)`}
            y1={height - 20}
            y2="0"
            stroke="black"
          />

          <text
            x={x(date) + 5} // position to the right of the line
            y={height - margin.bottom + 30} // position below the x-axis
            fill="black"
            style={{ wordWrap: "break-word" }} // lengthAdjust="spacingAndGlyphs" // specify how to adjust the length of the text
          >
            {formatDate2(date)}{" "}
            {/* formatDate2 is a function to format the date */}
          </text>
        </g>
        {/* @ts-ignore */}
        {series.map((serie) => {
          const { values } = serie;
          const i = bisect(values, date, 0, values.length - 1);
          const transform = `translate(0,${
            y(1) - y(values[i].value / values[0].value)
          })`;

          return (
            <g key={serie.key}>
              <path
                fill="none"
                transform={transform}
                stroke={z(serie.key)}
                strokeWidth="1.5"
                strokeLinejoin="round"
                strokeLinecap="round"
                d={line(serie.values) as string}
                onTouchStart={(event) => {
                  event.preventDefault(); // prevent the default touch behavior
                  event.target.dispatchEvent(new MouseEvent("mouseover")); // trigger the mouseover event
                }}
                onTouchEnd={(event) => {
                  event.preventDefault(); // prevent the default touch behavior
                  event.target.dispatchEvent(new MouseEvent("mouseout")); // trigger the mouseout event
                }}
              />
              {/* Add text element at the end of the line */}
              <text
                x={x(values[values.length - 1].date)} // position to the right of the line
                y={y(values[values.length - 1].value / values[0].value)} // same y position as the line
                dy="0.35em" // adjust vertical alignment
                fill={z(serie.key)}
                transform={`translate(0,${
                  y(1) - y(values[i].value / values[0].value)
                })`}
              >
                {serie.key.split(" ")[0]}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
}
