import { ProCard, ProCardTabsProps } from "@ant-design/pro-components";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Typography, Segmented } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getCookie } from "redux/utils/helpers";

import BasicInfo from "./BasicInfo";
import Kyc from "./Kyc";
import Goals from "./Goals";
import Estate from "./Estate";
import BankAccount from "./BankAccount";
import CustodianInfo from "./CustodianInfo";
import ProfileEdit from "./ProfileEdit";

const CmsView = () => {
  const { name } = useParams();
  const [tab, setTab] = useState("tab1");
  const [selectedOption, setSelectedOption] = useState("Profile");
  const [clientData,setClientData] = useState<any>({});

  const ethanToken = getCookie("ethan_token");

  const config = {
    headers: {
      Accept: "*/*",
      Authorization: (ethanToken as unknown) as string,
    },
  };

  const fetchData = async () => {
    const response = await axios.get(
      `${TRANSACTIONS_API_ROOT}/api/client/${name}`,
      config
    );
    setClientData(response?.data?.client);
    return response.data;
  };

  const fetchBankAccount = async () => {
    const response = await axios.get(
      `${TRANSACTIONS_API_ROOT}/api/bank_account/`,
      config
    );
    return response.data;
  };

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["client", name],
    queryFn: fetchData,
    enabled: !!name,
  });

  return (
    <div style={{ display: "flex", gap: "10px", height: "100vh" }}>
      <ProCard bordered style={{ flex: 0.4, overflow: "scroll" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "1em",
          }}
        >
          <Avatar size={100} icon={<UserOutlined />} />
          <Typography.Title>{name}</Typography.Title>
        </div>
        <div>
          <ProCard
            tabs={{
              activeKey: tab,
              items: [
                {
                  label: `Profile Info`,
                  key: "tab1",
                  children: <BasicInfo data={data?.client} />,
                },
              ],
              onChange: (key) => {
                setTab(key);
              },
            }}
          />
        </div>
      </ProCard>
      <ProCard style={{ flex: 1, overflow: "scroll" }} bordered>
        <>
          <Segmented
            block
            options={["Profile","Goals", "Estate", "Bank Accounts"]}
            onChange={(e) => setSelectedOption(e as string)}
          />
          <div>
            {selectedOption === "Goals" && <Goals id={data?.client?.id} />}
            {selectedOption === "Estate" && <Estate id={data?.client?.id} />}
            {selectedOption === "Bank Accounts" && (
              <BankAccount
                data={data?.bank_account}
                custodian={data?.custodian}
                id={data?.client?.id}
                refetch={refetch}
              />
            )}
            {/* {selectedOption === "Custodians" && <CustodianInfo data={data?.custodian}/>} */}
            {selectedOption === "Profile" && (
              <ProfileEdit name={name} selectedOption={selectedOption} refetch={refetch}/>
            )}
          </div>
        </>
      </ProCard>
    </div>
  );
};

export default CmsView;
