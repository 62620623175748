/* eslint-disable import/no-unresolved */
import {  ProFormDateRangePicker } from '@ant-design/pro-components';
import { message } from 'antd';
import { TRANSACTIONS_API_ROOT } from 'api/api-config';
import request  from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react'
import { getCookie } from 'redux/utils/helpers';

interface DateProps {
    // value?: string;
    onChange: (value: any) => void;
}

const DateRangeSelector =(props: DateProps) => {
  const {onChange} = props
  const [date, setDate] = React.useState<any>()
  const [firstDate, setFirstDate] = React.useState<any>()
  const [lastDate, setLastDate] = React.useState<any>()
  
  useEffect(() => {
    if(date != null){
     
      onChange(date)
    }
    else {
      onChange(null)
    }
  }, [date])


  const fetchDate=async()=>{
    try {
      const ethanToken = getCookie("ethan_token");
      const response = await request.get(`${TRANSACTIONS_API_ROOT}/trade/statements/clients/date_parser`, {
        headers: {
          Accept: "*/*",
          "Authorization": ethanToken as unknown as string,
        },
      })
      setFirstDate(response.data.data.first_date)
      setLastDate(response.data.data.last_date)
      //   setDate(response.data.data.last_date)

    } catch (error) {
      message.warn("Something went wrong")
    }
  }

  useEffect(() => {
    fetchDate()
  }, [])


  return (

 

    <ProFormDateRangePicker
      name="month"
      label= "Trade Date"
      fieldProps={{ 
        format: "MMM-YYYY",
        // value: {date[0],date[1]},
        disabledDate: (current) =>  current > moment(lastDate) || current < moment(firstDate),
        onChange: (changedDate:any) => {
          setDate(changedDate)
        },
      }}
    />
  )
}

export default DateRangeSelector