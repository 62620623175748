/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */// Chakra imports
import {
  Button,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  List,
  ListItem,
  OrderedList,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchtableauFirstImg } from "redux/features/reports/tableauFirstImgSlice";
import { fetchtableauFirstTicket } from "redux/features/reports/tableauFirstTicketSlice";
import { fetchtableauFetchTicket } from "redux/features/reports/tableauFetchTicketSlice";
import "./Reports.css";
import { EthanTrackerEvent } from "redux/utils/helpers";
// eslint-disable-next-line import/no-unresolved
import { update } from "redux/features/reports/tableauViewListSlice";
import Arrow from "views/Dashboard/Reports/components/Arrow";
import Card from "views/Dashboard/Reports/components/Card";
import moment from "moment";
import PreLoader from "components/PreLoader/PreLoader";
import { fetchtableauID } from "../../../redux/features/reports/tableauIDSlice";
import { fetchtableauCred } from "../../../redux/features/reports/tableauCredSlice";
import { fetchtableauSignIn } from "../../../redux/features/reports/tableauSignInSlice";
import { fetchtableauViewList } from "../../../redux/features/reports/tableauViewListSlice.ts";

// eslint-disable-next-line react/no-unstable-nested-components
function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(
    VisibilityContext
  );

  return (
    <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <ChevronLeftIcon color="grey" w="30px" h="30px" />
    </Arrow>
  );
}

// eslint-disable-next-line react/no-unstable-nested-components
function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(
    VisibilityContext
  );

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <ChevronRightIcon color="grey" w="30px" h="30px" />
    </Arrow>
  );
}

const Reports = () => {
  const [selected, setSelected] = React.useState("");
  const [selectedImg, setSelectedImg] = useState("");
  const [prev, setPrev] = useState({});
  const [loader, setLoader] = useState(true);
  const timerStarts=performance.now();
  const [timerEnds,setTimerEnds]=useState();
  const [firstContentUrl, setFirstContentUrl] = useState("");
  const [url, setUrl] = useState("");
  const [selectedUrl, setSelectedUrl] = useState("");
  const [fetched, setFetched] = useState(false);
  const [showSelection, setShowSelection] = useState(false);
  const [multipleSelects, setMultipleSelects] = useState([]);
  const [reportType, setReportType] = useState("");
  const [listReportType, setListReportType] = useState("");

  const handleTime=(t1,t2)=>{
    
    const ms= t2 - t1 ;
    const d = new Date(moment.duration(ms)); // round to nearest second
    function pad(i) { return (`0${i}`).slice(-2); }
    const str = `${d.getUTCHours()  }:${  pad(d.getUTCMinutes())  }:${  pad(d.getUTCSeconds())}`;
    // console.log(str);
    EthanTrackerEvent({
      category: "Reports-Loader",
      action: `Time Taken by Loader ${str}`,
      label: `Time taken by Loader`,
    })
  }
  const {
    isOpen: isPopupOpen,
    onOpen: onPopupOpen,
    onClose: onPopupClose,
  } = useDisclosure();
  const {
    isOpen: isListOpen,
    onOpen: onListOpen,
    onClose: onListClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  const tableauID = useSelector((state) => state.tableauID.tableauID);
  const tableauCred = useSelector((state) => state.tableauCred.tableauCred);
  const tableauToken = useSelector((state) => state.tableauSignIn.tableauToken);
  const tableauSiteID = useSelector(
    (state) => state.tableauSignIn.tableauSiteID
  );
  const views = useSelector((state) => state.tableauViewList.tableauViewList);
  const firstImgObj = useSelector((state) => state.tableauFirstImg);
  const firstTicket = useSelector(
    (state) => state.tableauFirstTicket.tableauFirstTicket
  );
  const ticket = useSelector(
    (state) => state.tableauFetchTicket.tableauFetchTicket
  );

  const handleAutoClick = () => {
    if (views.length > 0) {
      const newUrl = views[0].contentUrl.replace("/sheets", "");
      setSelectedUrl(newUrl);
    }
  };
  useEffect(() => {
    dispatch(fetchtableauID());
    if (views.length > 0) {
      handleAutoClick();
    }
  }, []);

  useEffect(() => {
    if (tableauID.length > 0) {
      dispatch(fetchtableauCred(tableauID));
    }
  }, [tableauID]);

  useEffect(() => {
    if (Object.keys(tableauCred).length !== 0) {
      dispatch(fetchtableauSignIn(tableauCred));
    }
  }, [tableauCred]);
  useEffect(() => {
    if (tableauToken.length > 0 && tableauSiteID.length > 0) {
      dispatch(fetchtableauViewList({ tableauToken, tableauSiteID }));
    }
  }, [tableauToken, tableauSiteID]);

  const fetchViewList = async () => {
    if (views.length > 0) {
      setFetched(true);
    }
  };
  const fetchFirst = () => {
    if (views.length > 0) {
      const contentURL = views[0].contentUrl;
      setFirstContentUrl(contentURL.replace("/sheets", ""));
      setSelected(views[0]);
      setPrev(views[0]);
      dispatch(
        fetchtableauFirstImg({ tableauToken, tableauSiteID, view: views[0] })
      );
    }
  };
  const fetchFirstTicket = () => {
    if (Object.keys(tableauCred).length !== 0) {
      dispatch(fetchtableauFirstTicket(tableauCred));
    }
  };
  const fetchTicket = () => {
    if (Object.keys(tableauCred).length !== 0) {
      dispatch(fetchtableauFetchTicket(tableauCred));
    }
  };
  const initUrl = () => {
    if (firstTicket.length > 0) {
      setUrl(
        `https://reports.ethan-ai.com/trusted/${firstTicket}/t/${tableauCred.credentials.site.contentUrl}/views/${firstContentUrl}?:embed=y&:tooltip=n&:toolbar=n&:showVizHome=no&:showAppBanner=n&:tabs=n&:mobile=n&:device=desktop`
      );
    }
  };
  const genUrl = () => {
    if (ticket.length > 0) {
      setUrl(
        `https://reports.ethan-ai.com/trusted/${ticket}/t/${tableauCred.credentials.site.contentUrl}/views/${selectedUrl}?:embed=y&:tooltip=n&:toolbar=n&:showVizHome=no&:showAppBanner=n&:tabs=n&:mobile=n&:device=desktop`
      );
    }
  };
  const isItemSelected = (id) =>
    !!multipleSelects.find((el) => el.itemId === id);
  const handleSelection = (itemId, title) => {
    const itemSelected = isItemSelected(itemId);
    setMultipleSelects((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el.itemId !== itemId)
        : currentSelected.concat({ itemId, title })
    );
  };
  const handleCancel = () => {
    onPopupClose();
  };
  const handleSave = () => {
    EthanTrackerEvent({
      category: "Reports",
      action: `Save Template`,
      label: `Save Template`,
    });
    setShowSelection(false);
    onPopupClose();
  };
  useEffect(() => {
    fetchFirstTicket();
    fetchViewList();
    setLoader(true);
  }, [tableauCred]);
  useEffect(() => {
    if (!fetched) {
      fetchFirst();
    }
  }, [fetched, views, tableauToken]);
  useEffect(() => {
    if (firstImgObj.tableauFirstImg.length > 0){
      setLoader(false);
      setTimerEnds(performance.now());
      handleTime(timerStarts,timerEnds);
    }
  }, [firstImgObj.tableauFirstImgLoader]);
  useEffect(() => {
    initUrl();
  }, [firstTicket, tableauCred, firstContentUrl]);
  useEffect(() => {
    fetchTicket();
  }, [selected]);
  useEffect(() => {
    genUrl();
  }, [ticket, tableauCred, selectedUrl]);
  useEffect(() => {
    if (views.length > 0) {
      const prevIndex = views.findIndex((items) => items.name === prev.name);
      dispatch(update({ attribute: prevIndex, value: selected }));
      setPrev(selected);
    }
  }, [selected]);
  const handleClick = (Img, title, contentUrl) => {
    EthanTrackerEvent({
      category: "Reports",
      action: `Switch Report`,
      label: `Switch Report`,
    });
    const newUrl = contentUrl.replace("/sheets", "");
    setSelectedUrl(newUrl);

    setSelectedImg(Img);
    const selectedIndex = views.findIndex((items) => items.name === title);
    dispatch(update({ attribute: selectedIndex, value: prev }));
    setFetched(true);
    setSelected(views[selectedIndex]);
  };

  const [hovered, setHovered] = useState(false);
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {loader ? (
        <PreLoader isLoaderOpen={loader}/>
      ) : (
        <div>
          <div
            onMouseEnter={() =>
              !showSelection ? setHovered(true) : setHovered(true)
            }
            onMouseLeave={() =>
              !showSelection ? setHovered(false) : setHovered(true)
            }
            className="parent"          >
            {hovered ? (
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {views.slice(1).map((items) => (
                  <Card
                    key={items.id}
                    type={1}
                    itemId={items.id} // NOTE: itemId is required for track items
                    title={items.name}
                    onClick={showSelection ? handleSelection : handleClick}
                    selected={isItemSelected(items.id)}
                    showSelection={showSelection}
                    contentUrl={items.contentUrl}
                    token={tableauToken}
                    site_id={tableauSiteID}
                  />
                ))}
              </ScrollMenu>
            ) : (
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {views.slice(1).map((items) => (
                  <Card
                    key={items.id}
                    type={2}
                    itemId={items.id} // NOTE: itemId is required for track items
                    title={items.name}
                    onClick={showSelection ? handleSelection : handleClick}
                    selected={isItemSelected(items.id)}
                    showSelection={showSelection}
                    contentUrl={items.contentUrl}
                    token={tableauToken}
                    site_id={tableauSiteID}
                  />
                ))}
              </ScrollMenu>
            )}
          </div>
          <label>
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {showSelection ? (
                <input
                  type="checkbox"
                  onChange={() => handleSelection(selected.id, selected.name)}
                />
              ) : null}
              <div
                className="selected__report"
                style={{ opacity: showSelection ? 0.5 : 1 }}
              >
                <span style={{ fontSize: 21 }}>{selected.name}</span>
              </div>
              {ticket.length > 0 || firstTicket.length > 0 || url.length > 0 ? (
                <iframe
                  crossorigin
                  src={url}
                  style={{
                    height: "600px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    opacity: showSelection ? 0.5 : 1,
                  }}
                  className="selected__report__img"
                />
              ) : null}
            </div>
          </label>
        </div>
      )}
    </Flex>
  );
};

export default Reports;
