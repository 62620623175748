/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialMtdPerformance = {
  mtdPerformanceLoader: false,
  mtdPerformance: [],
  error: "",
};
export const fetchmtdPerformance = createAsyncThunk(
  "mtdPerformance/fetchmtdPerformance",
  ({ startDate, lastDate }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/performance/?start_date=${startDate}&end_date=${lastDate}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const mtdPerformanceSlice = createSlice({
  name: "mtdPerformance",
  initialState: initialMtdPerformance,
  extraReducers: (builder) => {
    builder.addCase(fetchmtdPerformance.pending, (state) => {
      state.mtdPerformanceLoader = true;
    });
    builder.addCase(fetchmtdPerformance.fulfilled, (state, action) => {
      state.mtdPerformanceLoader = false;
      state.mtdPerformance = action.payload;
      state.error = "";
    });
    builder.addCase(fetchmtdPerformance.rejected, (state, action) => {
      state.mtdPerformanceLoader = false;
      state.mtdPerformance = [];
      state.error = action.error.message;
    });
  },
});

export default mtdPerformanceSlice.reducer;
