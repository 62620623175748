import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ModalForm,
  ProCard,
  ProForm,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Statistic,
  Tag,
  Typography,
  message,
} from "antd";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import { useState } from "react";
import { getCookie } from "redux/utils/helpers";

interface GoalsProps {
  id: string;
}

type selectedItemId = {
  asset_class_preference: string;
  client: string;
  constraints: string;
  id: string;
  investment_horizon: string;
  liquidity_needs: string;
  return_expectations: string;
};

const Goals = (props: GoalsProps) => {
  const { id } = props;
  const [form] = Form.useForm<any>();
  const queryClient = new QueryClient();
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<selectedItemId>();

  const ethanToken = getCookie("ethan_token");

  const config = {
    headers: {
      Accept: "*/*",
      Authorization: (ethanToken as unknown) as string,
    },
  };

  const fetchData = async () => {
    const response = await axios.get(
      `${TRANSACTIONS_API_ROOT}/api/goals`,
      config
    );
    return response.data;
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
  };

  const { data, isLoading, isError, refetch } = useQuery(["goals"], () =>
    fetchData()
  );

  const addMutation = useMutation({
    mutationFn: async (add) =>
      axios.post(`${TRANSACTIONS_API_ROOT}/api/goals/`, add, config),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["goals"] }),
  });

  return (
    <>
      <Button
        style={{ margin: "3em", float: "right" }}
        onClick={() => setOpen(true)}
      >
        <PlusOutlined />
        Add a goal
      </Button>
      <ModalForm<any>
        title={selectedItemId ? "Edit Goal" : "Add Goal"}
        initialValues={
          selectedItemId
            ? {
              asset_class_preference: selectedItemId?.asset_class_preference,
              investment_horizon: selectedItemId?.investment_horizon,
              liquidity_needs: selectedItemId?.liquidity_needs,
              return_expectations: selectedItemId?.return_expectations,
              constraints: selectedItemId?.constraints,
            }
            : {}
        }
        open={open}
        form={form}
        autoFocusFirstInput
        modalProps={{
          destroyOnClose: true,
          footer: null,
          onCancel: ()=>{
            setSelectedItemId(undefined)
            setOpen(false)
          },
        }}
        submitTimeout={2000}
        submitter={{
          submitButtonProps: {
            style: {
              display: "none",
            },
          },
          resetButtonProps: {
            style: {
              display: "none",
            },
          },
        }}
      >
        <ProForm
          initialValues={
            selectedItemId
              ? {
                asset_class_preference:
                    selectedItemId?.asset_class_preference,
                investment_horizon: selectedItemId?.investment_horizon,
                liquidity_needs: selectedItemId?.liquidity_needs,
                return_expectations: selectedItemId?.return_expectations,
                constraints: selectedItemId?.constraints,
              }
              : {}
          }
          onFinish={async (values) => {
            if (selectedItemId) {
              try {
                await axios.patch(
                  `${TRANSACTIONS_API_ROOT}/api/goals/${selectedItemId?.id}/`,
                  { ...values, client: id },
                  config
                );
                refetch();
                setSelectedItemId(undefined)
                message.success("Goal has been updated");
              } catch (err) {
                message.error("Something went wrong, could not update");
              }
            } else {
              try {
                addMutation.mutate(
                  { ...values, client: (id as unknown) as string } as any,
                  {
                    onSuccess: () => {
                      refetch();
                      setSelectedItemId(undefined)
                      message.success("Successful");
                    },
                    onError: () => {
                      message.error("Something went wrong, please try again");
                    },
                  }
                );
              } catch (err) {
                message.error("Something went wrong, could not add");
              }
            }
            setOpen(false);
            return true;
          }}
        >
          <ProForm.Group>
            <ProFormText
              width="md"
              name="asset_class_preference"
              label="Asset Class Preference"
              placeholder="Enter asset class"
            />
          </ProForm.Group>
          <ProForm.Group style={{ margin: "1em 0" }}>
            <ProFormText
              width="md"
              name="investment_horizon"
              label="Investment Horizon"
              placeholder="Enter your investment horizon"
            />
            <ProFormText
              width="md"
              name="liquidity_needs"
              label="Liquidity Needs"
              placeholder="Enter your Liquidity needs"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText
              width="md"
              name="return_expectations"
              label="Return Expectations"
              placeholder="Enter your return expectations"
            />
            <ProFormText
              width="md"
              name="constraints"
              label="Constraints"
              placeholder="Enter your constraints"
            />
          </ProForm.Group>
        </ProForm>
      </ModalForm>
      <div style={{ marginTop: "1em" }}>
        {data
          ?.filter((el: any) => el?.client === id)
          .map((item: any) => (
            <ProCard
              key={item?.id}
              title="Goal"
              headerBordered
              bordered
              extra={
                <Tag color="volcano" style={{ border: "none" }}>
                  {item?.asset_class_preference}
                </Tag>
              }
              actions={[
                <EditOutlined
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedItemId(item);
                  }}
                />,
                <DeleteOutlined
                  onClick={async () => {
                    try {
                      await axios.delete(
                        `${TRANSACTIONS_API_ROOT}/api/goals/${item?.id}`,
                        config
                      );
                      refetch();
                      message.success("Goal has been deleted");
                    } catch (err) {
                      message.error("Something went wrong, could not delete");
                    }
                  }}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />,
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Row gutter={[8, 8]}>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Investment Horizon
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.investment_horizon}
                  </Typography.Text>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Liquidity Needs
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.liquidity_needs}
                  </Typography.Text>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Return Expectations
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.return_expectations}
                  </Typography.Text>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Constraints
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.constraints}
                  </Typography.Text>
                </Col>
              </Row>
            </ProCard>
          ))}
      </div>
    </>
  );
};

export default Goals;
