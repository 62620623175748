// /* eslint-disable react/destructuring-assignment */
// /* eslint-disable no-alert */
// /* eslint-disable camelcase */
// import React, { Component } from "react";
// import axios from "axios";
// import { RELATIONSHIP_HOST } from "api/api-config";
// import LoginComponent from "./components/LoginComponent";
// import OtpComponent from "./components/OtpComponent";

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       code: "",
//       phoneNumber: "",
//       verified: false,
//       access_token: "",
//       refresh_token: "",
//       otp_verified: false,
//       ethan_token: "",
//     };
//     // eslint-disable-next-line react/no-unused-class-component-methods
//     this.hostname = window && window.location && window.location.hostname;
//     this.getCode = this.getCode.bind(this);
//     this.verifyUser = this.verifyUser.bind(this);
//     this.verifyCode = this.verifyCode.bind(this);
//   }

//   // eslint-disable-next-line no-underscore-dangle, react/sort-comp, react/no-unused-class-component-methods
//   async getCode() {
//     const { code, phoneNumber } = this.state;
//     const e = code + phoneNumber;
//     await axios.get(
//       `${RELATIONSHIP_HOST}/api/verify/getcode`,
//       {
//         params: {
//           phonenumber: e,
//           channel: "sms",
//         },
//       }
//     );
//   }

//   // eslint-disable-next-line no-underscore-dangle, react/sort-comp, react/no-unused-class-component-methods
//   async verifyUser(username, password) {
//     await axios
//       .post("https://login-server.ethan-ai.com/api/login/", {
//         username,
//         password,
//       })
//       .then((data) =>
//         this.setState({
//           access_token: data.data.access,
//           refresh_token: data.data.refresh,
//         })
//       ).catch(()=>{
//         window.alert("Login Credentials are Incorrect, Please Try again!");
//       });

//     await axios
//       .post("https://login-server.ethan-ai.com/api/decode_jwt/", {
//         token: this.state.access_token,
//       })
//       .then((data) => this.setState({ ethan_token: data.data.token }));

//     if (this.state.access_token) {
//       this.setState({ verified: true });
//     } else {
//       this.setState({ verified: false });
//     }
//     await axios
//       .post("https://login-server.ethan-ai.com/api/user/", {
//         access_token: this.state.access_token,
//       })
//       .then((data) => {
//         this.setState({
//           phoneNumber: data.data.phone_number,
//           // eslint-disable-next-line react/no-unused-state
//           email: data.data.email,
//           // eslint-disable-next-line react/no-unused-state
//           username: data.data.username,
//           code: data.data.code,
//         });
//       });
//     // eslint-disable-next-line react/destructuring-assignment
//     const e = this.state.code + this.state.phoneNumber;
//     const pno = e.substring(1, e.length);
//     await axios.get(
//       `${RELATIONSHIP_HOST}/api/verify/getcode`,
//       {
//         params: {
//           phonenumber: pno,
//           channel: "sms",
//         },
//       }
//     );
//   }

//   // eslint-disable-next-line no-underscore-dangle, react/sort-comp, react/no-unused-class-component-methods, class-methods-use-this
//   setCookie(cName, cValue, expDays) {
//     const date = new Date();
//     date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
//     const expires = `expires=${date.toUTCString()}`;
//     document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
//   }

//   // eslint-disable-next-line no-underscore-dangle, react/sort-comp, react/no-unused-class-component-methods, class-methods-use-this
//   getCookie(cName) {
//     const name = `${cName}=`;
//     const cDecoded = decodeURIComponent(document.cookie); // to be careful
//     const cArr = cDecoded.split("; ");
//     let res;
//     cArr.forEach((val) => {
//       if (val.indexOf(name) === 0) res = val.substring(name.length);
//     });
//     return res;
//   }

//   // eslint-disable-next-line no-underscore-dangle, react/sort-comp, react/no-unused-class-component-methods, class-methods-use-this
//   async verifyCode(otp) {
//     const { code, phoneNumber } = this.state;
//     const e = code + phoneNumber;
//     await axios
//       .get(
//         `${RELATIONSHIP_HOST}/api/verify/verifycode`,
//         {
//           params: {
//             phonenumber: e,
//             code: otp,
//           },
//         }
//       )
//       .then((data) => this.setState({ otp_verified: data.data.valid }))

//     if(!this.state.otp_verified){
//       window.alert("OTP Entered is Incorrect");
//     }

//   }

//   render() {
//     const {
//       username,
//       verified,
//       otp_verified,
//       access_token,
//       refresh_token,
//       ethan_token,
//     } = this.state;
//     if (otp_verified) {
//       window.location.href = "/summary";
//       this.setCookie("access", access_token, 10);
//       this.setCookie("refresh", refresh_token, 90);
//       this.setCookie("ethan_token", ethan_token, 90);
//       this.setCookie("user_name", username, 90);
//       this.setCookie("upload_preference", "ethan_cloud", 90);
//     }

//     return (
//       <div>
//         {!verified ? (
//           <LoginComponent verifyUser={this.verifyUser} />
//         ) : (
//           <OtpComponent verifyOtp={this.verifyCode} />
//         )}
//       </div>
//     );
//   }
// }
// const verifyCode = async (otp) => {
//   const e = code + phoneNumber;
//   try {
//     const {data} = await axios.get(
//       `${RELATIONSHIP_HOST}/api/verify/verifycode`,
//       {
//         params: {
//           phonenumber: e,
//           code: otp,
//         },
//       }
//     );
//     // eslint-disable-next-line prefer-destructuring
//     // const data = response.data;
//     if (data.valid) {
//       console.log("ref token", refresh_token,"access token", access_token);
//       setOtpVerified(true);
//       setCookie("access_token", access_token2, 1); // Set access token cookie for 1 day
//       setCookie("refresh_token", refresh_token2, 30); // Set refresh token cookie for 30 days
//       setCookie("ethan_token", ethan_token, 1); // Set Ethan token cookie for 1 day
//       window.location.href = "/summary";

//     } else {
//       window.alert("OTP verification failed, Please try again!");
//     }
//   } catch (error) {
//     // Handle error
//   }
// };

// const getCode = async () => {
//   const e = code + phoneNumber;
//   await axios.get(
//     `${RELATIONSHIP_HOST}/api/verify/getcode`,
//     {
//       params: {
//         phonenumber: e,
//         channel: "sms",
//       },
//     }
//   );
// };

// const verifyUser = async (username, password) => {
//   try {
//     const {data} = await axios.post("https://login-server.ethan-ai.com/api/login/", {
//       username,
//       password,
//     });
//     // const {data} = response.data;
//     setAccessToken2(data.access);
//     setRefreshToken2(data.refresh);
//     access_token = data.access;
//     refresh_token = data.refresh;
//   } catch (error) {
//     window.alert("Login Credentials are Incorrect, Please Try again!");
//   }

//   try {
//     const {data} = await axios.post("https://login-server.ethan-ai.com/api/decode_jwt/", {
//       token: access_token,
//     });
//     // const data = response.data;
//     setEthanToken(data.token);
//   } catch (error) {
//     // Handle error
//   }

//   if (access_token) {
//     setVerified(true);
//   } else {
//     setVerified(false);
//   }

//   try {
//     const {data} = await axios.post("https://login-server.ethan-ai.com/api/user/", {
//       // eslint-disable-next-line object-shorthand
//       access_token: access_token,
//     });
//     // const data = response.data;
//     setPhoneNumber(data.phone_number);
//     setCode(data.code);
//     try {
//       const e = data.code + data.phone_number;
//       const pno = e.substring(1, e.length);
//       await axios.get(
//         `${RELATIONSHIP_HOST}/api/verify/getcode`,
//         {
//           params: {
//             phonenumber: pno,
//             channel: "sms",
//           },
//         }
//       );
//     } catch (error) {
//       // handle error
//     }
//   } catch (error) {
//     // Handle error
//   }

    
// };


// export default Login;
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React, { useState } from "react";
import axios from "axios";
import { AUTH_HOST, RELATIONSHIP_HOST } from "api/api-config";
import { message } from "antd";
import LoginComponent from "./components/LoginComponent";
import OtpComponent from "./components/OtpComponent";

const Login = () => {
  // const [code, setCode] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [verified, setVerified] = useState(false);
  // const [access_token2, setAccessToken2] = useState("");
  // const [refresh_token2, setRefreshToken2] = useState("");
  // eslint-disable-next-line prefer-const
  let access_token="";
  // eslint-disable-next-line prefer-const
  let refresh_token="";
  const [otp_verified, setOtpVerified] = useState(false);
  // const [ethan_token, setEthanToken] = useState("");


  const [userId,setUserId] = useState("");

  const verifyUser = async (username, password) => {
    try {
      
      const {data, status} = await axios.post(`${AUTH_HOST}/api/login`, {
        username,
        password,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (status === 200) {
        setUserId(data?.user_id)
        setVerified(true);
      }
      else{
        setVerified(false);
        window.alert("Login Credentials are Incorrect, Please Try again!");
      }
    } catch (error) {
      setVerified(false);
      // window.alert("Login Credentials are Incorrect, Please Try again!");
      message.error("Login Credentials are Incorrect, Please Try again!");
    }

  };

  const setCookie = (cName, cValue, expDays) => {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
  };

  const getCookie = (cName) => {
    const name = `${cName}=`;
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  };


  const verifyCode = async (otp) => {

    try {
      
      const {data, status} = await axios.post(`${AUTH_HOST}/api/verify-otp`, {
        otp,
        user_id: userId,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      if (status === 200) {
        setOtpVerified(true);
        // acess token should expire in 1 hours
        setCookie("access_token", data?.access_token, 1/24); // Set access token cookie for 1 hour
        setCookie("refresh_token", data?.refresh_token, 5); // Set refresh token cookie for 2 days
        setCookie("ethan_token", `Bearer ${data?.access_token}`,1/24); // Set Ethan token cookie for 1 hour
        window.location.href = "/summary";
      }
      else{
        window.alert("OTP verification failed, Please try again!");
      }
    } catch (error) {
      message.error("OTP verification failed, Please try again!");
    }

  };

  return (
    <div>
      {!verified ? (
        <LoginComponent
          verifyUser={verifyUser}
        />
      ) : (
        <OtpComponent verifyOtp={verifyCode} />
      )}
    </div>
  );
};

export default Login;