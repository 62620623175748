/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable no-unused-expressions */
// /* eslint-disable no-plusplus */
// /* eslint-disable radix */

import { ProCard } from "@ant-design/pro-components";

import { Space, Empty, Col, Row, Spin } from "antd";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCookie } from "redux/utils/helpers";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import * as d3 from "d3";


import chart from "chart.js"
import GraphBox from "../../components/GraphBox/GraphBox";
import NetWorthBox from "../../components/NetWorthBox/NetWorthBox";
// import { IndexChart } from "../../components/IndexChart";
import IndexChart from "../../components/IndexChart";
import rawData from "../../components/rawData.json"

const Tab = styled.a`
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  ${(props: any) =>
    props.active &&
    `
    color:#1890FF;
  `}
`;

const SummaryNetWorth = () => {
  const ethanToken = getCookie("ethan_token");

  const types = ["1 Week", "1 Month", "1 Quarter", "1 Year"];

  enum TYPES {
    WEEKLY = "1 Week",
    MONTHLY = "1 Month",
    QUARTERLY = "1 Quarter",
    YEARLY = "1 Year",
  }

  const [active, setActive] = useState("1 Month");
  const [hasData, setHasData] = useState(true);
  const [clientId, setClientId] = useState<any>();
  const [clientName, setClientName] = useState<any>();
  const [graphLoader, setGraphLoader] = useState(false);
  // const [graphData, setGraphData] = useState([]);
  const netWorthLastDate = useSelector(
    (state: any) => state?.networthDateReducer?.networthDate?.last_date
  );

  const onClick = (type: string) => {
    setActive(type);
  };

  const { isLoading, data: graphData, error, status } = useQuery(
    ["graphData", netWorthLastDate, active, clientId],
    async () => {
      const startDate = moment(netWorthLastDate).endOf("day").toISOString();
      let lastDate: any;
      switch (active) {
        case TYPES.WEEKLY:
          lastDate = moment(netWorthLastDate)
            .subtract(7, "days")
            .startOf("day")
            .toISOString();
          break;
        case TYPES.MONTHLY:
          lastDate = moment(netWorthLastDate)
            .subtract(1, "months")
            .startOf("day")
            .toISOString();
          break;
        case TYPES.QUARTERLY:
          lastDate = moment(netWorthLastDate)
            .subtract(3, "months")
            .startOf("day")
            .toISOString();
          break;
        case TYPES.YEARLY:
          lastDate = moment(netWorthLastDate)
            .subtract(1, "years")
            .startOf("day")
            .toISOString();
          break;
        default:
          lastDate = moment(netWorthLastDate)
            .subtract(7, "days")
            .startOf("day")
            .toISOString();
      }
      const response = await axios.get(
        `${TRANSACTIONS_API_ROOT}/position_history/asset_networth/`,
        {
          headers: {
            Accept: "*/*",
            "Authorization": (ethanToken as unknown) as string,
          },
          params: {
            to_date: startDate,
            from_date: lastDate,
            client_id: clientId,
          },
        }
      );
      return response.data?.data;
    },
    {
      enabled: !!clientId && !!netWorthLastDate,
    }
  );


  return (
    <ProCard
      split="vertical"
      bordered
      style={{ paddingInline: "0px !important", overflowX: "scroll" }}
      className="min-h-144"
    >
      <Row>
        <Col span={12}>
          <ProCard
            colSpan="40%"
            style={{
              paddingInline: "0px !important",
              paddingBlock: "0px !important",
              padding: "0 !important",
            }}
          >
            <NetWorthBox
              setClientId={setClientId}
              setClientName={setClientName}
            />
          </ProCard>
        </Col>
        <Col span={12}>
          <ProCard
            title={clientName}
            className="summary-networth-graph"
            loading={isLoading}
            colSpan="60%"
            extra={
              <Space size="large">
                {types.map((type) => (
                  <Tab
                    key={type}
                    active={active === type}
                    onClick={() => onClick(type)}
                  >
                    {type}
                  </Tab>
                ))}
              </Space>
            }
          >
            {graphData === undefined ? (
              <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>
                <Spin />
              </div>
            ) : (
              // eslint-disable-next-line no-unsafe-optional-chaining
              <IndexChart chartData={graphData} showDropdown={false} width={document?.getElementsByClassName("summary-networth-graph")[0]?.clientWidth - 30} height={400}/>
            )}
          </ProCard>
        </Col>
      </Row>
    </ProCard>
  );
};

export default SummaryNetWorth;
