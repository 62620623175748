/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialPerformanceRangeState = {
  performanceRangeLoader: false,
  performanceRange: [],
  error: "",
};

export const fetchPerformanceRange = createAsyncThunk(
  "performanceRange/fetchPerformanceRange",
  ({ name, lastDate }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/performance/range/`,
        {
          start_date: 1,
          end_date: lastDate,
          client_name: name,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const performanceRangeSlice = createSlice({
  name: "performanceRange",
  initialState: initialPerformanceRangeState,
  extraReducers: (builder) => {
    builder.addCase(fetchPerformanceRange.pending, (state) => {
      state.performanceRangeLoader = true;
    });
    builder.addCase(fetchPerformanceRange.fulfilled, (state, action) => {
      state.performanceRangeLoader = false;
      state.performanceRange = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPerformanceRange.rejected, (state, action) => {
      state.performanceRangeLoader = false;
      state.performanceRange = [];
      state.error = action.error.message;
    });
  },
});

export default performanceRangeSlice.reducer;
