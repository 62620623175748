import { MenuProps, Tag } from "antd";

export const positionColumns = [
  {
    title: "Security Name",
    dataIndex: "securityName",
    key: "securityName",
  },
  {
    title: "Security ID",
    dataIndex: "securityID",
    key: "securityID",
  },
  {
    title: "Custodian",
    dataIndex: "custodian",
    key: "custodian",
  },
  {
    title: "Custodian Account",
    dataIndex: "customerAccount",
    key: "customerAccount",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (price: number) => (price ? price.toFixed(2) : null),
  },
  {
    title: "Cost Price",
    dataIndex: "costPrice",
    key: "costPrice",
    render: (costPrice: number) => (costPrice ? costPrice.toFixed(2) : null),
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Unrealized PNL",
    dataIndex: "unrealizedPNL",
    key: "unrealizedPNL",
    render: (unrealizedPnL: number) => (unrealizedPnL ? unrealizedPnL.toFixed(0) : null),
  },
  {
    title: "MTM Local",
    dataIndex: "mtmLocal",
    key: "mtmLocal",
    render: (mtmLocal: number) => (mtmLocal ? mtmLocal.toFixed(2) : null),
  },
  {
    title: "MTM RPT",
    dataIndex: "mtmTpy",
    key: "mtmTpy",
    render: (mtmTpy: number) => (mtmTpy ? mtmTpy.toFixed(2) : null),
  },
];

export const tradeColumns = [
  {
    title: "Security ID",
    dataIndex: "security_id",
    key: "security_id",
  },
  {
    title: "Custodian Name",
    dataIndex: "custodian_name",
    key: "custodian_name",
  },
  {
    title: "Asset Class",
    dataIndex: "asset_class",
    key: "asset_class",
  },
  {
    title: "Security Name",
    dataIndex: "security_name",
    key: "security_name",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Trade Quantity",
    dataIndex: "trade_qty",
    key: "trade_qty",
    render: (qty: string) => Number(qty).toFixed(2),
  },
  {
    title: "Trade Price",
    dataIndex: "trade_price",
    key: "trade_price",
  },
  {
    title: "Trade Action",
    dataIndex: "trade_action",
    key: "trade_action",
  },
  {
    title: "Trade Date",
    dataIndex: "trade_date",
    key: "trade_date",
  },
  {
    title: "Settlement Date",
    dataIndex: "settlement_date",
    key: "settlement_date",
  },
  {
    title: "Realised P&L",
    dataIndex: "realised_pl",
    key: "realised_pl",
    render: (realizedPL: number) => (realizedPL ? realizedPL.toFixed(2) : null),
  },
  {
    title: "Realised P&L RPT",
    dataIndex: "realised_pl_rpt",
    key: "realised_pl_rpt",
    render: (realizedPLRPT: number) =>
      realizedPLRPT ? realizedPLRPT.toFixed(2) : null,
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    render: (tags: string[]) => tags.map((tag) => <Tag key={tag} color="gold">{tag}</Tag>),
  },
];

// index page tabs
export const INDEX_POSITIONS_PAGE = "0";
export const INDEX_TRADE_PAGE = "1";
export const indexPageTabs: MenuProps["items"] = [
  {
    label: "Active Positions",
    key: INDEX_POSITIONS_PAGE,
  },
  {
    label: "Blotter Trades",
    key: INDEX_TRADE_PAGE,
  },
];

// add security page tabs
export const PUBLIC_SECURITY_PAGE = "0";
export const PRIVATE_SECURITY_PAGE = "1";
export const addSecurityPageTabs: MenuProps["items"] = [
  {
    label: "Search Public Security",
    key: PUBLIC_SECURITY_PAGE,
  },
  {
    label: "Add Custom Security",
    key: PRIVATE_SECURITY_PAGE,
  },
];

export const enum SECURITY_TYPE {
  EXISTING_PUBLIC_SECURITY,
  NEW_PUBLIC_SECURITY,
  PRIVATE_SECURITY,
}
