/* eslint-disable no-unneeded-ternary */
import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
import { Form } from 'antd';
import { BaseOptionType } from "antd/lib/select";

export interface IClientFormFieldProps {
  name?: string;
  shouldHaveClientName?: boolean;
  disabledLabel?: boolean;
  required?: boolean;
  onChange?: (value: string, options: BaseOptionType) => void;
  type?: string;
  assetData:any
}


const AssetClassFilter = (props: IClientFormFieldProps) => {
  const {shouldHaveClientName, required,assetData, disabledLabel, onChange, name} = props;
  const form = Form.useFormInstance();
  const changeAssetClass = (value: string, options: BaseOptionType) => {
    if(shouldHaveClientName){
      form?.setFieldsValue({
        client_name: options.label,
      })
    }
    onChange?.(value, options);
  }
  return (<>
    <ProFormSelect
      name={name? name : "asset_class"}
      label={disabledLabel ? null : "Asset Class"}
      allowClear={false}
      showSearch
      debounceTime={300}
      options={
        assetData?.map((asset: string) => ({
          value: asset === 'ALL' ? null : asset,
          label: asset,
        }))
      }
      fieldProps={{
        onChange: changeAssetClass,
      }}
      placeholder="Select an asset class"
      rules={[{ required, message: 'Please select an asset class' }]}
    />
    {shouldHaveClientName && <ProFormText name="asset_class" label="Asset Class" hidden />}
  </>)
}

export default AssetClassFilter;