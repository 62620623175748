/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialchartDataState = {
  chartDataLoader: false,
  loaded: false,
  chartData: [],
  error: "",
};

export const fetchchartData = createAsyncThunk(
  "chartData/fetchchartData",
  ({ type, name, date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/net_worth/range/?start_date=${type}&end_date=${date}&client_name=${name}`,
        {
          headers: {
            Accept: "*/*",
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.data);
  }
);
const chartDataSlice = createSlice({
  name: "chartData",
  initialState: initialchartDataState,
  extraReducers: (builder) => {
    builder.addCase(fetchchartData.pending, (state) => {
      state.chartDataLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchchartData.fulfilled, (state, action) => {
      state.chartDataLoader = false;
      state.loaded = true;
      state.chartData = action.payload;
      state.error = "";
    });
    builder.addCase(fetchchartData.rejected, (state, action) => {
      state.chartDataLoader = false;
      state.chartData = [];
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export default chartDataSlice.reducer;
