import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/custom-button/custom-button";
import styles from "./Auth.module.css";
import { ReactComponent as Google } from "../../../assets/svg/google.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/facebook.svg";
import { ReactComponent as Linkedin } from "../../../assets/svg/linkedin.svg";
import BrandingComponent from "./BrandingComponent";
import { EthanTrackerEvent } from "../../../redux/utils/helpers";

const LoginComponent = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { verifyUser } = props;
  const showSocialMedia= false;
  const handleLogin = (e) => {
    e.preventDefault();
    EthanTrackerEvent({
      category: 'User',
      action: "Login",
      label: "Login"
    })
    verifyUser(username, password);
  };

  return (
    <div className={styles.parent}>
      <BrandingComponent />
      <div className={styles.auth__section}>
        <div className={styles.welcome__section}>
          <span className={styles.title__regular}>
            Welcome to <span className={styles.title__bold}>ethan</span>{" "}
          </span>
          <span className={styles.subtitle}>Please enter your credentials</span>
        </div>
        <div className={styles.credentials__section}>
          <form className={styles.auth__form}>
            <input
              className={styles.auth__input}
              type="text"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Enter Username"
            />
            <div className={styles.group__box}>
              <input
                className={styles.auth__input}
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Enter Password"
              />
              <span className={styles.terms}>
                By signing up you agree to ethan terms of service and
                <a
                  href="https://www.ethan-ai.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  privacy policy
                </a>{" "}
                You also agree to receive subsequent email and third-party
                communications, which you may opt out of at any time.
              </span>
            </div>
            <div className={styles.group__box}>
              <CustomButton
                buttonType="authButton"
                onClick={(e) => handleLogin(e)}
              >
                Log In
              </CustomButton>
              <button
                className={styles.redirection__btn}
                type="button"
                onClick={() => {
                  EthanTrackerEvent({
                    category: "Window Switch",
                    action: "/signup",
                    label: "/signup",
                  });
                  navigate("/signup")
                }}
              >
                Not a member?{" "}
                <span className={styles.redirection__bold}>Sign up </span>
              </button>
              <button
                type="button"
                className={styles.redirection__btn}
                onClick={() => {
                  EthanTrackerEvent({
                    category: "Window Switch",
                    action: "/resetpassword",
                    label: "/resetpassword",
                  })
                  navigate("/resetpassword")
                }}
              >
                <span className={styles.redirection__bold}>
                  Forgot Password?{" "}
                </span>
              </button>
            </div>
          </form>
          { showSocialMedia && <div className={styles.other__section}>
            <div className={styles.line__breaker}>
              <div className={styles.hr} />
              <span className={styles.line__text}>or continue with</span>
              <div className={styles.hr} />
            </div>
            <div className={styles.auth__other}>
              <div className={styles.rect__box}>
                <Google className={styles.auth__logo} />
              </div>
              <div className={styles.rect__box}>
                <Facebook className={styles.auth__logo} />
              </div>
              <div className={styles.rect__box}>
                <Linkedin className={styles.auth__logo} />
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
