/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialallUploadsState = {
  allUploadsLoader: false,
  allUploads: [],
  error: "",
};

export const fetchallUploads = createAsyncThunk(
  "allUploads/fetchallUploads",
  () => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/statement/`, {
        headers: {
          Accept: "*/*",
          "Authorization": ethan_token,
        },
      })
      .then((response) => response.data.results);
  }
);
const allUploadsSlice = createSlice({
  name: "allUploads",
  initialState: initialallUploadsState,
  extraReducers: (builder) => {
    builder.addCase(fetchallUploads.pending, (state) => {
      state.allUploadsLoader = true;
    });
    builder.addCase(fetchallUploads.fulfilled, (state, action) => {
      state.allUploadsLoader = false;
      state.allUploads = action.payload;
      state.error = "";
    });
    builder.addCase(fetchallUploads.rejected, (state, action) => {
      state.allUploadsLoader = false;
      state.allUploads = [];
      state.error = action.error.message;
    });
  },
});

export default allUploadsSlice.reducer;
