/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-empty */
/* eslint-disable spaced-comment */
/* eslint-disable no-useless-return */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Grid } from "@chakra-ui/react";

import { selectLastDate } from "layouts/admin.selector";
import { fetchLastDate } from "redux/features/summary/statistics/lastDateSlice";
import {
  Col,
  Row,
  Space,
  Spin,
  Typography,
  Input,
  Checkbox,
  Segmented,
} from "antd";
import TimeMachineDatePickerComponent from "components/Navbars/TimeMachineDatePickerComponent";
import { netWorthLastDate } from "redux/features/summary/newNetworth/networthDateSlice";
import { ProCard, ProFormSelect } from "@ant-design/pro-components";
import { AppDispatch, useAppDispatch } from "redux/app/store";
import {
  useQuery,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import styled from "styled-components";
import axios from "axios";
import { getCookie } from "redux/utils/helpers";
import moment from "moment";
import { ANALYTICS_HOST } from "api/api-config";
import { Navigate } from "react-router-dom";
import PreLoader from "components/PreLoader/PreLoader";
import ClientSelectionTabs from "./components/ClientSelectionTabs/ClientSelectionTabs";
import PortfolioCharts from "./components/PortfolioCharts/PortfolioCharts";
import PortfolioLineCharts from "./components/PortfolioCharts/PortfolioLineCharts";
import AnalyticsSelector from "./components/AnalyticsDropdown/AnalyticsSelector";
import GraphBox from "../components/GraphBox/GraphBox";
import IndexChart from "../components/IndexChart";

interface IProps {
  activeNetWorth: string;
}

type ChartInfo = {
  component_id: number;
  chart_type: string;
  title: string;
  data: any;
};

export default function Portfolio() {
  const Tab = styled.a`
    font-size: 1rem;
    cursor: pointer;
    color: #000;
    ${(props: IProps) => props.activeNetWorth && `color:#1890FF;`}
  `;

  const dispatch = useDispatch<AppDispatch>();
  const ethanToken = getCookie("ethan_token");
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const [selectedCustodianId, setSelectedCustodianId] = useState<any>();
  const [filterValue, setFilterValue] = useState<any>({});
  const [activeTab, setActiveTab] = useState("1 Month");
  // const [selectedLegends, setSelectedLegends] = useState<any>([]);
  const types = ["1 Week", "1 Month", "1 Quarter", "1 Year"];
  const [IndexChartLoader, setIndexChartLoader] = useState<boolean>(false);
  const [OtherChartLoader, setOtherChartLoader] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const label = `${checked ? "Hide" : "Show"} Ticker Symbols`;

  const netWorthLastDateValue = useSelector(
    (state: any) => state?.networthDateReducer?.networthDate?.last_date
  );

  const showLoader = useSelector(
    (state: any) => state?.networthDateReducer?.lastDateLoader
  );

  enum TYPES {
    WEEKLY = "1 Week",
    MONTHLY = "1 Month",
    QUARTERLY = "1 Quarter",
    YEARLY = "1 Year",
  }

  const handleDate = async () => {
    const startDate = moment(netWorthLastDateValue)
      .endOf("day")
      .toISOString()
      .substring(0, 10);
    let lastDate: any;
    switch (activeTab) {
      case TYPES.WEEKLY:
        lastDate = moment(netWorthLastDateValue)
          .subtract(7, "days")
          .startOf("day")
          .toISOString()
          .substring(0, 10);
        break;
      case TYPES.MONTHLY:
        lastDate = moment(netWorthLastDateValue)
          .subtract(1, "months")
          .startOf("day")
          .toISOString()
          .substring(0, 10);
        break;
      case TYPES.QUARTERLY:
        lastDate = moment(netWorthLastDateValue)
          .subtract(3, "months")
          .startOf("day")
          .toISOString()
          .substring(0, 10);
        break;
      case TYPES.YEARLY:
        lastDate = moment(netWorthLastDateValue)
          .subtract(1, "years")
          .startOf("day")
          .toISOString()
          .substring(0, 10);
        break;
      default:
        lastDate = moment(netWorthLastDateValue)
          .subtract(7, "days")
          .startOf("day")
          .toISOString()
          .substring(0, 10);
    }
    return { startDate, lastDate };
  };

  const onClick = (type: string) => {
    setActiveTab(type);
  };

  const { isLoading, data, isError } = useQuery(
    ["analytics"],
    async () => {
      const response = await axios.post(
        `${ANALYTICS_HOST}/api/analytics/analysis_types`,
        null, // Pass null as the request body since no data is being sent
        {
          headers: {
            ethantoken: (ethanToken as unknown) as string,
            accept: "application/json",
          },
        }
      );
      return response?.data || [];
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: true,
    }
  );

  const getAnalytics = async (startDate: string, lastDate: string) => {
    const response = await axios.post(
      `${ANALYTICS_HOST}/api/${filterValue?.value}`,
      {
        parameter: {
          start_date: lastDate,
          end_date: startDate,
          client_id: selectedClientId,
          custodian_id: selectedCustodianId,
          // asset_classes: [],
          asset_classes: checked ? undefined : [],
          ticker: checked ? [] : undefined,
        },
      },
      {
        headers: {
          ethantoken: (ethanToken as unknown) as string,
        },
      }
    );
    if (await response?.data) {
      if (filterValue?.value === "get_index_chart_analysis") {
        setIndexChartLoader(false);
      } else {
        setOtherChartLoader(false);
      }
    }
    return response?.data || [];
  };

  const {
    isLoading: lineChartLoader,
    data: lineChartData,
    isError: lineChartError,
  } = useQuery({
    queryKey: [
      "AnalyticsLineCharts",
      filterValue?.value,
      selectedClientId,
      selectedCustodianId,
      activeTab,
      netWorthLastDateValue,
      checked,
    ],
    queryFn: async () => {
      const { startDate, lastDate } = await handleDate();
      return getAnalytics(startDate, lastDate);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled:
      !!filterValue?.value &&
      !!selectedClientId &&
      !!selectedCustodianId &&
      !!netWorthLastDateValue,
  });

  useEffect(() => {
    // Other Redux calls...
    if (!netWorthLastDateValue) {
      dispatch(netWorthLastDate(undefined));
    }
  }, []);

  const {
    isLoading: pieChartLoader,
    data: pieChartData,
    isError: pieChartError,
  } = useQuery({
    queryKey: [
      "AnalyticsPieCharts",
      filterValue?.value,
      selectedClientId,
      selectedCustodianId,
      netWorthLastDateValue,
      checked,
    ],
    queryFn: async () => {
      const { startDate } = await handleDate();
      return getAnalytics(startDate, startDate);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled:
      !!filterValue?.value &&
      !!selectedClientId &&
      !!selectedCustodianId &&
      !!netWorthLastDateValue,
  });

  if (showLoader) {
    return <PreLoader isLoaderOpen={showLoader} />;
  }

  return (
    <Flex flexDirection="column" pt={{ base: "90px", md: "75px" }} gap="1.5rem">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Input.Search
          placeholder="Enter query to generate analytics"
          allowClear
          style={{ width: 600 }}
          enterButton="query"
          size="large"
        />
        <TimeMachineDatePickerComponent
          handleChangeDate={(val, changedDate) => {
            dispatch(netWorthLastDate(changedDate));
            dispatch({ type: "reset", payload: changedDate });
          }}
          value={netWorthLastDateValue}
        />
      </div>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Col span={12}>
          <Typography.Title>
            {filterValue?.label ? filterValue.label : <Spin />}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <AnalyticsSelector
            setFilterValue={setFilterValue}
            data={data?.result}
            isLoading={isLoading}
            isError={isError}
          />
        </Col>
      </Row>
      <ClientSelectionTabs
        selectedClientId={selectedClientId}
        selectedCustodianId={selectedCustodianId}
        setSelectedClientId={setSelectedClientId}
        setSelectedCustodianId={setSelectedCustodianId}
      />
      {filterValue?.value === "index/get_index_chart_analysis" ? (
        <ProCard
          loading={lineChartLoader}
          className="index-chart-card"
          extra={
            <Space size="large">
              {types?.map((type) => (
                <Tab
                  key={type}
                  activeNetWorth={activeTab === type}
                  onClick={() => onClick(type)}
                >
                  {type}
                </Tab>
              ))}
            </Space>
          }
          title={
            <Segmented
              options={[
                {
                  label: "By Asset Class",
                  value: (false as unknown) as string,
                },
                {
                  label: "By Ticker",
                  value: (true as unknown) as string,
                },
              ]}
              value={(checked as unknown) as string}
              onChange={(value) => setChecked((value as unknown) as boolean)}
            />
          }
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              {lineChartData?.result && (
                <IndexChart
                  checked={checked}
                  chartData={lineChartData?.result}
                  showDropdown
                  options={lineChartData?.all_assets}
                  width={
                    document.getElementsByClassName("index-chart-card")[0]
                      ?.clientWidth - 30
                  }
                  height={400}
                  dropdownValue={lineChartData?.assets}
                />
              )}
            </Col>
          </Row>
        </ProCard>
      ) : (
        IndexChartLoader && (
          <ProCard
            loading={IndexChartLoader}
            className="index-chart-card"
            extra={
              <Space size="large">
                {types?.map((type) => (
                  <Tab
                    key={type}
                    activeNetWorth={activeTab === type}
                    onClick={() => onClick(type)}
                  >
                    {type}
                  </Tab>
                ))}
              </Space>
            }
          />
        )
      )}

      {filterValue?.value !== "index/get_index_chart_analysis" && (
        <>
          <PortfolioCharts
            data={pieChartData?.result}
            loader={pieChartLoader}
            error={pieChartError}
          />
          <ProCard
            loading={pieChartLoader}
            extra={
              <Space size="large">
                {types?.map((type) => (
                  <Tab
                    key={type}
                    activeNetWorth={activeTab === type}
                    onClick={() => onClick(type)}
                  >
                    {type}
                  </Tab>
                ))}
              </Space>
            }
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {lineChartData?.result
                ?.filter((item: ChartInfo) => item.chart_type === "line_chart")
                .map((item: ChartInfo) => (
                  <Col span={12} key={item?.component_id}>
                    <GraphBox
                      data={item?.data}
                      graphLoader={lineChartLoader}
                      type="portfolio"
                      yfield="total_pl"
                      name={item?.title}
                    />
                  </Col>
                ))}
            </Row>
          </ProCard>
        </>
      )}

      {/* <PortfolioLineCharts /> */}
    </Flex>
  );
}
