/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
// import { getCookie } from "redux/utils/helpers";


const initialClient = {
  loader: false,
  client: "",
  error: "",
};

export const selectedClient = createAsyncThunk(
  "selected_client/id",
  (id :any ) => id
);


const selectedClientSlice = createSlice({
  name: "selected_client",
  initialState: initialClient,
  extraReducers: (builder) => {
    builder.addCase(selectedClient.pending, (state:any) => {
      state.loader = true;
    });
    builder.addCase(selectedClient.fulfilled, (state:any, action :any) => {
      state.loader = false;
      state.client = action.payload;
      state.error = "";
    });
    builder.addCase(selectedClient.rejected, (state :any, action:any) => {
      state.loader = false;
      state.client = "";
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default selectedClientSlice.reducer;