/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialTopContributorQtd = {
  topContributorQtdLoader: false,
  topContributorQtd: [],
  error: "",
};

export const fetchtopContributorQtd = createAsyncThunk(
  "topContributorQtd/fetchtopContributorQtd",
  ({ client_name, end_date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/top_contributor/?client_name=${client_name}&period_type=QTD&report_date=${end_date}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.message)
  }
);
const topContributorQtdSlice = createSlice({
  name: "topContributorQtd",
  initialState: initialTopContributorQtd,
  extraReducers: (builder) => {
    builder.addCase(fetchtopContributorQtd.pending, (state) => {
      state.topContributorQtdLoader = true;
    });
    builder.addCase(fetchtopContributorQtd.fulfilled, (state, action) => {
      state.topContributorQtdLoader = false;
      state.topContributorQtd = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtopContributorQtd.rejected, (state, action) => {
      state.topContributorQtdLoader = false;
      state.topContributorQtd = [];
      state.error = action.error.message;
    });
  },
});

export default topContributorQtdSlice.reducer;
