/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialBlotterState = {
  blotterLoader: false,
  blotterGetLoader: false,
  loaded: false,
  blotter: [],
  blotterMessage: "",
  error: "",
};

export const getBlotter = createAsyncThunk("blotter/getBLotter", () => {
  const ethan_token = getCookie("ethan_token");
  return axios
    .get(`${TRANSACTIONS_API_ROOT}/trade/`, {
      headers: {
        "Authorization": ethan_token,
      },
    })
    .then((response) => {
      if (response.data.message !== undefined) return response.data.message;
      return null;
    })
});

export const postBlotter = createAsyncThunk(
  "blotter/postBlotter",
  ({
    security,
    client_name,
    trade_date,
    settlement_date,
    trade_price,
    trade_qty,
    settlement_amount,
    brokerage_optional,
    tax_optional,
    expected_returns_optional,
    by_date_optional,
    maximum_loss_total_optional,
    maximum_quaterly_loss_optional,
    maximum_week_loss_optional,
    custodian_optional,
    trade_type,
    delivery_type,
    clean_price,
    status,
    action_type,
    ccy,
    margin_amount,
    margin_percent,
  }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/trade/`,
        {
          trade_completed: true,
          security,
          client_name,
          trade_date,
          settlement_date,
          trade_price,
          trade_qty,
          settlement_amount,
          brokerage_optional,
          tax_optional,
          expected_returns_optional,
          by_date_optional,
          maximum_loss_total_optional,
          maximum_quaterly_loss_optional,
          maximum_week_loss_optional,
          custodian_optional,
          trade_type,
          delivery_type,
          clean_price,
          status,
          action_type,
          ccy,
          margin_amount,
          margin_percent,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => {
        if (response.data.message !== undefined) return response.data.message;
        return null;
      })
  }
);
export const updateBlotter = createAsyncThunk(
  "blotter/updateBlotter",
  ({
    id,
    security,
    client_name,
    trade_date,
    settlement_date,
    trade_price,
    trade_qty,
    settlement_amount,
    brokerage_optional,
    tax_optional,
    expected_returns_optional,
    by_date_optional,
    maximum_loss_total_optional,
    maximum_quaterly_loss_optional,
    maximum_week_loss_optional,
    custodian_optional,
    trade_type,
    delivery_type,
    clean_price,
    status,
    action_type,
    ccy,
    margin_amount,
    margin_percent,
  }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .put(
        `${TRANSACTIONS_API_ROOT}/trade/`,
        {
          id,
          trade_completed: true,
          security,
          client_name,
          trade_date,
          settlement_date,
          trade_price,
          trade_qty,
          settlement_amount,
          brokerage_optional,
          tax_optional,
          expected_returns_optional,
          by_date_optional,
          maximum_loss_total_optional,
          maximum_quaterly_loss_optional,
          maximum_week_loss_optional,
          custodian_optional,
          trade_type,
          delivery_type,
          clean_price,
          status,
          action_type,
          ccy,
          margin_amount,
          margin_percent,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.message)
  }
);
const blotterSlice = createSlice({
  name: "blotter",
  initialState: initialBlotterState,
  extraReducers: (builder) => {
    builder.addCase(getBlotter.pending, (state) => {
      state.blotterGetLoader = true;
      state.loaded = false;
    });
    builder.addCase(getBlotter.fulfilled, (state, action) => {
      state.blotterGetLoader = false;
      state.blotter = action.payload !== null ? action.payload : state.blotter;
      state.loaded = true;
      state.error = "";
    });
    builder.addCase(getBlotter.rejected, (state, action) => {
      state.blotterGetLoader = false;
      state.blotter = [];
      state.loaded = false;
      state.error = action.error.message;
    });

    builder.addCase(postBlotter.pending, (state) => {
      state.blotterLoader = true;
    });
    builder.addCase(postBlotter.fulfilled, (state) => {
      state.blotterLoader = false;
      state.error = "";
    });
    builder.addCase(postBlotter.rejected, (state, action) => {
      state.blotterLoader = false;
      state.blotterMessage = action.payload;
      state.error = action.error.message;
    });

    builder.addCase(updateBlotter.pending, (state) => {
      state.blotterLoader = true;
    });
    builder.addCase(updateBlotter.fulfilled, (state, action) => {
      state.blotterLoader = false;
      state.blotterMessage = action.payload;
      state.error = "";
    });
    builder.addCase(updateBlotter.rejected, (state, action) => {
      state.blotterLoader = false;
      state.blotterMessage = "";
      state.error = action.error.message;
    });
  },
});

export default blotterSlice.reducer;
