import {
  ProForm,
  ProFormDatePicker,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { useQuery } from "@tanstack/react-query";
import { Form, message } from "antd";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import { useEffect, useState } from "react";
import { getCookie } from "redux/utils/helpers";

interface ProfileEdit {
  name:any;
  selectedOption:any;
  refetch:any
}



const ProfileEdit = (props: ProfileEdit) => {
  const { name,selectedOption,refetch } = props;
  const [form] = Form.useForm<any>();

  const ethanToken = getCookie("ethan_token");

  const config = {
    headers: {
      Accept: "*/*",
      Authorization: (ethanToken as unknown) as string,
    },
  };

  
  const fetchData = async () => {
    const response = await axios.get(
      `${TRANSACTIONS_API_ROOT}/api/client/${name}`,
      config
    );
    return response?.data?.client;
  };

  const { data, isLoading, isError} = useQuery({
    queryKey: ["client", name, selectedOption],
    queryFn: fetchData,
    enabled: !!name && !!selectedOption,
  });


  // const initialValues = {
  //   first_name: data?.client?.first_name,
  //   last_name: data?.client?.last_name,
  //   date_of_birth: data?.client?.date_of_birth,
  //   nationality: data?.client?.nationality,
  //   identification_number: data?.client?.identification_number,
  //   occupation: data?.client?.occupation,
  //   rpt_currency: data?.client?.rpt_currency,
  //   tax_residency: data?.client?.tax_residency,
  //   risk_profile: data?.client?.risk_profile,
  //   email: data?.client?.email,
  //   phone_number: data?.client?.phone_number,
  //   address: data?.client?.address,
  //   city: data?.client?.city,
  //   postal_code: data?.client?.postal_code,
  //   country: data?.client?.country,
  // };

  return (
    <ProForm
      // initialValues={initialValues}
      request={() => fetchData()}
      onFinish={async (values) => {
        try {
          await axios.patch(
            `${TRANSACTIONS_API_ROOT}/api/client/${data?.id}/`,
            {
              ...values,
            },
            config
          );
          refetch();
          message.success("Profile has been edited");
        } catch (err) {
          message.error("Something went wrong, could not edit");
        }
      }}
    >
      <ProForm.Group style={{ margin: "2em" }}>
        <ProFormText width="md" name="first_name" label="First Name" />
        <ProFormText width="md" name="last_name" label="Last Name" />
      </ProForm.Group>
      <ProForm.Group style={{ margin: "2em" }}>
        <ProFormDatePicker
          width="md"
          name="date_of_birth"
          label="Date Of Birth"
          placeholder="Choose Date Of Birth"
        />
        <ProFormText width="md" name="nationality" label="Nationality" />
      </ProForm.Group>
      <ProForm.Group style={{ margin: "2em" }}>
        <ProFormText
          width="md"
          name="identification_number"
          label="Identification Number"
        />
        <ProFormText width="md" name="occupation" label="Occupation" />
        <ProFormText width="md" name="rpt_currency" label="RPT Currency" />
      </ProForm.Group>
      <ProForm.Group style={{ margin: "2em" }}>
        <ProFormText width="md" name="tax_residency" label="Tax Residency" />
        {/* <ProFormText width="md" name="risk_profile" label="Risk Profile" /> */}
        <ProFormSelect
          width="md"
          name="risk_profile"
          label="Risk Profile"
          placeholder="Choose risk profile"
          options={[
            {
              value: "conservative",
              label: "Conservative",
            },
            {
              value: "moderate",
              label: "Moderate",
            },
            {
              value: "aggressive",
              label: "Aggressive",
            },
          ]}
        />
        <ProFormText
          width="md"
          name="email"
          label="Email"
          rules={[{ type: "email" }]}
        />
      </ProForm.Group>
      <ProForm.Group style={{ margin: "2em" }}>
        <ProFormText
          width="md"
          name="phone_number"
          label="Phone Number"
          fieldProps={
            {
              type: "number",
            } as any
          }
        />
        <ProFormTextArea width="md" name="address" label="Address" />
        <ProFormText width="md" name="city" label="City" />
      </ProForm.Group>
      <ProForm.Group style={{ margin: "2em" }}>
        <ProFormText width="md" name="postal_code" label="Postal Code" />
        <ProFormText width="md" name="country" label="Country" />
      </ProForm.Group>
    </ProForm>
  );
};

export default ProfileEdit;
