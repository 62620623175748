/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialtableauFirstImgState = {
  tableauFirstImgLoader: false,
  tableauFirstImg: "",
  error: "",
};

export const fetchtableauFirstImg = createAsyncThunk(
  "tableauFirstImg/fetchtableauFirstImg",
  ({ tableauToken, tableauSiteID, view }) => fetch(
    `https://reports.ethan-ai.com/api/3.12/sites/${tableauSiteID}/views/${view.id
    }/image`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "X-Tableau-Auth": tableauToken,
      },
    }
  ).then(async (response) => {
    const imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  })
);
const tableauFirstImgSlice = createSlice({
  name: "tableauFirstImg",
  initialState: initialtableauFirstImgState,
  extraReducers: (builder) => {
    builder.addCase(fetchtableauFirstImg.pending, (state) => {
      state.tableauFirstImgLoader = true;
    });
    builder.addCase(fetchtableauFirstImg.fulfilled, (state, action) => {
      state.tableauFirstImgLoader = false;
      state.tableauFirstImg = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtableauFirstImg.rejected, (state, action) => {
      state.tableauFirstImgLoader = false;
      state.tableauFirstImg = "";
      state.error = action.error.message;
    });
  },
});

export default tableauFirstImgSlice.reducer;
