import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Typography,
} from "antd";

import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { MinusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { ISecurityDetailExternalAPI, ISecurityObject } from "../interfaces";
import {
  PRIVATE_SECURITY_PAGE,
  PUBLIC_SECURITY_PAGE,
  SECURITY_TYPE,
  addSecurityPageTabs,
} from "../constants";

interface IPrivateSecurityValue {
  assetClass: string;
  currency: string;
  securityID: string;
  securityName: string;
  privateMeta: { key: string; value: string }[];
}

interface IAddSecurityProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSecurity: React.Dispatch<
    React.SetStateAction<ISecurityObject | undefined>
  >;
  updateSecurityName: (securityName: string) => void;
  setSecurityMeta: React.Dispatch<any>;
  setSecurityType: React.Dispatch<React.SetStateAction<SECURITY_TYPE>>;
}

const SecurityDisplay = ({ data }: { data: ISecurityDetailExternalAPI }) => (
  <List
    header={<Typography.Title level={4}>Security Details</Typography.Title>}
    bordered
    dataSource={Object.entries(data)}
    renderItem={([key, value]) => (
      <div style={{ padding: "5px 0px 5px 25px" }}>
        <strong>{key}</strong>: {value}
      </div>
    )}
  />
);

const AddSecurity = ({
  visible,
  setVisible,
  setSelectedSecurity,
  updateSecurityName,
  setSecurityMeta,
  setSecurityType,
}: IAddSecurityProps) => {
  const [formNewSecurity] = Form.useForm();
  const [formPrivateSecurity] = Form.useForm();

  const [
    securityDetail,
    setSecurityDetail,
  ] = React.useState<ISecurityDetailExternalAPI>();
  const [securitySymbol, setSecuritySymbol] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const [currentPage, setCurrentPage] = useState<string>(PUBLIC_SECURITY_PAGE);

  const fetchSecurityDetail = async () => {
    const response = await axios.get(`${TRANSACTIONS_API_ROOT}/security/info`, {
      params: {
        symbol: securitySymbol,
      },
    });
    return response.data;
  };

  const updateSecurityDetail = async () => {
    try {
      setLoading(true);
      const securitiesList = await fetchSecurityDetail();

      if (securitiesList.length > 0) {
        setSecurityDetail(securitiesList[0]);
      } else {
        setSecurityDetail(undefined);
        setError("No securities found! Try another search...");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    formNewSecurity.resetFields();
    formPrivateSecurity.resetFields();
    setError("");
    setSecurityDetail(undefined);
    setSecuritySymbol("");
    setVisible(false);
  };

  const handleCancel = () => {
    clearData();
    setCurrentPage(PUBLIC_SECURITY_PAGE);
    setVisible(false);
  };

  const handlePublicSecurityFinish = () => {
    if (securityDetail) {
      setSelectedSecurity({
        sec_name: `${securityDetail.Name}`,
        security_code: securityDetail.Code,
        security_id: `${securityDetail.Code}.${securityDetail.Exchange}`,
        ccy: securityDetail.Currency,
        asset_class: securityDetail.Type,
        isin: securityDetail.ISIN,
      });
      updateSecurityName(securityDetail.Name);
      setSecurityType(SECURITY_TYPE.NEW_PUBLIC_SECURITY);
    }
    clearData();
    setVisible(false);
  };

  const handlePrivateSecurityFinish = (values: IPrivateSecurityValue) => {
    setSelectedSecurity({
      sec_name: values.securityName,
      security_code: "",
      security_id: values.securityID,
      ccy: values.currency,
      asset_class: values.assetClass,
      isin: "",
    });
    setSecurityMeta({
      private_sec: values.privateMeta.map((item) => ({
        [item.key]: item.value,
      })),
    });
    updateSecurityName(values.securityName);
    setSecurityType(SECURITY_TYPE.PRIVATE_SECURITY);
    clearData();
    setVisible(false);
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      centered
      footer={null}
      title="Add New Security"
    >
      <Menu
        onClick={(e) => setCurrentPage(e.key)}
        selectedKeys={[currentPage.toString()]}
        mode="horizontal"
        items={addSecurityPageTabs}
        style={{ marginBottom: "20px" }}
      />
      {currentPage === PUBLIC_SECURITY_PAGE && (
        <Form
          form={formNewSecurity}
          onFinish={handlePublicSecurityFinish}
          layout="horizontal"
        >
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item name="securitySymbol" label="ISIN/Ticker">
                <Input
                  type="string"
                  placeholder="Enter security ticker/ISIN"
                  value={securitySymbol}
                  onChange={(event) => setSecuritySymbol(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                htmlType="button"
                style={{ marginTop: "10px" }}
                loading={loading}
                onClick={() => updateSecurityDetail()}
              >
                Search Security
              </Button>
            </Col>
          </Row>
          <hr style={{ margin: "10px 0px 20px 0px" }} />
          {securityDetail && (
            <>
              <SecurityDisplay data={securityDetail} />
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "20px" }}
              >
                Confirm New Security
              </Button>
            </>
          )}
          {!securityDetail && error && <Descriptions title={error} bordered />}
        </Form>
      )}
      {currentPage === PRIVATE_SECURITY_PAGE && (
        <Form
          form={formPrivateSecurity}
          onFinish={handlePrivateSecurityFinish}
          layout="vertical"
        >
          <Form.Item
            name="securityName"
            label="Security Name"
            rules={[
              { required: true, message: "Please enter the security name" },
            ]}
          >
            <Input placeholder="Enter name of security" />
          </Form.Item>
          <Form.Item
            name="assetClass"
            label="Asset Class"
            rules={[
              { required: true, message: "Please enter the asset class" },
            ]}
          >
            <Input placeholder="Enter asset class" />
          </Form.Item>
          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: true, message: "Please currency" }]}
          >
            <Input placeholder="Enter currency" />
          </Form.Item>
          <Form.Item
            name="securityID"
            label={
              <>
                <span>Security ID (Generate Random &nbsp;</span>
                <SyncOutlined
                  onClick={() =>
                    formPrivateSecurity.setFieldsValue({
                      securityID: `PRIVATE-${Math.floor(
                        100000 + Math.random() * 900000
                      ).toString()}`,
                    })
                  }
                />
                <span>&nbsp; )</span>
              </>
            }
          >
            <Input placeholder="Enter an identifier for your security" />
          </Form.Item>

          <hr style={{ margin: "10px 0px 20px 0px" }} />

          <Typography.Title level={4}>Additional Properties</Typography.Title>

          <Form.List name="privateMeta">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field) => (
                  <Row key={field.key} align="middle" gutter={8}>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, "key"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a property",
                          },
                        ]}
                      >
                        <Input placeholder="Key" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, "value"]}
                        rules={[
                          { required: true, message: "Please enter a value" },
                        ]}
                      >
                        <Input placeholder="Value" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        style={{ color: "#f5222d" }}
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "100%" }}
                  >
                    Add Property
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Add Security
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddSecurity;
