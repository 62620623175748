/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { EthanTrackerEvent } from "redux/utils/helpers";
import DemoPie from "components/PieChart/PieChart";
import { Col, Empty, Row, Skeleton, Spin, Typography } from "antd";
import { ProCard } from "@ant-design/pro-components";


type ChartInfo = {
  component_id: number;
  chart_type: string;
  title: string;
  data: any
}



type PortfolioChartsProps = {
  data: any;
  loader: boolean;
  error: boolean
}

const PortfolioCharts = (props: PortfolioChartsProps) => {
  const { data, loader, error } = props;

  if (error) return <Empty />

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <ProCard loading={loader}>
        <Row>
          {data
            ?.filter((item: ChartInfo) => item.chart_type === "pie_chart")
            .map((item: ChartInfo) => (
              <Col
                xs={18}
                sm={12}
                md={8}
                lg={8}
                xl={26}
                key={item?.component_id}
              >
                <DemoPie data2={item?.data} title={item?.title} />
              </Col>
            ))}
        </Row>
      </ProCard>
    </>
  );

}

export default PortfolioCharts;
