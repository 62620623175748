/* eslint-disable react/self-closing-comp */
import './parameter.css';

// eslint-disable-next-line arrow-body-style
export const Parameter = ({text,value}) => {
  return(
    <div className="parameter">
      <div className={value ? "fill" : "check "}></div>
      <span className='param'>{text}</span>
    </div>
  )
}

export default Parameter;