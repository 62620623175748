/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialBenchmarkRangeState = {
  benchmarkRangeLoader: false,
  benchmarkRange: [],
  error: "",
};

export const fetchBenchmarkRange = createAsyncThunk(
  "benchmarkRange/fetchBenchmarkRange",
  ({ lastDate }) => {
    const ethanToken = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/benchmark/range/`,
        {
          start_date: 1,
          end_date: lastDate,
        },
        {
          headers: {
            "Authorization": ethanToken,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);

const benchmarkRangeSlice = createSlice({
  name: "benchmarkRange",
  initialState: initialBenchmarkRangeState,
  extraReducers: (builder) => {
    builder.addCase(fetchBenchmarkRange.pending, (state) => ({
      ...state,
      benchmarkRangeLoader: true,
    }));
    builder.addCase(fetchBenchmarkRange.fulfilled, (state, action) => ({
      ...state,
      benchmarkRangeLoader: false,
      benchmarkRange: action.payload,
      error: ""
    }));
    builder.addCase(fetchBenchmarkRange.rejected, (state, action) => ({
      benchmarkRangeLoader: false,
      benchmarkRange: [],
      error: action.error.message,
    }));
  },
});

export default benchmarkRangeSlice.reducer;
