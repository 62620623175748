
import React from "react";
import StatementTable from "views/Dashboard/Upload/Components/Statement/StatementTable";
import StatementCreateForm from "views/Dashboard/Upload/Components/Statement/StatementCreateForm";

const Statement = () => {
  const [drawerVisit, setDrawerVisit] = React.useState<boolean>(false);
  const openStatementCreateForm = () => {
    setDrawerVisit(true);
  }
  return (
    <>
      <StatementTable openStatementCreateForm={() => { openStatementCreateForm() }} />
      <StatementCreateForm drawerVisit={drawerVisit} setDrawerVisit={setDrawerVisit} />
    </>
  );
};
export default Statement;