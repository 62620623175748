import { PlusOutlined } from '@ant-design/icons';
import { ActionType, ProColumns, ProFormText } from '@ant-design/pro-components';
import { Button, DatePicker, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';

import Table from "components/Table/Table";
import { TRANSACTIONS_API_ROOT } from 'api/api-config';
import { getCookie } from 'redux/utils/helpers';
import ClientFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientFormField';
import CustodianFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientCustodianFormField';
import moment from 'moment';
import ProcessStatusColor from 'views/Dashboard/Upload/Components/ProcessStatusColor';
import { SortOrder } from 'antd/lib/table/interface';
import PreLoader from 'components/PreLoader/PreLoader';
import { getDownloadPath } from 'common/getDownloadPath';

import request from 'axios';


type StatementTableData = {
  id: number;
  statement_id: number;
  client_id: number;
  report_date: Date;
  upload_status: string;
  processing_status: string;
  relationship_no: string;
  client_name: string;
  custodian_name: string;
  custodian_id: string;
  portfolio_no: string;
  file_name: string;
  uploaded_at: Date;
  currency: string;
}

const columns: ProColumns<StatementTableData>[] = [
  {
    dataIndex: 'client_name',
    title: "Client Name",
    width: 100,
    fixed: 'left',
    key: 'client_name',
    renderFormItem: (item, {onChange}) => <ClientFormSelect onChange={onChange} disabledLabel />,
  },
  {
    dataIndex: 'custodian_name',
    title: "Custodian Name",
    key: 'custodian_name',
    width: 150,
    renderFormItem: (item, {onChange}) => <CustodianFormSelect onChange={onChange} disabledLabel />,
  },
  {
    dataIndex: 'report_date',
    title: "Report Month",
    width: 140,
    sorter: true,
    // Format the date to be displayed in the table
    renderText: (text) => moment(text).format("MMM YYYY"),
    key: 'report_date',
    renderFormItem: ( _item, _empty, form) => (
      <>
        <DatePicker.MonthPicker
          placeholder="Select Month"
          format="MMM-YY"
          onChange={(value) => {
            form.setFieldValue("report_date_from", value?.startOf('month').format("YYYY-MM-DD"));
            form.setFieldValue("report_date_to", value?.endOf("month").format("YYYY-MM-DD"));
          }}
        />
        <ProFormText name="report_date_from" hidden />
        <ProFormText name="report_date_to" hidden />
      </>
    ),
  },
  {
    dataIndex: 'uploaded_at',
    title: "Uploaded",
    width: 140,
    // Format the date to be displayed in the table
    renderText: (text) => moment(text).format("MMM DD, YYYY"),
    key: 'uploaded_at',
    sorter: true,
    search: false,
    defaultSortOrder: 'descend',
  },
  {
    key: 'processing_status',
    dataIndex: 'processing_status',
    title: "Status",
    width: 100,
    search: false,
    renderText: (text) => (<ProcessStatusColor status={text} />)
  },
  {
    dataIndex: 'currency',
    title: "Currency",
    key: 'currency',
    width: 80,
    search: false,
  },
  {
    dataIndex: 'relationship_no',
    title: "Relationship No",
    key: "relationship_no",
    search: false,
  },
  {
    dataIndex: 'portfolio_no',
    title: "Portfolio No",
    key: 'portfolio_no',
    search: false,
  },
  {
    key: 'file_name',
    search: false,
    title: 'Download File',
    dataIndex: 'file_name',
    fixed: 'right',
    width: 150,
    render: (value) => (
      <Tooltip placement="top" title={value}>
        <Button onClick={async() => getDownloadPath(value as string)}>
          Download
        </Button>
      </Tooltip>
    ),
    formItemProps: {
      rules: [
        {
          required: true,
          message: '此项为必填项',
        },
      ],
    },
  },
];


interface StatementTableProps {
  openStatementCreateForm: () => void;
}


const StatementTable = (props: StatementTableProps) => {
  const {  openStatementCreateForm } = props;
  const [isLoading, setIsLoading]=useState<boolean>(true);
  // const pageSize = 10;
  const [pageSize, setPageSize] = useState<number>(10);
  const actionRef = useRef<ActionType>();

  // after 10secs set the loading to false
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
  }, []);


  return (
    <>
      {isLoading && <PreLoader isLoaderOpen={isLoading}/>}
      <Table<StatementTableData>
        columns={columns}
        actionRef={actionRef}
        cardBordered
        // eslint-disable-next-line default-param-last
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
          const ordering = Object.keys(sortObject).map((key) => {
            const value = sortObject[key];
            return `${value === "ascend" ? "" : "-"}${key}`;
          })?.join();

          const ethanToken = getCookie("ethan_token");
          const { pageSize: pageSizeLimit, current, ...customParams} = params;
          const response = await request.get(`${TRANSACTIONS_API_ROOT}/statement/`, {
            params: {
              ...customParams,
              limit: pageSizeLimit,
              ordering,
              offset: ((current as number) - 1) * (pageSizeLimit as number),
            },
          
            headers: {
              Accept: "*/*",
              "Authorization": ethanToken as unknown as string,
            },
          })
          const obj = {
            total: response.data.count || 0,
            data: response.data.results || [],
          }
          setIsLoading(false);
          return obj;
        }}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'statement-table',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === 'get') {
              return {
                ...values,
                created_at: [values.startTime, values.endTime],
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize,
          onShowSizeChange(current, size) {
            setPageSize(size);
          },
        }}
        dateFormatter="string"
        search={{
          filterType: 'light',
          collapsed: false,
          collapseRender: false,
        }}
        scroll={{ x: 1200 }}
        toolBarRender={() => [
          <Button onClick={() => {
            openStatementCreateForm()
          }} key="button" icon={<PlusOutlined />} type="primary">
            Add Statement
          </Button>
        ]}
      />
    </>
  )
}

export default StatementTable;