/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import {
  DrawerForm,
  ProFormDatePicker,
  ProFormDependency,
  ProFormGroup,
  ProFormText,
  ProFormUploadDragger,
} from "@ant-design/pro-components";

import { Form, message, Upload } from "antd";
import moment from "moment";
import CustodianFormSelect from "views/Dashboard/Upload/Components/FormFields/ClientCustodianFormField";
import ClientFormSelect from "views/Dashboard/Upload/Components/FormFields/ClientFormField";
import ClientRelationshipFormSelect from "views/Dashboard/Upload/Components/FormFields/ClientRelationshipFormField";
import request from "axios";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { getCookie } from "redux/utils/helpers";
import React from "react";
import { removePropertiesFromObject } from "common/removeProperty";
import StatementType from "../FormFields/StatementType";
import Textfield from "../FormFields/Textfield";

interface IStatementCreateFormProps {
  drawerVisit: boolean;
  setDrawerVisit?: (value: boolean) => void;
}

interface IPresignedPostResponseData {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  };
}

export interface UploadProgressEvent extends Partial<ProgressEvent> {
  percent?: number;
}
export declare type UploadRequestMethod =
  | "POST"
  | "PUT"
  | "PATCH"
  | "post"
  | "put"
  | "patch";
export interface UploadRequestError extends Error {
  status?: number;
  method?: UploadRequestMethod;
  url?: string;
}

interface IUploadFileToS3 {
  file: File;
  onProgress?: (event: UploadProgressEvent) => void;
  onError?: (event: UploadRequestError | ProgressEvent) => void;
  onSuccess?: (body: string, xhr?: XMLHttpRequest) => void;
}

export default (props: IStatementCreateFormProps) => {
  const { drawerVisit, setDrawerVisit } = props;
  // const [signedUploadResponse, setSignedUploadResponse] = React.useState<IPresignedPostResponseData>();
  // const [fileKey, setFileKey] = React.useState<string>();

  // NOTE: We are using let instead of useState because uploadFileToS3 runs before useState (data is still in pipeline when func is called )

  let signedUploadResponse: IPresignedPostResponseData;
  let fileKey: string;
  const [form] = Form.useForm();
  const uploadFileToS3 = (
    presignedPostData: IPresignedPostResponseData | null
  ) => async (fileUpload: IUploadFileToS3): Promise<boolean> => {
    const { file, onSuccess, onError, onProgress } = fileUpload;
    if (!presignedPostData) {
      return false;
    }
    const formData = new FormData();
    // Map all the fields
    (Object.keys(
      presignedPostData.fields
    ) as (keyof typeof presignedPostData.fields)[]).forEach((key) => {
      const value = presignedPostData?.fields[key];
      formData.append(key as string, value as string);
    });
    formData.append("file", file);
    const response = await request.post(presignedPostData.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        if (event.lengthComputable) {
          onProgress?.({
            percent: (event.loaded / event.total) * 100,
          });
        }
      },
    });
    if (response.status === 204) {
      onSuccess?.("Ok");
      form.setFieldsValue({
        file: presignedPostData.fields.key,
      });
      return true;
    }
    const error = new Error("Error uploading the file");
    onError?.(error);
    return false;
  };

  return (
    <DrawerForm<{
      name: string;
      company: string;
    }>
      onOpenChange={setDrawerVisit}
      title="Upload Statement"
      form={form}
      open={drawerVisit}
      initialValues={{
        month: moment().subtract(1, "months").endOf("month"),
        report_date: moment()
          .subtract(1, "months")
          .endOf("month")
          .toISOString(),
      }}
      autoFocusFirstInput
      // drawerProps={{
      //   destroyOnClose: true,
      //   onClose: async() => {
      //     if(fileKey){
      //       // Delete file
      //       const ethanToken = getCookie("ethan_token");
      //       await request.post(`${TRANSACTIONS_API_ROOT}/delete-file/`,{
      //         file_key: fileKey,
      //       },{
      //         headers: {
      //           Accept: "*/*",
      //           "Authorization": ethanToken as unknown as string,
      //         },
      //       });
      //     }
      //   }
      // }}
      submitTimeout={2000}
      onBlur={async (e: any) => {
        const ethanToken = getCookie("ethan_token");
        const allValues = form.getFieldsValue();

        const requiredFields = [
          "client",
          "custodian",
          "report_date",
          "statement_type",
        ];

        const hasRequiredFields = requiredFields.every(
          (field) => allValues[field]
        );
        if (hasRequiredFields) {
          if (allValues.id) {
            if (allValues.upload) {
              await request.patch(
                `${TRANSACTIONS_API_ROOT}/statement/${allValues.id}`,
                { file_name: fileKey },
                {
                  headers: {
                    Accept: "*/*",
                    Authorization: (ethanToken as unknown) as string,
                  },
                }
              );
              await request.patch(
                `${TRANSACTIONS_API_ROOT}/statement/${allValues.id}`,
                { original_file_name: allValues.original_file_name },
                {
                  headers: {
                    Accept: "*/*",
                    Authorization: (ethanToken as unknown) as string,
                  },
                }
              );
              // const data = await statementAPI.patchTrasactionStatement(allValues.id,{file_name: fileKey },ethanToken)
              // const data2 = await statementAPI.patchTrasactionStatement(allValues.id,{original_file_name: allValues.original_file_name },ethanToken)
            } else {
              await request.patch(
                `${TRANSACTIONS_API_ROOT}/statement/${allValues.id}`,
                allValues,
                {
                  headers: {
                    Accept: "*/*",
                    Authorization: (ethanToken as unknown) as string,
                  },
                }
              );
              // const data = await statementAPI.patchTrasactionStatement(allValues.id,allValues,ethanToken)
            }
          } else {
            // Create
            const data = await request.post(
              `${TRANSACTIONS_API_ROOT}/statement/`,
              allValues,
              {
                headers: {
                  Accept: "*/*",
                  Authorization: (ethanToken as unknown) as string,
                },
              }
            );
            // const data = statementAPI.postStatements({
            //   token: ethanToken,
            //   data: allValues
            // })
            const res = await data;
            // console.log(res)
            form.setFieldsValue({
              id: res?.data?.id,
            });
          }
        }
      }}
      onFinish={async (values) => {
        const ethanToken = getCookie("ethan_token");
        const formData: any = removePropertiesFromObject(values, [
          "upload",
          "month",
        ]);
        try {
          const newData = await request.patch(
            `${TRANSACTIONS_API_ROOT}/statement/${formData?.id}`,
            formData,
            {
              headers: {
                Accept: "*/*",
                Authorization: (ethanToken as unknown) as string,
              },
            }
          );

          if (newData.status === 200) {
            const { data, status } = await request.patch(
              `${TRANSACTIONS_API_ROOT}/statement/${formData?.id}`,
              { upload_status: "COMPLETED" },
              {
                headers: {
                  Accept: "*/*",
                  Authorization: (ethanToken as unknown) as string,
                },
              }
            );
            if (status !== 200) {
              message.error(data.error);
              return false;
            }
            form.resetFields();
            message.success("Success");
            return true;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          message.error(error?.response?.data?.error || "Unexpected Error");
          return false;
        }
      }}
    >
      <ProFormGroup>
        <ProFormText name="id" hidden />
        <ProFormText name="original_file_name" hidden />
        <ProFormText name="report_date" hidden />
        <ClientFormSelect shouldHaveClientName />
        <ProFormDependency name={["client"]}>
          {({ client }) => (
            <CustodianFormSelect
              dependencies={["client"]}
              shouldHaveCustodianName
              clientId={client}
              disable={!client}
            />
          )}
        </ProFormDependency>
      </ProFormGroup>
      <ProFormGroup>
        <ProFormDependency name={["client", "custodian"]}>
          {({ client, custodian }) => (
            <ProFormDatePicker
              name="report_date"
              disabled={!client || !custodian}
              label="Date"
              rules={[{ required: true, message: "Please select the Date" }]}
              placeholder="Select Date"
              dataFormat="ISOString"
              fieldProps={{
                onChange: (value) => {
                  form.setFieldsValue({
                    report_date: value?.toISOString(),
                  });
                },
              }}
            />
          )}
        </ProFormDependency>
        <ProFormDependency name={["client", "custodian"]}>
          {({ client, custodian }) => (
            <StatementType
              dependencies={["client", "custodian"]}
              clientId={client}
              custodianId={custodian}
              disable={!client || !custodian}
            />
          )}
        </ProFormDependency>
      </ProFormGroup>

      <ProFormGroup>
        <ProFormDependency name={["client", "custodian", "statement_type"]}>
          {({ client, custodian, statement_type }) => (
            <ClientRelationshipFormSelect
              dependencies={["client", "custodian"]}
              clientId={client}
              custodianId={custodian}
              disable={!client || !custodian || !statement_type}
            />
          )}
        </ProFormDependency>

        <ProFormDependency
          name={["client", "custodian", "statement_type"]}
          style={{ marginTop: "1em" }}
        >
          {({ client, custodian, statement_type }) => (
            <Textfield
              label="Portfolio No."
              name="portfolio_no"
              dependencies={["client", "custodian", "statmentType"]}
              disable={!client || !custodian || !statement_type}
            />
          )}
        </ProFormDependency>
        <ProFormDependency
          name={["client", "custodian", "statement_type"]}
          style={{ marginTop: "1em" }}
        >
          {({ client, custodian, statement_type }) => (
            <Textfield
              label="Cash Account No."
              name="cash_account_no"
              dependencies={["client", "custodian", "statmentType"]}
              disable={!client || !custodian || !statement_type}
            />
          )}
        </ProFormDependency>
      </ProFormGroup>

      <ProFormDependency
        name={["client_name", "custodian_name", "month", "statement_type"]}
      >
        {({
          client_name: clientName,
          custodian_name: custodianName,
          month,
          statement_type,
        }) => (
          <>
            <ProFormUploadDragger
              disabled={!clientName || !custodianName || !statement_type}
              name="upload"
              style={{ width: "100%" }}
              max={1}
              label="Upload"
              title="Upload Statement"
              description={
                <>
                  Support for a single.
                  <br />
                  Client and custodian information must be provided
                </>
              }
              fieldProps={{
                multiple: false,
                customRequest: async ({
                  file,
                  onProgress,
                  onSuccess,
                  onError,
                }) => {
                  uploadFileToS3(signedUploadResponse || null)({
                    file: file as File,
                    onProgress,
                    onSuccess,
                    onError,
                  });
                },
                beforeUpload: async (file) => {
                  form.setFieldsValue({
                    original_file_name: file?.name,
                  });
                  const isPDForEXL =
                    file.type === "application/pdf" ||
                    file.type === "application/vnd.ms-excel" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                  if (!isPDForEXL) {
                    message.error(`${file.name} is not a pdf or Excel file`);
                  }
                  const ethanToken = getCookie("ethan_token");
                  const fileDataResponse = {
                    file_name: `${clientName}_${custodianName}_${moment().toISOString()}.pdf`,
                    file_path: `${moment(month).format(
                      "YYYY-MMM"
                    )}/${clientName}/${custodianName}/`,
                  };
                  const response: {
                    data: IPresignedPostResponseData;
                  } = await request.post(
                    `${TRANSACTIONS_API_ROOT}/pre-sign/`,
                    fileDataResponse,
                    {
                      headers: {
                        Accept: "*/*",
                        Authorization: (ethanToken as unknown) as string,
                      },
                    }
                  );
                  signedUploadResponse = response.data;
                  fileKey = response?.data?.fields?.key;
                  // setSignedUploadResponse(response.data);
                  // setFileKey(response?.data?.fields?.key);
                  return isPDForEXL || Upload.LIST_IGNORE;
                },
                data: () => (fileKey ? { key: fileKey } : {}),
              }}
            />
            <ProFormText name="file" hidden />
          </>
        )}
      </ProFormDependency>
    </DrawerForm>
  );
};
