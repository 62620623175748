/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "redux/utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialtableauFetchTicketState = {
  tableauFetchTicketLoader: false,
  tableauFetchTicket: "",
  error: "",
};


export const fetchtableauFetchTicket = createAsyncThunk(
  "tableauFetchTicket/fetchtableauFetchTicket",
  (tableauCred) => fetch(`${TRANSACTIONS_API_ROOT}/tableau/`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Authorization": getCookie("ethan_token"),
    },
    body: JSON.stringify({
      username: tableauCred.credentials.name,
      site_id: tableauCred.credentials.site.contentUrl,
    }),
  })
    .then((response) => response.json())
    .then((data) => data.ticket)
);
const tableauFetchTicketSlice = createSlice({
  name: "tableauFetchTicket",
  initialState: initialtableauFetchTicketState,
  extraReducers: (builder) => {
    builder.addCase(fetchtableauFetchTicket.pending, (state) => {
      state.tableauFetchTicketLoader = true;
    });
    builder.addCase(fetchtableauFetchTicket.fulfilled, (state, action) => {
      state.tableauFetchTicketLoader = false;
      state.tableauFetchTicket = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtableauFetchTicket.rejected, (state, action) => {
      state.tableauFetchTicketLoader = false;
      state.tableauFetchTicket = ""
      state.error = action.error.message;
    });
  },
});

export default tableauFetchTicketSlice.reducer;
