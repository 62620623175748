/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialColorCodesState = {
  loadingColor: false,
  colorCodes: {},
  loadedColor: false
}

export const fetchColorCodes = createAsyncThunk(
  "colorCodes/fetchColorCodes",
  (codes) => new Promise((resolve) => {
    resolve({
      codes
    })
  })
)

const colorCodesSlice = createSlice({
  name: "colorCodes",
  initialState: initialColorCodesState,
  extraReducers: (builder) => {
    builder.addCase(fetchColorCodes.pending, (state) => {
      state.loadingColor = true;
    })
    builder.addCase(fetchColorCodes.fulfilled, (state, action) => {
      state.colorCodes = action.payload.codes;
      state.loadingColor = false;
      state.loadedColor = true;
    })
    builder.addCase(fetchColorCodes.rejected, (state) => {
      state.colorCodes = [];
      state.loadingColor = false;
      state.loadedColor = false;
    })
  },
})

export default colorCodesSlice.reducer;