/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialPerformanceState = {
  performanceLoader: false,
  performance: [],
  error: "",
};

export const fetchPerformance = createAsyncThunk(
  "performance/fetchPerformance",
  () => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/performance/`, {
        headers: {
          "Authorization": ethan_token,
        },
      })
      .then((response) => response.data.data);
  }
);
const performanceSlice = createSlice({
  name: "performance",
  initialState: initialPerformanceState,
  extraReducers: (builder) => {
    builder.addCase(fetchPerformance.pending, (state) => {
      state.performanceLoader = true;
    });
    builder.addCase(fetchPerformance.fulfilled, (state, action) => {
      state.performanceLoader = false;
      state.performance = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPerformance.rejected, (state, action) => {
      state.performanceLoader = false;
      state.performance = [];
      state.error = action.error.message;
    });
  },
});

export default performanceSlice.reducer;
