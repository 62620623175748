import { EditOutlined } from "@ant-design/icons";
import { ProList } from "@ant-design/pro-components";





const BasicInfo = (props: any) => {
  const { data } = props;

  return (
    <ProList<any>
      dataSource={
        [
          {
            title:"First name",
            description:data?.first_name
          },
          {
            title:"Last name",
            description:data?.last_name
          },
          {
            title:"Date of birth",
            description:data?.date_of_birth
          },
          {
            title:"Nationality",
            description:data?.nationality
          },
          {
            title:"Identification Number",
            description:data?.identification_number
          },
          {
            title:"Occupation",
            description:data?.occupation
          },
          {
            title:"Rpt currency",
            description:data?.rpt_currency
          },
          {
            title:"Tax residency",
            description:data?.tax_residency
          },
          {
            title:"Email",
            description:data?.email
          },
          {
            title:"Phone number",
            description:data?.phone_number
          },
          {
            title:"Address",
            description:data?.address
          },
          {
            title:"City",
            description:data?.city
          },
          {
            title:"Postal code",
            description:data?.postal_code
          },
          {
            title:"Country",
            description:data?.country
          }
        ]
      }
      metas={{
        title: {
          dataIndex: "title",
        },
        description: {
          dataIndex: "description",
        },
      }}
    />
  );
};

export default BasicInfo;
