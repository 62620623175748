import axios from "axios";
import { Moment } from "moment";
import { getCookie } from "redux/utils/helpers";

export const appFetch = async (endpoint: string, params: any) => {
  try {
    const ethanToken = getCookie("ethan_token");
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Accept: "*/*",
        Authorization: (ethanToken as unknown) as string,
      },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

// helper function to convert ant form date to string
export function formatDate(moment: Moment) {
  const date = new Date(moment.toString());
  const mnth = (`0${  date.getMonth() + 1}`).slice(-2);
  const day = (`0${  date.getDate()}`).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
