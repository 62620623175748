/* eslint-disable import/no-unresolved */
import { ProFormDatePicker } from '@ant-design/pro-components';
import { message } from 'antd';
import { TRANSACTIONS_API_ROOT } from 'api/api-config';
import request  from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react'
import { getCookie } from 'redux/utils/helpers';

interface DateProps {
    // value?: string;
    onChange: (value: any) => void;
}

const DateSelector =(props: DateProps) => {
  const {onChange} = props
  const [date, setDate] = React.useState<any>()
  const [firstDate, setFirstDate] = React.useState<any>()
  const [lastDate, setLastDate] = React.useState<any>()
  
  useEffect(() => {
    if(date != null){
      onChange(moment(date).toISOString())
    }
    else {
      onChange(null)
    }
  }, [date])


  const fetchDate=async()=>{
    try {
      const ethanToken = getCookie("ethan_token");
      const response = await request.get(`${TRANSACTIONS_API_ROOT}/trades/statements/date-parser`, {
        headers: {
          Accept: "*/*",
          "Authorization": ethanToken as unknown as string,
        },
      })
      setFirstDate(response.data.data.first_date)
      setLastDate(response.data.data.last_date)
      setDate(response.data.data.last_date)

    } catch (error) {
      message.warn("Something went wrong")
    }
  }

  useEffect(() => {
    fetchDate()
  }, [])


  return (
    <ProFormDatePicker.Month
      label="Report Month"
      name="month"
      initialValue={moment(lastDate).month()}
      fieldProps={{
        format: "MMM-YYYY",
        disabledDate: (current) =>   current < moment(firstDate) || current > moment(lastDate),
        value: date,
        onChange: (value) => {
          setDate(value?.toISOString())
        },
      }}
      placeholder="Select Month"
    />
  )
}

export default DateSelector