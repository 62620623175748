import React from "react";
import { PageContainer } from "@ant-design/pro-components";
import Statement from "views/Dashboard/Upload/Components/Statement/Statement";

const UploadStatement = () => (<PageContainer
  style={{
    marginTop: 80,
  }}
  fixedHeader
  header={{
    title: 'Upload Statement',
  }}
>
  <Statement />
</PageContainer>
)


export default UploadStatement;