/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType, LightFilter, ProColumns, ProForm, ProFormDatePicker, ProFormDateRangePicker, ProFormText } from '@ant-design/pro-components';
import {  Button, DatePicker, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useStatePersist } from 'use-state-persist';
import Table from "components/Table/Table";
import { TRANSACTIONS_API_ROOT } from 'api/api-config';
import { getCookie } from 'redux/utils/helpers';
import ClientFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientFormField';
import CustodianFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientCustodianFormField';
import moment from 'moment';
import { SortOrder } from 'antd/lib/table/interface';
import PreLoader from 'components/PreLoader/PreLoader';

import request from 'axios';
import { useForm } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';
import SecurityId from './SecurityId';
import DateSelector from './DateSelector';

const { Search } = Input;


type TradeTableData = {
    id: number;
    security_id: string;
    client_name: string;
    ccy: string;
    trade_type: string;
    trade_qty: number;
    trade_price: number;
    trade_date: Date;
    action: string;
    bank_narration: string;
    asset_class: string;
    sett_amt: number;
    custodian: string;
    trade_id: string;
    client_id: number;
}



const columns: ProColumns<TradeTableData>[] = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    search: false,
    fixed: 'left',
    width: 300,
  },
  {
    title: 'Client',
    dataIndex: 'client_name',
    key: 'client_name',
    search: false,
  },
  {
    title: 'Custodian',
    dataIndex: 'custodian_name',
    key: 'custodian_name',
    search: false,
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    key: 'transaction_date',
    search: false,
  },
  {
    title: 'Narration',
    dataIndex: 'narration',
    key: 'narration',
    search: false,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    search: false,
  },
  {
    title: 'Currency',
    dataIndex: 'ccy',
    key: 'ccy',
    search: false,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    search: false,
  },
  {
    title: 'Settlement Date',
    dataIndex: 'settlement_date',
    key: 'settlement_date',
    search: false,
  },
  {
    title: 'Debit',
    dataIndex: 'debit',
    key: 'debit',
    search: false,
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    key: 'credit',
    search: false,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    search: false,
  },
  {
    title: "Report Date",
    dataIndex: 'report_date',
    key: 'report_date',
    search: false,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    search: false,
    fixed: 'right',
    width: 150,
  },
];

interface TradesPageProps {
  showDrawer: (a:any) => void;
}

const TradesPage = (props: TradesPageProps) => {
  const [isLoading, setIsLoading]=useState<boolean>(true);
  const {showDrawer} = props;
  const [filterValue, setFilterValue] = useState<any>({});
  const [searchForm] = useForm();
  const pageSize = 10;
  const actionRef = useRef<ActionType>();
  const ethanToken = getCookie("ethan_token");
  const[clientId,setClientId] = useState<any>();

  const setSearchFilter = async (_: any, allValues: any) => {
    if(allValues?.client_id !== undefined){
      setClientId(allValues?.client_id);
    }
    if(allValues?.client_id === undefined){
      setClientId(null);
    }

    if(allValues?.search){
      setTimeout(() => {
        setFilterValue({
          ...allValues,
          search: allValues?.search,
        });
        actionRef.current?.reload();
      }, 300);
    }
    else{
      setFilterValue({
        ...allValues,
      });
      actionRef.current?.reload();
    }
    if(allValues?.search === '' || allValues?.search === undefined || allValues?.search === null){
      setFilterValue({
        ...allValues,
        search: null,
      });
    }

  }

  useEffect(() => {
    actionRef.current?.reload();
  }, []);

  useEffect(() => {
    if(actionRef?.current?.reset){
      actionRef.current?.reset();
    }

  }, [filterValue])

  return (
    <>
      {/* {isLoading &&   <PreLoader isLoaderOpen={isLoading}/>} */}
      <Table<TradeTableData>
        columns={columns}
        actionRef={actionRef}
        cardBordered
        scroll={{ x: columns.length * 200, y:700 }}
        // eslint-disable-next-line default-param-last
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
          const ordering = Object.keys(sortObject).map((key) => {
            const value = sortObject[key];
            return `${value === "ascend" ? "" : "-"}${key}`;
          })?.join();

          const { pageSize: pageSizeLimit, current, ...customParams} = params;
          const response = await request.get(`${TRANSACTIONS_API_ROOT}/trades/statements/`, {
            params: {
              ...customParams,
              ...filterValue,
              limit: pageSizeLimit,
              ordering,
              offset: ((current as number) - 1) * (pageSizeLimit as number),
            },
            headers: {
              Accept: "*/*",
              "Authorization": ethanToken as unknown as string,
            },
          })
          const obj = {
            total: response.data.count,
            data: response.data.results,
          }
          setIsLoading(false);
          return obj;
        }}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'trade-statement-table',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === 'get') {
              return {
                ...values,
                created_at: [values.startTime, values.endTime],
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize,
          showSizeChanger: false,
        }}
        dateFormatter="string"
        search={{
          filterType: 'light',
          collapsed: false,
          collapseRender: false,
        }}
        toolbar={{
          title: 'Statements Trades',
          filter: (
            <LightFilter

              initialValues={{
                ...filterValue
              }}
              form={searchForm}
              preserve
              onValuesChange={setSearchFilter}
            > 
              <SecurityId name="search" />
              <ClientFormSelect name="client_id" />
              <CustodianFormSelect name="custodian_id" dependencies={['client_id']} clientId={clientId}/>
              <DateSelector onChange={(changedDate: any)=> {
                if(changedDate===null || changedDate===undefined){
                  searchForm.setFieldValue("trade_date_from",null);
                  searchForm.setFieldValue("trade_date_to", null);
                  setFilterValue({
                    ...filterValue,
                    trade_date_from: null,
                    trade_date_to: null,
                    month: null,
                  });
                }
                else{
                  searchForm.setFieldValue("trade_date_from",moment(changedDate).startOf('month').format("YYYY-MM-DD"));
                  searchForm.setFieldValue("trade_date_to",moment(changedDate).endOf("month").format("YYYY-MM-DD"));
                  setFilterValue({
                    ...filterValue,
                    trade_date_from: moment(changedDate).startOf('month').format("YYYY-MM-DD"),
                    trade_date_to: moment(changedDate).endOf("month").format("YYYY-MM-DD"),
                    month: null,
                  });
                }
              }}/>
              <ProFormText name="trade_date_from" hidden />
              <ProFormText name="trade_date_to" hidden />
            </LightFilter>
          )
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if(record?.trade_id){
              showDrawer(record)
            }
          },
        })}
      />
    </>
  )
}

export default TradesPage;