/* eslint-disable import/no-unresolved */
// /* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-param-last */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
// /* eslint-disable no-plusplus */
// /* eslint-disable import/no-duplicates */

import { ProColumns, ProTable } from "@ant-design/pro-components";
import { SortOrder } from "antd/es/table/interface";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { number, string } from "prop-types";
import React, { useState, useEffect } from "react";
import { getCookie } from "redux/utils/helpers";
import request from 'axios';
import { useSelector } from "react-redux";
import { CurrencyComponent, CurrencyValueComponent, NumberValueShortComponent } from "views/Dashboard/Tables/statementPosition/components/CurrencyComponent";
import { useQuery } from "@tanstack/react-query";
import { Empty } from "antd";

  
type DetailListProps={   
  setClientId:any; 
  setClientName:any
};

  
const NetWorthBox:React.FC<DetailListProps>=(props)=>{      
  const{ setClientId,setClientName }= props;  
  const[tableListDataSource, setTableListDataSource]=useState([]);   
  // const netWorthLastDateValue = useSelector((state:any) => state?.networthDateReducer?.networthDate?.last_date);
  const netWorthLastDateValue = useSelector((state:any) => state?.networthDateReducer?.networthDate?.last_date);
  const RPTccy= useSelector((state:any) => state?.CurrencyRPTReducer?.rpt)
  const ethanToken = getCookie("ethan_token");
  const [tableLoader, setTableLoader] = useState(false);
  const columns:ProColumns[]=[   
    {
      title : 'Client Name' , 
      key:'client_name',
      dataIndex:'client_name', 
    },
    {
      title: 'Total PL',
      key:'client_name',
      dataIndex:'total_pl',
      render: (text, record:any) => (
        <CurrencyValueComponent value={record?.total_pl} currency={RPTccy} />
      )
    },
    {
      title: 'Net Worth',
      key:'client_name',
      dataIndex:'net_worth',
      render: (text, record:any) => (
        <CurrencyValueComponent value={record?.net_worth} currency={RPTccy} />
      )
    },
  ];

  const { isLoading, data:netWorthData, error,status } = useQuery(
    ['positionHistory', netWorthLastDateValue],
    async () => {
      const response = await request.get(
        `${TRANSACTIONS_API_ROOT}/position_history/summary/daily/?report_date=${netWorthLastDateValue}`,
        {
          headers: {
            Accept: "*/*",
            "Authorization": ethanToken as unknown as string,
          }
        }
      );
      return response.data.data;
    },

    {
      enabled: !!netWorthLastDateValue,
    }
  );
    

  useEffect(() => {
    if(status === 'success'){
      setClientId(netWorthData[0]?.client_id);
      setClientName(netWorthData[0]?.client_name);
    }
  },[status])

 

  return( 
    <ProTable
      title={() => <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight:"bold",height:"2rem", fontSize:"1.3rem"}}>Net Worth </div> }
      headerTitle="Net Worth"
      loading={isLoading}
      columns={columns}
      dataSource={netWorthData}
      onRow={(record:any) => ({
        onClick: (event) => {
          setClientId(record?.client_id);
          setClientName(record?.client_name);
        },
      })}
      pagination={false}
      scroll={{y: 400}}
      // scroll={{y: "autoHeight"}}
      style={{padding:"0px 0px 0px 0px" }}
      rowKey="key"
      toolBarRender={false}
      search={false}
    />
  );
};
  
export default NetWorthBox;