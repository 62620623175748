/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialBatchRunState = {
  batchRunLoader: false,
  batchRun: [],
  error: "",
};

export const runBatch = createAsyncThunk(
  "batchRun/runBatch",
  () => null
);
const batchRunSlice = createSlice({
  name: "batchRun",
  initialState: initialBatchRunState,
  extraReducers: (builder) => {
    builder.addCase(runBatch.pending, (state) => {
      state.batchRunLoader = true;
    });
    builder.addCase(runBatch.fulfilled, (state, action) => {
      state.batchRunLoader = false;
      state.batchRun = action.payload;
      state.error = "";
    });
    builder.addCase(runBatch.rejected, (state, action) => {
      state.batchRunLoader = false;
      state.batchRun = [];
      state.error = action.error.message;
    });
  },
});

export default batchRunSlice.reducer;
