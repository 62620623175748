/* eslint-disable import/no-unresolved */
import React from "react";
import { ActionType, PageContainer } from "@ant-design/pro-components";
import ClientMonthly from "views/Dashboard/ClientMonthly/Components/ClientMonthly";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { AppDispatch } from "redux/app/store";
import PreLoader from "components/PreLoader/PreLoader";
import { netWorthLastDate } from "redux/features/summary/newNetworth/networthDateSlice";


const ClientMonthlyPage = () => 
{ 
  const dispatch = useDispatch<AppDispatch>();
  const netWorthLastDateValue= useSelector((state:any) => state?.networthDateReducer?.networthDate?.last_date);
  const showLoader = useSelector((state:any) => state?.networthDateReducer?.lastDateLoader);
  
  React.useEffect(() => {
    if(!netWorthLastDateValue) {
      dispatch(netWorthLastDate(undefined));
    }
  }, []);

  if(showLoader) return <PreLoader isLoaderOpen={showLoader} />


  return(
    <PageContainer
      style={{
        marginTop: 80,
      }}
      fixedHeader
      header={{
        title: 'Client Monthly',
      }}
    >
      <ClientMonthly />
    </PageContainer>
  )
}


export default ClientMonthlyPage;