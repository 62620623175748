/* eslint-disable no-unneeded-ternary */
import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
import { getCookie } from "redux/utils/helpers";
import request from "axios";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import React from "react";
import { Form } from 'antd';
import { BaseOptionType } from "antd/lib/select";

export interface IClientFormFieldProps {
  name?: string;
  shouldHaveClientName?: boolean;
  disabledLabel?: boolean;
  required?: boolean;
  onChange?: (value: string, options: BaseOptionType) => void;
  type?: string;
}

export interface IClient {
  client_id: string;
  name: string;
}


const ClientFormSelect = (props: IClientFormFieldProps) => {
  const {shouldHaveClientName, required, disabledLabel, onChange, name,type,} = props;
  const form = Form.useFormInstance();
  const changeClientName = (value: string, options: BaseOptionType) => {
    if(shouldHaveClientName){
      form?.setFieldsValue({
        client_name: options.label,
      })
    }
    onChange?.(value, options);
  }
  return (<>
    <ProFormSelect
      style={{width:`${type === 'cms' ? '600px' : null}`}}
      name={name? name : "client"}
      label={disabledLabel ? null : "Client"}
      allowClear={type ? false : true}
      showSearch
      debounceTime={300}
      request={async () => {
        const ethanToken = getCookie("ethan_token");
        const response = await request.get(`${TRANSACTIONS_API_ROOT}/client/`, {
          headers: {
            Accept: "*/*",
            "Authorization": ethanToken as unknown as string,
          },
        })
        const items = response.data.map((client: IClient) => ({
          value: client.client_id,
          label: client.name,
        }))
        if(type){
          items.push({value: type, label: type});
          return items;
        }
        return items;
      }}
      fieldProps={{
        onChange: changeClientName,
      }}
      placeholder="Select a client"
      rules={[{ required, message: 'Please select the client' }]}
    />
    {shouldHaveClientName && <ProFormText name="client_name" label="Client Name" hidden />}
  </>)
}

export default ClientFormSelect;