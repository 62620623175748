import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';

interface MiniNetWorthPieProps {
  data?: {
    asset_class: string;
    total_value: string;
  }[];
}

export const  MiniNetWorthPie = (props: MiniNetWorthPieProps) => {
  const { data } = props;
  const graphData = data?.map((item) => ({
    type: item.asset_class,
    value: Math.abs(parseInt(item?.total_value || `0`, 10)),
  }))|| [];

  
  const config = {
    appendPadding: 10,
    data: graphData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    // 设置圆弧起始角度
    // startAngle: Math.PI,
    // endAngle: Math.PI * 2,
    // legend: false as any,
    legend: {
      flipPage: false,
      position: 'bottom',
    } as any,

    label: {
      content: null,
    } as any,
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie height={150} {...config} />;
};