import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/custom-button/custom-button";
import BrandingComponent from "./BrandingComponent";
import styles from "./Auth.module.css";
import { EthanTrackerEvent } from "../../../redux/utils/helpers";

const SignupComponent = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [time, setTime] = useState("");
  const [media, setMedia] = useState("");
  const [other, setOther] = useState("");
  const { submitUserData } = props;
  const handleRegister = (e) => {
    e.preventDefault();
    EthanTrackerEvent({
      category: "User",
      action: "Submit Request",
      label: "Submit Request",
    });
    if (media === "Other") {
      submitUserData(
        username,
        email,
        phoneNumber,
        countryCode,
        company,
        designation,
        time,
        other
      );
    } else {
      submitUserData(
        username,
        email,
        phoneNumber,
        countryCode,
        company,
        designation,
        time,
        media
      );
    }
  };
  return (
    <div className={styles.parent}>
      <BrandingComponent />
      <div className={styles.auth__section__signup}>
        <div className={styles.welcome__section}>
          <span className={styles.title__regular}>
            Welcome to <span className={styles.title__bold}>ethan</span>{" "}
          </span>
          <span className={styles.subtitle}>Please enter your credentials</span>
        </div>
        <div className={styles.credentials__section}>
          <form className={styles.auth__form__signup}>
            <input
              className={styles.auth__input}
              type="text"
              placeholder="Enter Username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
            <input
              className={styles.auth__input}
              type="email"
              placeholder="Enter Email ID"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <div className={styles.phone__section}>
              <input
                className={styles.auth__input}
                type="number"
                placeholder="Country Code"
                value={countryCode}
                onChange={(event) => setCountryCode(event.target.value)}
              />
              <input
                className={styles.auth__input}
                type="number"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
            </div>
            <input
              className={styles.auth__input}
              type="text"
              placeholder="Enter Company Name"
              value={company}
              onChange={(event) => setCompany(event.target.value)}
            />
            <input
              className={styles.auth__input}
              type="text"
              placeholder="Enter Designation"
              value={designation}
              onChange={(event) => setDesignation(event.target.value)}
            />
            <label htmlFor={styles.auth__input} className={styles.auth__label}>
              When can we contact you?
              <input
                className={styles.auth__input}
                type="time"
                placeholder="10"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </label>
            <label htmlFor={styles.auth__input} className={styles.auth__label}>
              How did you hear about us?
              <div className={styles.radio__box}>
                <label htmlFor="G">
                  Google Search Engine
                  <input
                    type="radio"
                    value="Google Search Engine"
                    name="media"
                    id="G"
                    onChange={(e) => setMedia(e.target.value)}
                  />
                </label>
              </div>
              <div className={styles.radio__box}>
                <label htmlFor="S">
                  Social Media
                  <input
                    type="radio"
                    value="Social Media"
                    name="media"
                    id="S"
                    onChange={(e) => setMedia(e.target.value)}
                  />
                </label>
              </div>
              <div className={styles.radio__box}>
                <label htmlFor="B">
                  Blog or Publication
                  <input
                    type="radio"
                    value="Blog or Publication"
                    name="media"
                    id="B"
                    onChange={(e) => setMedia(e.target.value)}
                  />
                </label>
              </div>
              <div className={styles.radio__box}>
                <label htmlFor="O">
                  Other
                  <input
                    type="radio"
                    value="Other"
                    name="media"
                    id="O"
                    onChange={(e) => setMedia(e.target.value)}
                  />
                </label>
              </div>
            </label>
            {media === "Other" ? (
              <textarea
                className={styles.auth__input}
                placeholder="state here"
                rows="1"
                value={other}
                onChange={(e) => setOther(e.target.value)}
              />
            ) : (
              <div style={{ marginBottom: "1rem" }} />
            )}
            <div className={styles.group__box}>
              <CustomButton
                buttonType="authButton"
                onClick={(e) => handleRegister(e)}
              >
                Submit
              </CustomButton>
              <button
                className={styles.redirection__btn}
                type="submit"
                onClick={() => {
                  EthanTrackerEvent({
                    category: "Window Switch",
                    action: "/sign-in",
                    label: "/sign-in",
                  });
                  navigate("/sign-in");
                }}
              >
                Already a member?{" "}
                <span className={styles.redirection__bold}>Log In</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
