/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialTopContributorMtd = {
  topContributorMtdLoader: false,
  topContributorMtd: [],
  error: "",
};

export const fetchtopContributorMtd = createAsyncThunk(
  "topContributorMtd/fetchtopContributorMtd",
  ({ client_name, end_date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/top_contributor/?client_name=${client_name}&period_type=MTD&report_date=${end_date}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.message)
  }
);
const topContributorMtdSlice = createSlice({
  name: "topContributorMtd",
  initialState: initialTopContributorMtd,
  extraReducers: (builder) => {
    builder.addCase(fetchtopContributorMtd.pending, (state) => {
      state.topContributorMtdLoader = true;
    });
    builder.addCase(fetchtopContributorMtd.fulfilled, (state, action) => {
      state.topContributorMtdLoader = false;
      state.topContributorMtd = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtopContributorMtd.rejected, (state, action) => {
      state.topContributorMtdLoader = false;
      state.topContributorMtd = [];
      state.error = action.error.message;
    });
  },
});

export default topContributorMtdSlice.reducer;
