/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialallStatementsState = {
  allStatementsLoader: false,
  allStatements: [],
  error: "",
};

export const fetchallStatements = createAsyncThunk(
  "allStatements/fetchallStatements",
  () => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/statement_master/`, {
        headers: {
          Accept: "*/*",
          "Authorization": ethan_token,
        },
      })
      .then((response) => response.data.statements.reverse());
  }
);
const allStatementsSlice = createSlice({
  name: "allStatements",
  initialState: initialallStatementsState,
  extraReducers: (builder) => {
    builder.addCase(fetchallStatements.pending, (state) => {
      state.allStatementsLoader = true;
    });
    builder.addCase(fetchallStatements.fulfilled, (state, action) => {
      state.allStatementsLoader = false;
      state.allStatements = action.payload;
      state.error = "";
    });
    builder.addCase(fetchallStatements.rejected, (state, action) => {
      state.allStatementsLoader = false;
      state.allStatements = [];
      state.error = action.error.message;
    });
  },
});

export default allStatementsSlice.reducer;
