import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-242606963-2'

export const getCookie = (cName) => {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(document.cookie); // to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const setCookie = (cName, cValue, expDays) => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const EthanTrackerInitiate = () => {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.set({ userId: getCookie('user_name') }, ['dev']);
}

export const EthanTrackerEvent = (event) => {
  const { category, action, label } = event;
  ReactGA.event({
    category,
    action,
    label
  })
}