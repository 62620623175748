// library imports
import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Button,
  Modal,
  AutoComplete,
  Row,
  Col,
  Tag,
  Space,
} from "antd";
import moment, { Moment } from "moment";

// source file imports
import axios from "axios";
import { getCookie } from "redux/utils/helpers";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { formatDate } from "utils/utils";
import { useToast } from "@chakra-ui/react";
import { IClientInformation } from "redux/features/uploads/allClientSlice";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  IActivePositionFetchPayload,
  IActivePositionTable,
  IBlotterTradePost,
  ICustodianInformation,
  IRelationInformation,
  ISecurityObject,
} from "../interfaces";
import AddSecurity from "./AddSecurity";
import { SECURITY_TYPE } from "../constants";

interface ITradeDefaults {
  client: IClientInformation;
  custodianList: ICustodianInformation[];
  relationsList: IRelationInformation[];
  securities: ISecurityObject[];

  // autofill values based on selected position
  selectedPosition?: IActivePositionTable;
  setSelectedPosition?: React.Dispatch<
    React.SetStateAction<IActivePositionTable | null>
  >;

  // on form close callback
  formOpen: boolean;
  onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;

  // update blotter trades and active positions once form filling is complete
  updateBlotterTrades: (page: number) => Promise<number>;
  updateActivePositions: (payload: IActivePositionFetchPayload) => Promise<number>;
}

interface IFormData {
  buySell: string;
  client: string;
  custodian: string;
  custodianAccount: string | null;
  price: string;
  quantity: string;
  security: string;
  settlementDate: Moment;
  tradeDate: Moment;
}

const AddBlotterTrade = ({
  client,
  selectedPosition,
  setSelectedPosition,
  custodianList,
  relationsList,
  securities,
  formOpen,
  onClose,
  updateBlotterTrades,
  updateActivePositions,
}: ITradeDefaults) => {
  const [form] = Form.useForm();
  const toast = useToast();

  const [selectedCustodian, setSelectedCustodian] = useState<ICustodianInformation>();

  const [selectedSecurity, setSelectedSecurity] = useState<ISecurityObject>();
  const [securitySearchValue, setSecuritySearchValue] = useState<string>("");
  const [
    addSecurityPopupVisible,
    setAddSecurityPopupVisible,
  ] = useState<boolean>(false);

  const [tags, setTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState<string>("");

  const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
  const [securityMeta, setSecurityMeta] = useState<any>(); // TODO fix a type
  const [securityType, setSecurityType] = useState<SECURITY_TYPE>(
    SECURITY_TYPE.EXISTING_PUBLIC_SECURITY
  );

  const resetForm = () => {
    form.resetFields(["security"]);
    if (setSelectedPosition) setSelectedPosition(null);

    setTags([]);
    setAddSecurityPopupVisible(false);

    setSelectedSecurity(undefined);
    form.setFieldsValue({ security: "" });
    setSecuritySearchValue("");
  };

  // handle form submission
  const handleFinish = (values: IFormData) => {
    // seurity selected is mandatory whether the security is public or private
    if (!selectedSecurity) return;

    // these fields are mandatory for public and existing security but not for private security
    if (!selectedCustodian && securityType !== SECURITY_TYPE.PRIVATE_SECURITY) return;
    
    // if we are selling, the trade quantity must be negated as per the backend logic
    const tradeQty =
      values.buySell === "sell"
        ? -Number(values.quantity)
        : Number(values.quantity);

    // the meta fields contain the tags for now, can be extended in the future
    const metaTags = tags.length > 0 ? { tags } : null;

    const newTrade: IBlotterTradePost = {
      security_id: selectedSecurity.security_id,
      security_name: selectedSecurity.sec_name,
      client_id: client.client_id,
      client_name: client.name,
      custodian_id: selectedCustodian?.custodian_id || null,
      custodian_name: selectedCustodian?.custodian_name || null,
      asset_class: selectedSecurity.asset_class,
      trade_qty: tradeQty,
      trade_price: Number(values.price),
      trade_date: formatDate(values.tradeDate),
      settlement_date: formatDate(values.settlementDate),
      trade_action: values.buySell,
      currency: selectedSecurity.ccy,
      useraccount: values.custodianAccount,
      meta: securityMeta ? { ...metaTags, ...securityMeta } : metaTags,
      private: securityType === SECURITY_TYPE.PRIVATE_SECURITY,
    };
    
    // console.log(JSON.stringify(newTrade));
    
    setSubmissionLoading(true);
    
    const ethanToken = getCookie("ethan_token");

    axios
      .post(`${TRANSACTIONS_API_ROOT}/blotter-trade/`, newTrade, {
        headers: {
          Accept: "*/*",
          Authorization: (ethanToken as unknown) as string,
        },
      })
      .then(() => {
        resetForm();
        updateBlotterTrades(1);
        updateActivePositions({ page: 1 });
        onClose();
      })
      .catch((e) => {
        toast({
          title: "Encountered an error while adding blotter trade!",
          description: "Please check if the values you entered are correct.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        console.error(e);
      })
      .finally(() => setSubmissionLoading(false));

    // add new ticker if security type is new public security
    if (securityType === SECURITY_TYPE.NEW_PUBLIC_SECURITY) {
      axios.post(
        `${TRANSACTIONS_API_ROOT}/security/add-ticker/`,
        {
          symbol: selectedSecurity.security_id,
        },
        {
          headers: {
            Accept: "*/*",
            Authorization: (ethanToken as unknown) as string,
          },
        }
      );
    }
  };

  // handle dialog close
  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    resetForm();
    onClose(e);
  };

  const updateSecurityName = (securityName: string) => {
    form.setFieldsValue({ security: securityName });
  };

  // logic for autocomplete field of selecting security
  const handleSecuritySearch = (value: string) => {
    setSecuritySearchValue(value);
    setSelectedSecurity(undefined);
  };

  const handleSecuritySelect = (
    value: string,
    option: { children: any; index: number; key: string; value: string }
  ) => {
    setSecuritySearchValue(value);
    setSelectedSecurity(securities[option.index]);
    setSecurityType(SECURITY_TYPE.EXISTING_PUBLIC_SECURITY);
  };

  // set initial values on load
  useEffect(() => {
    if (selectedPosition) {
      setSelectedCustodian({
        custodian_code: "", // this is not useful for us so it can be left empty
        custodian_id: selectedPosition.custodianID,
        custodian_name: selectedPosition.custodian
      })
      setSelectedSecurity({
        sec_name: selectedPosition.securityName,
        security_code: selectedPosition.securityID,
        security_id: selectedPosition.securityID,
        ccy: selectedPosition.currency,
        asset_class: selectedPosition.assetClass,
        isin: selectedPosition.isin,
      });
      if (selectedPosition.meta && selectedPosition.meta.private_sec) {
        setSecurityType(SECURITY_TYPE.PRIVATE_SECURITY);
      }
    }
  }, []);

  return (
    <Modal
      title="Add Blotter Trade"
      open={formOpen}
      onCancel={handleClose}
      afterClose={form.resetFields}
      footer={null}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Space direction="vertical" size="small">
          <Form.Item
            name="custodian"
            label="Custodian"
            rules={[{ required: securityType !== SECURITY_TYPE.PRIVATE_SECURITY, message: "Please select a custodian" }]}
            initialValue={selectedPosition?.custodian}
          >
            <Select
              onChange={(value) => {
                // TODO set only custodian later
                setSelectedCustodian(custodianList[value]);
              }}
              placeholder="Select custodian"
            >
              {custodianList.map((custodian, index) => (
                <Select.Option key={custodian.custodian_id} value={index}>
                  {custodian.custodian_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="custodianAccount"
            label="Custodian Account"
            rules={[
              { required: securityType !== SECURITY_TYPE.PRIVATE_SECURITY, message: "Please select a custodian account" },
            ]}
            initialValue={selectedPosition?.customerAccount}
          >
            <Select placeholder="Select custodian account">
              {relationsList
                .filter((relation) => relation.bank_id === selectedCustodian?.custodian_id)
                .map((relation) => (
                  <Select.Option
                    key={relation.relationship_number}
                    value={relation.relationship_number}
                  >
                    {relation.relationship_number}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="security"
            label={
              <>
                <span>Security &nbsp;</span>
                <PlusCircleOutlined
                  onClick={() => {
                    setAddSecurityPopupVisible(true);
                  }}
                />
              </>
            }
            rules={[{ required: true, message: "Please select a security" }]}
            initialValue={
              selectedPosition ? `${selectedPosition?.securityName}` : ""
            }
          >
            <AutoComplete
              value={securitySearchValue}
              onChange={handleSecuritySearch}
              onSelect={handleSecuritySelect}
              placeholder="Search securities"
              filterOption={(inputValue, option) => {
                if (
                  option?.value
                    ?.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                ) {
                  return true;
                }
                return false;
              }}
            >
              {securities.map((security, index) => (
                <AutoComplete.Option
                  key={security.security_id + index.toString()}
                  index={index}
                  value={`${security.sec_name}`}
                >
                  {`${security.sec_name}`}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>

          <Row gutter={8}>
            <Col span={8}>
              <Form.Item name="securityID" label="Security ID">
                <span className="disabledInput">
                  {selectedSecurity?.security_id?.substring(0, 19)}
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="currency" label="Currency">
                <span className="disabledInput">{selectedSecurity?.ccy}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="assetType" label="Asset Type">
                <span className="disabledInput">
                  {selectedSecurity?.asset_class}
                </span>
              </Form.Item>
            </Col>
          </Row>

          {securityType === SECURITY_TYPE.PRIVATE_SECURITY && <div>Hello</div>}

          <Form.Item
            name="buySell"
            label="Buy/Sell"
            rules={[{ required: true, message: "Please select Buy or Sell" }]}
          >
            <Radio.Group>
              <Radio value="buy">Buy</Radio>
              <Radio value="sell">Sell</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="tradeDate"
            label="Trade Date"
            rules={[{ required: true, message: "Please select a trade date" }]}
            initialValue={moment()}
          >
            <DatePicker
              defaultValue={moment()}
              format="YYYY-MM-DD"
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            name="settlementDate"
            label="Settlement Date"
            rules={[
              { required: true, message: "Please select a settlement date" },
            ]}
            initialValue={moment().add(2, "days")}
          >
            <DatePicker defaultValue={moment().add(2, "days")} />
          </Form.Item>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[
                  { required: true, message: "Please enter the quantity" },
                ]}
              >
                <Input type="number" placeholder="Enter quantity of asset" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: "Please enter the price" }]}
              >
                <Input type="number" placeholder="Enter price of trade" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} align="bottom">
            <Col span={16}>
              <Form.Item
                name="tags"
                label="Add Tags"
                style={{ margin: "10px 0px 0px 0px" }}
              >
                <Input
                  value={currentTag}
                  onChange={(e) => setCurrentTag(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                htmlType="button"
                onClick={() => {
                  if (currentTag) {
                    setTags([...tags, currentTag]);
                    setCurrentTag("");
                    form.resetFields(["tags"]);
                  }
                }}
              >
                Add Tag
              </Button>
            </Col>
          </Row>

          <div style={{ marginTop: "4px" }}>
            {tags.map((tag, index) => (
              <Tag
                key={tag + index.toString()}
                color="gold"
                onClick={() => {
                  setTags(tags.filter((_, tagIndex) => tagIndex !== index));
                }}
                style={{ cursor: "pointer" }}
              >
                {tag}
              </Tag>
            ))}
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "10px" }}
              loading={submissionLoading}
            >
              Save Trade
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <AddSecurity
        visible={addSecurityPopupVisible}
        setVisible={setAddSecurityPopupVisible}
        setSelectedSecurity={setSelectedSecurity}
        updateSecurityName={updateSecurityName}
        setSecurityMeta={setSecurityMeta}
        setSecurityType={setSecurityType}
      />
    </Modal>
  );
};

export default AddBlotterTrade;
