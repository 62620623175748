/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialsecondLevelState = {
  secondLevelLoader: false,
  secondLevel: [],
  loaded: false,
  error: "",
};

export const fetchsecondLevel = createAsyncThunk(
  "secondLevel/fetchsecondLevel",
  ({ date, name }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/postions/client/?report_date=${date}&client_name=${name}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.data);
  }
);
const secondLevelSlice = createSlice({
  name: "secondLevel",
  initialState: initialsecondLevelState,
  extraReducers: (builder) => {
    builder.addCase(fetchsecondLevel.pending, (state) => {
      state.secondLevelLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchsecondLevel.fulfilled, (state, action) => {
      state.secondLevelLoader = false;
      state.secondLevel = action.payload;
      state.loaded = true;
      state.error = "";
    });
    builder.addCase(fetchsecondLevel.rejected, (state, action) => {
      state.secondLevelLoader = false;
      state.secondLevel = [];
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export default secondLevelSlice.reducer;
