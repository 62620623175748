import { DrawerForm, ProFormText } from '@ant-design/pro-components';
import React from 'react'


interface DrawerProps{
    drawerVisit: any,
    setDrawerVisit?: (value: boolean) => void;
    data:any
}

function TradeDrawer(props: DrawerProps) {
  const {drawerVisit,setDrawerVisit,data}=props;
  return (
    <DrawerForm
      onOpenChange={setDrawerVisit}
      open={drawerVisit}
      onFinish={async (value) => 
        // ParsingMappingAPI.postTickerinParsingMapping({...value,custodian_id})
        // ref?.current?.resetFields()
        true
      }
      width={600}
    //   formRef={ref}
    >
      {Object.keys(data).map((item:string)=> (
        <ProFormText
          name={item}
          placeholder={item}
          allowClear = {false}
          label={item.toUpperCase().split('_').join(' ')}
          initialValue={data[item]}
          disabled
        />
      ))}
        
    </DrawerForm>
  )
}

export default TradeDrawer