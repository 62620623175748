/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialfirstLevelQtdState = {
  firstLevelQtdLoader: false,
  firstLevelQtd: [],
  error: "",
};
export const fetchfirstLevelQtd = createAsyncThunk(
  "firstLevelQtd/fetchfirstLevelQtd",
  ({ start_date, end_date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/portfolio_movement/`,
        {
          start_date,
          end_date,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const firstLevelQtdSlice = createSlice({
  name: "firstLevelQtd",
  initialState: initialfirstLevelQtdState,
  extraReducers: (builder) => {
    builder.addCase(fetchfirstLevelQtd.pending, (state) => {
      state.firstLevelQtdLoader = true;
    });
    builder.addCase(fetchfirstLevelQtd.fulfilled, (state, action) => {
      state.firstLevelQtdLoader = false;
      state.firstLevelQtd = action.payload;
      state.error = "";
    });
    builder.addCase(fetchfirstLevelQtd.rejected, (state, action) => {
      state.firstLevelQtdLoader = false;
      state.firstLevelQtd = [];
      state.error = action.error.message;
    });
  },
});

export default firstLevelQtdSlice.reducer;
