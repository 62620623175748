import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  ModalForm,
  ProCard,
  ProForm,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { Form, Button, message, Tag, Row, Col, Typography, Modal } from "antd";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import { useState } from "react";
import { getCookie } from "redux/utils/helpers";

interface BankAccountProps {
  data: any;
  custodian: any;
  id: any;
  refetch: any;
}

type selectedItemId = {
  id: string;
  custodian: string;
  account_number: string;
  account_type: string;
  relationship_number: string;
  currency: string;
};

const BankAccount = (props: BankAccountProps) => {
  const { data, custodian, id, refetch } = props;
  const [form] = Form.useForm<any>();
  const queryClient = new QueryClient();
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<selectedItemId>();

  const ethanToken = getCookie("ethan_token");

  const config = {
    headers: {
      Accept: "*/*",
      Authorization: (ethanToken as unknown) as string,
    },
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
  };

  const addMutation = useMutation({
    mutationFn: async (add) =>
      axios.post(`${TRANSACTIONS_API_ROOT}/api/bank_account/`, add, config),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["bank"] }),
  });

  return (
    <>
      <Button
        style={{ margin: "3em", float: "right" }}
        onClick={() => setOpen(true)}
      >
        <PlusOutlined />
        Add a bank account
      </Button>
      <ModalForm<any>
        title={selectedItemId ? "Edit Bank Account" : "Add Bank Account"}
        initialValues={
          selectedItemId
            ? {
              custodian: selectedItemId?.custodian,
              account_number: selectedItemId?.account_number,
              account_type: selectedItemId?.account_type,
              relationship_number: selectedItemId?.relationship_number,
              currency: selectedItemId?.currency,
            }
            : {}
        }
        open={open}
        form={form}
        autoFocusFirstInput
        modalProps={{
          destroyOnClose: true,
          footer: null,
          onCancel: ()=> {
            setSelectedItemId(undefined)
            setOpen(false);
          },
        }}
        submitTimeout={2000}
        submitter={{
          submitButtonProps: {
            style: {
              display: "none",
            },
          },
          resetButtonProps: {
            style: {
              display: "none",
            },
          },
        }}
      >
        <ProForm
          initialValues={
            selectedItemId
              ? {
                custodian: selectedItemId?.custodian,
                account_number: selectedItemId?.account_number,
                account_type: selectedItemId?.account_type,
                relationship_number: selectedItemId?.relationship_number,
                currency: selectedItemId?.currency,
              }
              : {}
          }
          onFinish={async (values) => {
            if (selectedItemId) {
              try {
                await axios.patch(
                  `${TRANSACTIONS_API_ROOT}/api/bank_account/${selectedItemId?.id}/`,
                  { ...values, client: id },
                  config
                );
                setSelectedItemId(undefined)
                refetch();
                message.success("Bank account has been updated");
              } catch (err) {
                message.error("Something went wrong, could not update");
              }
            } else {
              try {
                addMutation.mutate(
                  { ...values, client: (id as unknown) as string } as any,
                  {
                    onSuccess: () => {
                      setSelectedItemId(undefined)
                      refetch();
                      message.success("Successful");
                    },
                    onError: () => {
                      message.error("Something went wrong, please try again");
                    },
                  }
                );
              } catch (err) {
                message.error("Something went wrong, could not add");
              }
            }
            setOpen(false);
            return true;
          }}
        >
          <ProForm.Group>
            <ProFormSelect
              width="md"
              name="custodian"
              label="Custodian"
              placeholder="Choose custodian"
              options={custodian?.map((el: any) => ({
                value: el?.id,
                label: el?.custodian_name,
              }))}
            />
            <ProFormText
              width="md"
              name="account_number"
              label="Account Number"
              placeholder="Enter account number"
            />
          </ProForm.Group>
          <ProForm.Group style={{ margin: "1em 0" }}>
            <ProFormText
              width="md"
              name="account_type"
              label="Account Type"
              placeholder="Enter account type"
            />
            <ProFormText
              width="md"
              name="currency"
              label="Currency"
              placeholder="Enter currency e.g SGD"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText
              width="md"
              name="relationship_number"
              label="Relationship Number"
              placeholder="Enter the relationship number"
            />
          </ProForm.Group>
        </ProForm>
      </ModalForm>
      <div style={{ marginTop: "1em" }}>
        {data
          ?.filter((el: any) => el?.client === id)
          .map((item: any) => (
            <ProCard
              key={item?.id}
              title="Bank Account"
              headerBordered
              bordered
              extra={
                <Tag color="volcano" style={{ border: "none" }}>
                  {item?.currency}
                </Tag>
              }
              actions={[
                <EditOutlined
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedItemId(item);
                  }}
                />,
                <DeleteOutlined
                  onClick={async () => {
                    try {
                      await axios.delete(
                        `${TRANSACTIONS_API_ROOT}/api/bank_account/${item?.id}`,
                        config
                      );
                      refetch();
                      message.success("Bank account has been deleted");
                    } catch (err) {
                      message.error("Something went wrong, could not delete");
                    }
                  }}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />,
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Row gutter={[8, 8]}>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Account Number
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.account_number}
                  </Typography.Text>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Account Type
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.account_type}
                  </Typography.Text>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Relationship Number
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.relationship_number}
                  </Typography.Text>
                </Col>
              </Row>
            </ProCard>
          ))}
      </div>
    </>
  );
};

export default BankAccount;
