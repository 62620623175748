import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ModalForm,
  ProCard,
  ProForm,
  ProFormDatePicker,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import { Button, Col, Form, Modal, Row, Tag, Typography, message } from "antd";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import axios from "axios";
import { useState } from "react";
import { getCookie } from "redux/utils/helpers";

interface EstateProps {
  id: string;
}

type selectedItemId = {
  id: string;
  type: string;
  name: string;
  email: string;
  phone: string;
  date_of_birth: string;
  relationship: string;
  percent_share: number;
};

const Estate = (props: EstateProps) => {
  const { id } = props;
  const [form] = Form.useForm<any>();
  const queryClient = new QueryClient();
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<selectedItemId>();

  const ethanToken = getCookie("ethan_token");

  const config = {
    headers: {
      Accept: "*/*",
      Authorization: (ethanToken as unknown) as string,
    },
  };

  const fetchData = async () => {
    const response = await axios.get(
      `${TRANSACTIONS_API_ROOT}/api/estate/`,
      config
    );
    return response.data;
  };

  const types: any = {
    nominee: "nominee",
    beneficiary_person: "beneficiary person",
    beneficiary_trust: "beneficiary trust",
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
  };

  const { data, isLoading, isError, refetch } = useQuery(["estate"], () =>
    fetchData()
  );

  const addMutation = useMutation({
    mutationFn: async (add) =>
      axios.post(`${TRANSACTIONS_API_ROOT}/api/estate/`, add, config),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["estate"] }),
  });

  return (
    <>
      <Button
        style={{ margin: "3em", float: "right" }}
        onClick={() => setOpen(true)}
      >
        <PlusOutlined />
        Add an estate
      </Button>
      <ModalForm<any>
        title={selectedItemId ? "Edit Estate" : "Add Estate"}
        initialValues={
          selectedItemId
            ? {
              type: selectedItemId?.type,
              name: selectedItemId?.name,
              email: selectedItemId?.email,
              phone: selectedItemId?.phone,
              date_of_birth: selectedItemId?.date_of_birth,
              relationship: selectedItemId?.relationship,
              percent_share: selectedItemId?.percent_share,
            }
            : {}
        }
        open={open}
        form={form}
        autoFocusFirstInput
        modalProps={{
          destroyOnClose: true,
          footer: null,
          onCancel: ()=>{
            setSelectedItemId(undefined)
            setOpen(false)
          },
        }}
        submitTimeout={2000}
        submitter={{
          submitButtonProps: {
            style: {
              display: "none",
            },
          },
          resetButtonProps: {
            style: {
              display: "none",
            },
          },
        }}
      >
        <ProForm
          initialValues={
            selectedItemId
              ? {
                type: selectedItemId?.type,
                name: selectedItemId?.name,
                email: selectedItemId?.email,
                phone: selectedItemId?.phone,
                date_of_birth: selectedItemId?.date_of_birth,
                relationship: selectedItemId?.relationship,
                percent_share: selectedItemId?.percent_share,
              }
              : {}
          }
          onFinish={async (values) => {
            if (selectedItemId) {
              try {
                await axios.patch(
                  `${TRANSACTIONS_API_ROOT}/api/estate/${selectedItemId?.id}/`,
                  { ...values, client: id },
                  config
                );
                setSelectedItemId(undefined)
                refetch();
                message.success("Estate has been updated");
              } catch (err) {
                message.error("Something went wrong, could not update");
              }
            } else {
              try {
                addMutation.mutate(
                  { ...values, client: (id as unknown) as string } as any,
                  {
                    onSuccess: () => {
                      refetch();
                      setSelectedItemId(undefined)
                      message.success("Successful");
                    },
                    onError: () => {
                      message.error("Something went wrong, please try again");
                    },
                  }
                );
              } catch (err) {
                message.error("Something went wrong, could not add");
              }
            }
            setOpen(false);
            return true;
          }}
        >
          <ProForm.Group>
            <ProFormSelect
              width="md"
              name="type"
              label="Type"
              placeholder="Choose Type"
              options={[
                {
                  value: "nominee",
                  label: "Nominee",
                },
                {
                  value: "beneficiary_trust",
                  label: "Beneficiary (Trust)",
                },
                {
                  value: "beneficiary_person",
                  label: "Beneficiary (Person)",
                },
              ]}
            />
            <ProFormText
              width="md"
              name="name"
              label="Name"
              placeholder="Enter the name"
            />
          </ProForm.Group>
          <ProForm.Group style={{ margin: "1em 0" }}>
            <ProFormText
              width="md"
              name="email"
              label="Email"
              placeholder="Enter email"
              rules={[{type:'email'}]}
            />
            <ProFormText
              width="md"
              name="phone"
              label="Phone Number"
              placeholder="Enter Phone Number"
              fieldProps={
                {
                  type: "number",
                } as any
              }
            />
          </ProForm.Group>
          <ProForm.Group style={{ margin: "1em 0" }}>
            <ProFormDatePicker
              width="md"
              name="date_of_birth"
              label="Date Of Birth"
              placeholder="Choose Date Of Birth"
            />
            <ProFormText
              width="md"
              name="relationship"
              label="Relationship"
              placeholder="Enter Relationship"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText
              width="md"
              name="percent_share"
              label="% Share"
              placeholder="Enter the share"
              fieldProps={
                {
                  type: "number",
                } as any
              }
            />
          </ProForm.Group>
        </ProForm>
      </ModalForm>
      <div style={{ marginTop: "1em" }}>
        {data
          ?.filter((el: any) => el?.client === id)
          .map((item: any) => (
            <ProCard
              key={item?.id}
              title="Estate"
              headerBordered
              bordered
              extra={
                <Tag color="volcano" style={{ border: "none" }}>
                  {types[item?.type]}
                </Tag>
              }
              actions={[
                <EditOutlined
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedItemId(item);
                  }}
                />,
                <DeleteOutlined
                  onClick={async () => {
                    try {
                      await axios.delete(
                        `${TRANSACTIONS_API_ROOT}/api/estate/${item?.id}`,
                        config
                      );
                      refetch();
                      message.success("Estate has been deleted");
                    } catch (err) {
                      message.error("Something went wrong, could not delete");
                    }
                  }}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />,
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Row gutter={[8, 8]}>
                <Col
                  span={8}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">Email</Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.email}
                  </Typography.Text>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">Name</Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.name}
                  </Typography.Text>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Phone Number
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.phone}
                  </Typography.Text>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Date Of Birth
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.date_of_birth}
                  </Typography.Text>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">
                    Relationship
                  </Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.relationship}
                  </Typography.Text>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text type="secondary">% Share</Typography.Text>
                  <Typography.Text style={{ marginTop: "0.3em" }}>
                    {item?.percent_share}
                  </Typography.Text>
                </Col>
              </Row>
            </ProCard>
          ))}
      </div>
    </>
  );
};

export default Estate;
