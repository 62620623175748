/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialfirstLevelState = {
  firstLevelLoader: false,
  firstLevel: [],
  loaded: false,
  error: "",
};

export const fetchfirstLevel = createAsyncThunk(
  "firstLevel/fetchfirstLevel",
  (date) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/postions/date/`,
        {
          report_date: date,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.data);
  }
);
const firstLevelSlice = createSlice({
  name: "firstLevel",
  initialState: initialfirstLevelState,
  extraReducers: (builder) => {
    builder.addCase(fetchfirstLevel.pending, (state) => {
      state.firstLevelLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchfirstLevel.fulfilled, (state, action) => {
      state.firstLevelLoader = false;
      state.firstLevel = action.payload;
      state.loaded = true;
      state.error = "";
    });
    builder.addCase(fetchfirstLevel.rejected, (state, action) => {
      state.firstLevelLoader = false;
      state.firstLevel = [];
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export default firstLevelSlice.reducer;
