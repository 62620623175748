/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialthirdLevelState = {
  thirdLevelLoader: false,
  thirdLevel: [],
  error: "",
};

export const fetchthirdLevel = createAsyncThunk(
  "thirdLevel/fetchthirdLevel",
  ({ date, asset, name }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/postions/client_asset_class/?report_date=${date}&asset_class=${asset}&client_name=${name}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.data);
  }
);
const thirdLevelSlice = createSlice({
  name: "thirdLevel",
  initialState: initialthirdLevelState,
  extraReducers: (builder) => {
    builder.addCase(fetchthirdLevel.pending, (state) => {
      state.thirdLevelLoader = true;
    });
    builder.addCase(fetchthirdLevel.fulfilled, (state, action) => {
      state.thirdLevelLoader = false;
      state.thirdLevel = action.payload;
      state.error = "";
    });
    builder.addCase(fetchthirdLevel.rejected, (state, action) => {
      state.thirdLevelLoader = false;
      state.thirdLevel = [];
      state.error = action.error.message;
    });
  },
});

export default thirdLevelSlice.reducer;
