/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialtradesState = {
  tradesLoader: false,
  trades: "",
  error: "",
};

export const fetchtrades = createAsyncThunk("trades/fetchtrades", (date) => {
  const ethan_token = getCookie("ethan_token");
  return axios
    .get(`${TRANSACTIONS_API_ROOT}/trade_count/?report_date=${date}`, {
      headers: {
        "Authorization": ethan_token,
      },
    })
    .then((response) => {
      const trades = Math.round(response.data.data);
      const tradesDone = Math.round(response.data.trade_sum);
      return { trades, tradesDone };
    });
});
const tradesSlice = createSlice({
  name: "trades",
  initialState: initialtradesState,
  extraReducers: (builder) => {
    builder.addCase(fetchtrades.pending, (state) => {
      state.tradesLoader = true;
    });
    builder.addCase(fetchtrades.fulfilled, (state, action) => {
      state.tradesLoader = false;
      state.trades = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtrades.rejected, (state, action) => {
      state.tradesLoader = false;
      state.trades = "";
      state.error = action.error.message;
    });
  },
});

export default tradesSlice.reducer;
