/* eslint-disable import/no-unresolved */
/* eslint-disable no-empty */
/* eslint-disable no-useless-return */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
import {  Segmented, Space, Spin } from "antd"
import axios from "axios";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { getCookie } from "redux/utils/helpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/app/store";
import { useQuery } from "@tanstack/react-query";
import { selectedClient } from "../../../../../../redux/features/selectedClient/selectedClientSlice";
import { selectedCustodian } from "../../../../../../redux/features/selectedCustodian/selectedCustodianSlice";


type ClientSelectionTabProps = {
  selectedClientId:number | undefined;
  selectedCustodianId:number | undefined;
  setSelectedClientId:any
  setSelectedCustodianId:any
}

const ClientSelectionTabs = (props:ClientSelectionTabProps) => {
  const {selectedClientId,selectedCustodianId,setSelectedClientId,setSelectedCustodianId} = props
  const ethan_token = getCookie("ethan_token");
  const dispatch = useDispatch<AppDispatch>();

  type ItemType = {
    client_name: string;
    client_id: string;
  };

  const [options, setOptions] = useState([
    {
      label: "" as string,
      value: "" as string,
    },
  ]);
  const [custodianOptions, setCustodianOptions] = useState([
    {
      label: "" as string,
      value: "" as string,
    },
  ]);

  const { isLoading: isClientsLoading, data: clientsData } = useQuery(
    ["clients"],
    async () => {
      const response = await axios.get(
        `${TRANSACTIONS_API_ROOT}/position_history/client_view/`,
        {
          headers: {
            "Authorization": ethan_token as unknown as string,
          },
        }
      );
      return response?.data?.data || [];
    },{
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: true,
    }
  );

  useEffect(() => {
    if (clientsData && clientsData.length > 0) {
      setSelectedClientId(clientsData[0]?.client_id?.toString());
      setOptions(
        clientsData.map((item: ItemType) => ({
          label: (
            <div style={{ padding: 4 }}>
              <div>{item?.client_name}</div>
            </div>
          ),
          value: item?.client_id?.toString(),
        }))
      );
    }
  }, [clientsData]);

  const { isLoading: isCustodiansLoading, data: custodiansData } = useQuery(
    ["custodians",selectedClientId],
    async () => {
      const response = await axios.get(
        `${TRANSACTIONS_API_ROOT}/position_history/custodian_view/`,
        {
          params: {
            client_id: selectedClientId,
          },
          headers: {
            Accept: "*/*",
            "Authorization": ethan_token as unknown as string,
          },
        }
      );
      return response?.data || [];
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!selectedClientId,
    }
  );

  interface CustodianType {
    custodian_name: string;
    custodian_id: string | number ;
  }

  useEffect(() => {
    if (custodiansData && custodiansData?.length > 0) {
      setSelectedCustodianId(custodiansData[0]?.custodian_id?.toString());
      setCustodianOptions(
        custodiansData.map((item: CustodianType) => ({
          label: (
            <div style={{ padding: 4 }}>
              <div>{item.custodian_name}</div>
            </div>
          ),
          value: item.custodian_id?.toString(),
        }))
      );
    }
  }, [custodiansData]);

  useEffect(() => {
    dispatch(selectedClient({ selectedClientId }));
  }, [selectedClientId]);

  useEffect(() => {
    dispatch(selectedCustodian(selectedCustodianId));
  }, [selectedCustodianId]);


  return (
    <Space direction="vertical" >
      <div style={{overflow:"scroll"}}>
        {
          isClientsLoading ? <Spin /> : <Segmented options={[...options]} value={selectedClientId}  onChange={(e) => setSelectedClientId(e as string)} />
        }
      </div>
      <div style={{overflow:"scroll"}}>
        {
          isCustodiansLoading ? <Spin /> : <Segmented options={[...custodianOptions]} value={selectedCustodianId} onChange={(e) => setSelectedCustodianId(e as string)} />
        }
      </div>
    </Space>
  );
}

export default ClientSelectionTabs;