/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialSecurityState = {
  securityLoader: false,
  security: [],
  loaded: false,
  error: "",
};

export const fetchSecurity = createAsyncThunk("security/fetchSecurity", () => {
  const ethanToken = getCookie("ethan_token");
  return axios
    .get(`${TRANSACTIONS_API_ROOT}/historical/`, {
      headers: {
        "Authorization": ethanToken,
      },
    })
    .then((response) => response.data.data);
});
const securitySlice = createSlice({
  name: "security",
  initialState: initialSecurityState,
  extraReducers: (builder) => {
    builder.addCase(fetchSecurity.pending, (state) => {
      state.securityLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchSecurity.fulfilled, (state, action) => {
      state.securityLoader = false;
      state.security = action.payload;
      state.loaded = true;
      state.error = "";
    });
    builder.addCase(fetchSecurity.rejected, (state, action) => {
      state.securityLoader = false;
      state.security = [];
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export default securitySlice.reducer;
