/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialtradeDataState = {
  tradeDataLoader: false,
  loaded: false,
  tradeData: [],
  error: "",
  actions: [],
  tradeDates: [],
};

function sortFunction(a, b) {
  const dateA = new Date(a.trade_date).getTime();
  const dateB = new Date(b.trade_date).getTime();
  return dateA > dateB ? 1 : -1;
}
export const fetchtradeData = createAsyncThunk(
  "tradeData/fetchtradeData",
  ({ startDate, lastDate }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/trades/date/`,
        {
          trade_date_start: startDate,
          trade_date_end: lastDate,
        },
        {
          headers: {
            Accept: "*/*",
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data)
      .then((resJson) => {
        const array = resJson.data;
        array.sort(sortFunction);
        array.reverse();
        return array;
      });
  }
);
const tradeDataSlice = createSlice({
  name: "tradeData",
  initialState: initialtradeDataState,
  extraReducers: (builder) => {
    builder.addCase(fetchtradeData.pending, (state) => {
      state.tradeDataLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchtradeData.fulfilled, (state, action) => {
      state.tradeDataLoader = false;
      state.tradeData = action.payload;
      state.loaded = true;
      state.actions = Array.from(
        new Set(action.payload.map((item) => item.action))
      );
      state.tradeDates = Array.from(
        new Set(action.payload.map((item) => item.trade_date))
      );
      state.error = "";
    });
    builder.addCase(fetchtradeData.rejected, (state, action) => {
      state.tradeDataLoader = false;
      state.tradeData = [];
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export default tradeDataSlice.reducer;
