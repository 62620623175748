/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { POSITION_ACTION_TYPES } from "./positionStatement.types";


interface IClient {
    client_id: string;
    rpt_currency: string;
    positionStatement: any;
}

interface IPositionStatementState {
    clientList: IClient[];
}

const POSITION_INITIAL_STATE: IPositionStatementState = {
  clientList: [],
}

interface IAction {
    type: string;
    payload: any;
}
interface IPositionStatementReducer {
    (state: IPositionStatementState, action: IAction): IPositionStatementState;
}
// eslint-disable-next-line default-param-last
export const positionStatementReducer: IPositionStatementReducer = (state = POSITION_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case POSITION_ACTION_TYPES.SET_CLIENT_LIST:
      return {
        ...state,
        clientList: payload
      }
    case POSITION_ACTION_TYPES.SET_POSITION_STATEMENT: {
      const clientList: any[] = [];
      state?.clientList?.forEach((client) => {
        payload?.filter((item: any) => {
          if(client?.client_id === item?.client_id){
            client = {
              rpt_currency: client?.rpt_currency,
              ...item,
            }
            clientList.push(client);
          }
        });
      })
      return {
        ...state,
        clientList
      }
    }
     
    default:
      return state;  
  }
}