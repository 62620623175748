import { Pagination, Table, Typography } from "antd";
import { IBlotterTrade, IBlotterTradeTable } from "../interfaces";
import { tradeColumns } from "../constants";

interface IBlotterTradesPageProps {
  blotterTrades: IBlotterTradeTable[];
  updateBlotterTrades: (page: number) => Promise<number>;
  totalBlotterTrades: number;
}

const BlotterTradesPage = ({
  blotterTrades,
  updateBlotterTrades,
  totalBlotterTrades,
}: IBlotterTradesPageProps) => (
  <>
    <Typography.Title level={3}>Blotter Trades for Client</Typography.Title>
    <Table
      dataSource={blotterTrades}
      columns={tradeColumns}
      scroll={{ x: 400 }}
      // pagination={false}
    />
    {/* TODO fix this custom pagination after demo to client */}
    {/* <Pagination
      onChange={(page) => updateBlotterTrades(page)}
      total={totalBlotterTrades}
      showSizeChanger={false}
      pageSize={10}
    /> */}
  </>
);

export default BlotterTradesPage;
