/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialfourthLevelState = {
  fourthLevelLoader: false,
  fourthLevel: [],
  error: "",
};

export const fetchfourthLevel = createAsyncThunk(
  "fourthLevel/fetchfourthLevel",
  ({ date, security_id, firstmodalTitle }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/trades/client_security_date/?trade_date=${date}&security_id=${security_id}&client_name=${firstmodalTitle}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.data);
  }
);
const fourthLevelSlice = createSlice({
  name: "fourthLevel",
  initialState: initialfourthLevelState,
  extraReducers: (builder) => {
    builder.addCase(fetchfourthLevel.pending, (state) => {
      state.fourthLevelLoader = true;
    });
    builder.addCase(fetchfourthLevel.fulfilled, (state, action) => {
      state.fourthLevelLoader = false;
      state.fourthLevel = action.payload;
      state.error = "";
    });
    builder.addCase(fetchfourthLevel.rejected, (state, action) => {
      state.fourthLevelLoader = false;
      state.fourthLevel = [];
      state.error = action.error.message;
    });
  },
});

export default fourthLevelSlice.reducer;
