/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploaded: false,
  error: false
}

const uploadSlice = createSlice({
  name: 'scenarioUpload',
  initialState,
  reducers: {
    uploadedAction: (state, action) => {
      state.uploaded = action.payload;
    },
    errorAction: (state, action) => {
      state.error = action.payload;
    },
  }
})

export const { uploadedAction, errorAction } = uploadSlice.actions;

export const selectUploaded = (state) => state.scenarioUpload.uploaded;
export const selectError = (state) => state.scenarioUpload.error;

export default uploadSlice.reducer;
