/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialTopContributorYtd = {
  topContributorYtdLoader: false,
  topContributorYtd: [],
  error: "",
};

export const fetchtopContributorYtd = createAsyncThunk(
  "topContributorYtd/fetchtopContributorYtd",
  ({ client_name, end_date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/top_contributor/?client_name=${client_name}&period_type=YTD&report_date=${end_date}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.message)
  }
);
const topContributorYtdSlice = createSlice({
  name: "topContributorYtd",
  initialState: initialTopContributorYtd,
  extraReducers: (builder) => {
    builder.addCase(fetchtopContributorYtd.pending, (state) => {
      state.topContributorYtdLoader = true;
    });
    builder.addCase(fetchtopContributorYtd.fulfilled, (state, action) => {
      state.topContributorYtdLoader = false;
      state.topContributorYtd = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtopContributorYtd.rejected, (state, action) => {
      state.topContributorYtdLoader = false;
      state.topContributorYtd = [];
      state.error = action.error.message;
    });
  },
});

export default topContributorYtdSlice.reducer;
