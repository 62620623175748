/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialchartParamsState = {
  chartParamsLoader: false,
  chartParams: [],
  error: "",
};

export const fetchchartParams = createAsyncThunk(
  "chartParams/fetchchartParams",
  ({ start_date, end_date, Rf, days_req, client_name }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/sharpe/`,
        {
          start_date,
          end_date,
          Rf,
          days_req,
          client_name,
        },
        {
          headers: {
            Accept: "*/*",
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.message);
  }
);
const chartParamsSlice = createSlice({
  name: "chartParams",
  initialState: initialchartParamsState,
  extraReducers: (builder) => {
    builder.addCase(fetchchartParams.pending, (state) => {
      state.chartParamsLoader = true;
    });
    builder.addCase(fetchchartParams.fulfilled, (state, action) => {
      state.chartParamsLoader = false;
      state.chartParams = action.payload;
      state.error = "";
    });
    builder.addCase(fetchchartParams.rejected, (state, action) => {
      state.chartParamsLoader = false;
      state.chartParams = [];
      state.error = action.error.message;
    });
  },
});

export default chartParamsSlice.reducer;
