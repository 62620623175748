import styles from './Auth.module.css'
import { ReactComponent as Logo } from "../../../assets/svg/ethan-logo.svg";

const BrandingComponent = () => (
  <div className={styles.logo__section}>
    <div className={styles.circles}>
      <Logo className={styles.logo} />
      <span className={styles.brand}>ethan</span>
    </div>
  </div>
)

export default BrandingComponent