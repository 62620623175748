import PreLoader from 'components/PreLoader/PreLoader';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppDispatch } from 'redux/app/store';
import { netWorthLastDate } from 'redux/features/summary/newNetworth/networthDateSlice';
import TradeDrawer from './TradeDrawer';
import TradesPage from './TradesTable'

interface TradeProps {
  drawerOff?: boolean;
}

function Trades(props: TradeProps) {
  const { drawerOff } = props;
  const [data2,setData2]=useState<any>(null);
  const [drawervisit,setDrawerVisit]= useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const onOpenDrawer=(data3:any)=>{
    setData2(data3)
    setDrawerVisit((prev: any)=> !prev)
  }

  const netWorthLastDateValue= useSelector((state:any) => state?.networthDateReducer?.networthDate?.last_date);
  const showLoader = useSelector((state:any) => state?.networthDateReducer?.lastDateLoader);
  
  
  useEffect(() => {
    if(!netWorthLastDateValue) {
      dispatch(netWorthLastDate(undefined));
    }
  }, []);

  if(showLoader) return <PreLoader isLoaderOpen={showLoader} />

  return (
    <>
      <TradesPage showDrawer={onOpenDrawer} />      
      { drawerOff && drawervisit &&
      <TradeDrawer  drawerVisit={drawervisit} setDrawerVisit={setDrawerVisit} data={data2} />}
    </>

  )
}

export default Trades