/* eslint-disable import/no-unresolved */
/* eslint-disable no-constant-condition */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tag, Tooltip, Typography } from "antd";
import React from "react";
import { CurrencyFlag } from "views/Dashboard/Tables/statementPosition/utils";

interface CurrencyValueComponentProps {
  currency?: string;
  value?: string;
}
interface CurrencyComponentProps {
  currency?: string;
}

export const CurrencyComponent = (props: CurrencyComponentProps) => (
  <Tag>
    <Typography.Text type="secondary">
      {props.currency}
      <CurrencyFlag
        style={{
          width: 15,
          display: "inline-block",
          marginLeft: 5,
          marginTop: "-2.5px",
        }}
        currency={props.currency}
      />
    </Typography.Text>
  </Tag>
);

const trimNumber = (str: string): string => {
  if (str?.[0] === "-") {
    return str?.length > 6 ? `${str?.substring(0, 7)}` : str;
  }
  return str?.length > 5 ? `${str?.substring(0, 6)}` : str;
};

export const integerValueShorter = (value: number) => {
  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(2)}B`;
  }
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(2)}M`;
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(2)}K`;
  }
  // Negative values
  if (value <= -1000000000) {
    return `${(value / 1000000000).toFixed(2)}B`;
  }
  if (value <= -1000000) {
    return `${(value / 1000000).toFixed(2)}M`;
  }
  if (value <= -1000) {
    return `${(value / 1000).toFixed(2)}K`;
  }
  if (value === 0) return "0";

  if (value > 0 && value < 1) return `${value?.toFixed(5)}`;

  if (value < 0 && value > -1) return `${value?.toFixed(5)}`;

  return trimNumber(value?.toString());
};

interface NumberValueShortComponentProps {
  value?: string;
}

export const NumberValueShortComponent = (
  props: NumberValueShortComponentProps
) => (
  <Tooltip title={parseFloat(props?.value || "0").toString()}>
    <span>{integerValueShorter(parseFloat(props?.value || "0"))}</span>
  </Tooltip>
);

export const CurrencyValueComponent = (props: CurrencyValueComponentProps) => {
  const { currency, value } = props;
  if (!currency) return null;
  return (
    <>
      <CurrencyComponent currency={currency} />
      <Typography.Text style={{ marginLeft: 2 }}>
        <NumberValueShortComponent value={value} />
      </Typography.Text>
    </>
  );
};
