/* eslint-disable no-unneeded-ternary */
import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { getCookie } from "redux/utils/helpers";
import request from "axios";
import { Form } from "antd";
import { BaseOptionType } from "antd/lib/select";

interface ICustodianFormFieldProps {
  name?: string;
  clientId?: string;
  disable?: boolean;
  disabledLabel?: boolean;
  dependencies?: string[];
  shouldHaveCustodianName?: boolean;
  required?: boolean;
  onChange?: (value: string, options: BaseOptionType) => void;
  type?: string;
}

interface ICustodian {
  custodian_id: string;
  custodian_name: string;
}


const CustodianFormSelect = (props: ICustodianFormFieldProps) => {
  const { disable, clientId, required, disabledLabel, dependencies, onChange, shouldHaveCustodianName ,name, type} = props;
  const form = Form.useFormInstance();
  const changeCustodianName = (value: string, options: BaseOptionType) => {
    if(shouldHaveCustodianName){
      form?.setFieldsValue({
        custodian_name: options.label,
      })
    }
    onChange?.(value, options);
  }
  return (<>
    <ProFormSelect
      name={name ? name : "custodian"}
      label={disabledLabel ? null : "Custodian"}
      disabled={disable}
      showSearch
      allowClear={type ? false : true}
      dependencies={dependencies}
      debounceTime={300}
      request={async () => {
        const ethanToken = getCookie("ethan_token");
        const response = await request.get(`${TRANSACTIONS_API_ROOT}/custodian/`, {
          params: {
            client_id: clientId
          },
          headers: {
            Accept: "*/*",
            "Authorization": ethanToken as unknown as string,
          },
        })
        const items = response.data.map((custodian: ICustodian) => ({
          value: custodian.custodian_id,
          label: custodian.custodian_name,
        }))
        if(type){
          items.push({value: null, label: type});
          return items;
        }
        return items;
      }}
      fieldProps={{
        onChange: changeCustodianName,
      }}
      placeholder="Select custodian"
      rules={[{ required, message: 'Please select the custodian' }]}
    />
    {shouldHaveCustodianName && <ProFormText name="custodian_name" label="Custodian Name" hidden />}
  </>
  )

}

export default CustodianFormSelect;