/* eslint-disable no-console */
/* eslint-disable no-alert */
// Assets
import axios from "axios";
import React from "react";
import SignupComponent from "./components/SignupComponent";

function Submit() {
  const sendEmail = async (id) => {
    await axios
      .get(`https://login-server.ethan-ai.com/api/send_email/?contact_id=${id}`)
      .then((data) => window.alert(data.data.response));
  };
  const submitUserData = async (
    username,
    email,
    phoneNumber,
    countryCode,
    company,
    designation,
    time,
    media
  ) => {
    // @TODO: Add API
    await axios
      .get(
        `https://login-server.ethan-ai.com/api/contact_us/?username=${username}&email=${email}&phone_number=${phoneNumber}&code=${countryCode}&media=${media}&company=${company}&designation=${designation}&time=${time}`
      )
      .then((data) => {
        sendEmail(data.data.response.id);
        window.alert("Thank you! You data is recorded");
      })
      .catch((err) => console.log(err));
  };
  return <SignupComponent submitUserData={submitUserData} />;
}

export default Submit;
