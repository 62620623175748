/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../api/api-config";

const initialClientStatusState = {
  clientStatusLoader: false,
  clientStatus: [],
  error: "",
};

export const fetchClientStatus = createAsyncThunk(
  "clientStatus/fetchClientStatus",
  () => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/client_date/`, {
        headers: {
          "Authorization": ethan_token,
        },
      })
      .then((response) => {
        const arr = response.data.response.sort((a, b) => new Date(b.max_date) - new Date(a.max_date));
        return arr;
      });
  }
);
const clientStatusSlice = createSlice({
  name: "clientStatus",
  initialState: initialClientStatusState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientStatus.pending, (state) => {
      state.clientStatusLoader = true;
    });
    builder.addCase(fetchClientStatus.fulfilled, (state, action) => {
      state.clientStatusLoader = false;
      state.clientStatus = action.payload;
      state.error = "";
    });
    builder.addCase(fetchClientStatus.rejected, (state, action) => {
      state.clientStatusLoader = false;
      state.clientStatus = [];
      state.error = action.error.message;
    });
  },
});

export default clientStatusSlice.reducer;
