import { getCookie } from "redux/utils/helpers";
import request from 'axios';
import { TRANSACTIONS_API_ROOT } from "api/api-config";

export const getDownloadPath = async (filePath: string) => {
  const ethanToken = getCookie("ethan_token");
  const response = await request.post(`${TRANSACTIONS_API_ROOT}/download/`, {
    file_path: filePath,
  },  {
    headers: {
      Accept: "*/*",
      "Authorization": ethanToken as unknown as string,
    },
  })
  window.open(response.data.response);
};