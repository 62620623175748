/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialBenchmarkState = {
  benchmarkLoader: false,
  benchmark: [],
  error: "",
};

export const fetchBenchmark = createAsyncThunk(
  "benchmark/fetchBenchmark",
  () => {
    const ethanToken = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/benchmark/`, {
        headers: {
          "Authorization": ethanToken,
        },
      })
      .then((response) => response.data.data);
  }
);
const benchmarkSlice = createSlice({
  name: "benchmark",
  initialState: initialBenchmarkState,
  extraReducers: (builder) => {
    builder.addCase(fetchBenchmark.pending, (state) => ({
      ...state,
      benchmarkLoader: true,
    }))
    builder.addCase(fetchBenchmark.fulfilled, (state, action) => ({
      ...state,
      benchmarkLoader: false,
      benchmark: action.payload,
      error: ""
    }))
    builder.addCase(fetchBenchmark.rejected, (state, action) => ({
      ...state,
      benchmarkLoader: false,
      benchmark: [],
      error: action.error.message,
    }));
  },
});

export default benchmarkSlice.reducer;
