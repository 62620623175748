export const ClientMonthlyIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}}  viewBox="0 0 74 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52.0074 60.4191L49.9237 43.7364C49.6284 41.3734 48.48 39.1997 46.6945 37.624C44.9089 36.0484 42.6093 35.1793 40.2279 35.1802H33.0521C30.6719 35.1808 28.3738 36.0507 26.5896 37.6262C24.8055 39.2017 23.658 41.3745 23.3629 43.7364L21.2759 60.4191C21.1614 61.3357 21.2431 62.2661 21.5158 63.1486C21.7884 64.0311 22.2457 64.8456 22.8573 65.5378C23.4688 66.23 24.2206 66.7842 25.0628 67.1636C25.905 67.543 26.8182 67.7388 27.7419 67.7382H45.5446C46.468 67.7384 47.3809 67.5422 48.2227 67.1626C49.0645 66.783 49.8159 66.2288 50.4271 65.5366C51.0383 64.8444 51.4953 64.0301 51.7677 63.1478C52.0402 62.2656 52.1219 61.3354 52.0074 60.4191V60.4191Z" stroke="#AEAEAE" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M36.6391 22.1539C42.0335 22.1539 46.4065 17.7809 46.4065 12.3865C46.4065 6.99215 42.0335 2.61914 36.6391 2.61914C31.2447 2.61914 26.8717 6.99215 26.8717 12.3865C26.8717 17.7809 31.2447 22.1539 36.6391 22.1539Z" stroke="#AEAEAE" strokeWidth="4"/>
    <path d="M10.5945 31.9229C14.1907 31.9229 17.1061 29.0075 17.1061 25.4113C17.1061 21.815 14.1907 18.8997 10.5945 18.8997C6.99823 18.8997 4.08289 21.815 4.08289 25.4113C4.08289 29.0075 6.99823 31.9229 10.5945 31.9229Z" stroke="#AEAEAE" strokeWidth="4"/>
    <path d="M62.6869 31.9229C66.2831 31.9229 69.1985 29.0075 69.1985 25.4113C69.1985 21.815 66.2831 18.8997 62.6869 18.8997C59.0906 18.8997 56.1753 21.815 56.1753 25.4113C56.1753 29.0075 59.0906 31.9229 62.6869 31.9229Z" stroke="#AEAEAE" strokeWidth="4"/>
    <path d="M10.5929 41.6892H9.59658C8.05516 41.689 6.56365 42.2356 5.38741 43.2318C4.21116 44.228 3.42645 45.6092 3.17289 47.1296L2.08871 53.6412C1.93312 54.5741 1.98264 55.5298 2.23382 56.4416C2.48499 57.3535 2.9318 58.1997 3.54316 58.9214C4.15452 59.643 4.91576 60.2229 5.77392 60.6205C6.63209 61.0182 7.56658 61.2241 8.5124 61.224H20.3603M62.6856 41.6892H63.6819C65.2233 41.689 66.7148 42.2356 67.8911 43.2318C69.0673 44.228 69.852 45.6092 70.1056 47.1296L71.1898 53.6412C71.3454 54.5741 71.2958 55.5298 71.0447 56.4416C70.7935 57.3535 70.3467 58.1997 69.7353 58.9214C69.124 59.643 68.3627 60.2229 67.5046 60.6205C66.6464 61.0182 65.7119 61.2241 64.7661 61.224H52.9182" stroke="#AEAEAE" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const ClientMonthlySelectedIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}}  viewBox="0 0 74 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52.0074 60.4191L49.9237 43.7364C49.6284 41.3734 48.48 39.1997 46.6945 37.624C44.9089 36.0484 42.6093 35.1793 40.2279 35.1802H33.0521C30.6719 35.1808 28.3738 36.0507 26.5896 37.6262C24.8055 39.2017 23.658 41.3745 23.3629 43.7364L21.2759 60.4191C21.1614 61.3357 21.2431 62.2661 21.5158 63.1486C21.7884 64.0311 22.2457 64.8456 22.8573 65.5378C23.4688 66.23 24.2206 66.7842 25.0628 67.1636C25.905 67.543 26.8182 67.7388 27.7419 67.7382H45.5446C46.468 67.7384 47.3809 67.5422 48.2227 67.1626C49.0645 66.783 49.8159 66.2288 50.4271 65.5366C51.0383 64.8444 51.4953 64.0301 51.7677 63.1478C52.0402 62.2656 52.1219 61.3354 52.0074 60.4191V60.4191Z" stroke="#0FABE0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M36.6391 22.1539C42.0335 22.1539 46.4065 17.7809 46.4065 12.3865C46.4065 6.99215 42.0335 2.61914 36.6391 2.61914C31.2447 2.61914 26.8717 6.99215 26.8717 12.3865C26.8717 17.7809 31.2447 22.1539 36.6391 22.1539Z" stroke="#0FABE0" strokeWidth="4"/>
    <path d="M10.5945 31.9229C14.1907 31.9229 17.1061 29.0075 17.1061 25.4113C17.1061 21.815 14.1907 18.8997 10.5945 18.8997C6.99823 18.8997 4.08289 21.815 4.08289 25.4113C4.08289 29.0075 6.99823 31.9229 10.5945 31.9229Z" stroke="#0FABE0" strokeWidth="4"/>
    <path d="M62.6869 31.9229C66.2831 31.9229 69.1985 29.0075 69.1985 25.4113C69.1985 21.815 66.2831 18.8997 62.6869 18.8997C59.0906 18.8997 56.1753 21.815 56.1753 25.4113C56.1753 29.0075 59.0906 31.9229 62.6869 31.9229Z" stroke="#0FABE0" strokeWidth="4"/>
    <path d="M10.5929 41.6892H9.59658C8.05516 41.689 6.56365 42.2356 5.38741 43.2318C4.21116 44.228 3.42645 45.6092 3.17289 47.1296L2.08871 53.6412C1.93312 54.5741 1.98264 55.5298 2.23382 56.4416C2.48499 57.3535 2.9318 58.1997 3.54316 58.9214C4.15452 59.643 4.91576 60.2229 5.77392 60.6205C6.63209 61.0182 7.56658 61.2241 8.5124 61.224H20.3603M62.6856 41.6892H63.6819C65.2233 41.689 66.7148 42.2356 67.8911 43.2318C69.0673 44.228 69.852 45.6092 70.1056 47.1296L71.1898 53.6412C71.3454 54.5741 71.2958 55.5298 71.0447 56.4416C70.7935 57.3535 70.3467 58.1997 69.7353 58.9214C69.124 59.643 68.3627 60.2229 67.5046 60.6205C66.6464 61.0182 65.7119 61.2241 64.7661 61.224H52.9182" stroke="#0FABE0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
