import { ProCard } from "@ant-design/pro-components";
import { Col, Row, Space } from "antd";
import StatementGainer from "views/Dashboard/Dashboard/SummaryPage/SummaryGainerLoser/StatementGainerComponent";
import StatementLoser from "views/Dashboard/Dashboard/SummaryPage/SummaryGainerLoser/StatementLoserComponent";
import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
import Meta from "antd/lib/card/Meta";

const Tab = styled.a`
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  ${(props: any) =>
    props.active &&
    `
    color:#1890FF;
  `}
`;

enum TYPES {
  WEEKLY = "1 Week",
  MONTHLY = "1 Month",
  QUARTERLY = "1 Quarter",
  YEARLY = "1 Year",
  DAILY = "1 Day",
}

const types = ["1 Day", "1 Week", "1 Month", "1 Quarter", "1 Year"];

const SummaryGainerLoser = () => {
  const [active, setActive] = useState("1 Month");

  const netWorthLastDate = useSelector(
    (state: any) => state.networthDateReducer?.networthDate?.last_date
  );

  function calculateDate() {
    let lastDate: any;
    switch (active) {
      case TYPES.WEEKLY:
        lastDate = moment(netWorthLastDate)
          .subtract(7, "days")
          .endOf("day")
          .toISOString();
        return { lastDate };
        break;
      case TYPES.MONTHLY:
        lastDate = moment(netWorthLastDate)
          .subtract(1, "months")
          .endOf("day")
          .toISOString();
        return { lastDate };
        break;
      case TYPES.QUARTERLY:
        lastDate = moment(netWorthLastDate)
          .subtract(3, "months")
          .endOf("day")
          .toISOString();
        return { lastDate };
        break;
      case TYPES.YEARLY:
        lastDate = moment(netWorthLastDate)
          .subtract(1, "years")
          .endOf("day")
          .toISOString();
        return { lastDate };
        break;
      case TYPES.DAILY:
        lastDate = moment(netWorthLastDate)
          .subtract(1, "days")
          .endOf("day")
          .toISOString();
        return { lastDate };
        break;
      default:
        lastDate = moment(netWorthLastDate)
          .subtract(7, "days")
          .endOf("day")
          .toISOString();
        return { lastDate };
    }
  }

  const onClick = (type: string) => {
    setActive(type);
    calculateDate();
  };

  const { lastDate } = calculateDate();

  return (
    <ProCard
      title={
        <Row>
          <h3>Gainer/Loser :</h3>
          <h3 style={{ float: "right" }}>
            &nbsp; {lastDate?.substring(0, 10)} -{">"} {netWorthLastDate}
          </h3>
        </Row>
      }
      headerBordered
      extra={
        <Space size="large">
          {types.map((type) => (
            <Tab
              key={type}
              active={active === type}
              onClick={() => onClick(type)}
            >
              {type}
            </Tab>
          ))}
        </Space>
      }
    >
      <Row>
        <Col span={12}>
          <StatementGainer active={active} />
        </Col>
        <Col span={12}>
          <StatementLoser active={active} />
        </Col>
      </Row>
    </ProCard>
  );
};

export default SummaryGainerLoser;
