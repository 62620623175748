/* eslint-disable import/no-unresolved */
import { LightFilter, ProFormSelect } from "@ant-design/pro-components";
import { useQuery } from "@tanstack/react-query";
import { ANALYTICS_HOST, TRANSACTIONS_API_ROOT } from "api/api-config";
import { getCookie } from "redux/utils/helpers";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import { AnalyticsDropDown } from "../../Portfolio";

type Data = {
  id:number;
  name:string;
  endpoint:string;
}
type AnalyticsProps = {
  setFilterValue: any;
  data: Data[];
  isLoading: boolean;
  isError: boolean;
}

function AnalyticsSelector(props: AnalyticsProps) {
  const { setFilterValue,data,isLoading,isError } = props;
  const [searchForm] = useForm();

  useEffect(() => {
    setFilterValue({ value: data?.[0]?.endpoint, label: data?.[0]?.name });
  }, [data?.length]);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  type ItemType = {
    name: string;
    id: string;
  };

  return (
    <LightFilter
      form={searchForm}
      preserve
      initialValues={{
        analytics: { value: data[0]?.id, label: data[0]?.name },
      }}
      bordered
      style={{ display: "flex", justifyContent: "flex-end", flex: "1" }}
    >
      <ProFormSelect
        style={{ width: "100%" }}
        name="analytics"
        placeholder="Select Analytics"
        fieldProps={{
          onChange(value, option) {
            setFilterValue(option)
          },
        }}
        options={data?.map((item: Data) => ({
          value: item.endpoint,
          label: item.name,
        }))}
        rules={[{ required: true, message: "Please select analytics type!" }]}
      />
    </LightFilter>
  );
}

export default AnalyticsSelector;
