/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
// /* eslint-disable no-param-reassign */
// /* eslint-disable camelcase */
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
// import { getCookie } from "../../../utils/helpers";
// import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

// const getCurrentDate = () => {
//   const today = new Date();
//   const dd = String(today.getDate()).padStart(2, "0");
//   const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
//   const yyyy = today.getFullYear();
//   return `${yyyy}-${mm}-${dd}`;
// };
// const initiallastDateState = {
//   lastDateLoader: false,
//   lastDate: "",
//   currentLastDate: "",
//   error: "",
// };

// export const fetchLastDate = createAsyncThunk(
//   "lastDate/fetchlastDate",
//   (datee = `${getCurrentDate()}`) => {
//     const ethan_token = getCookie("ethan_token");
//     return axios
//       .get(
//         `${TRANSACTIONS_API_ROOT}/last_date/position_history/?report_date=${datee}`,
//         {
//           headers: {
//             "Authorization": ethan_token,
//           },
//         }
//       )
//       .then((response) => {
//         const lastDate = response.data.data;
//         return lastDate;
//       });
//   }
// );
// const lastDateSlice = createSlice({
//   name: "lastDate",
//   initialState: initiallastDateState,
//   extraReducers: (builder) => {
//     builder.addCase(fetchLastDate.pending, (state) => {
//       state.lastDateLoader = true;
//     });
//     builder.addCase(fetchLastDate.fulfilled, (state, action) => {
//       state.lastDateLoader = false;
//       state.lastDate = action.payload;
//       state.currentLastDate = action.payload;
//       state.error = "";
//     });
//     builder.addCase(fetchLastDate.rejected, (state, action) => {
//       state.lastDateLoader = false;
//       state.lastDate = "";
//       state.error = action.error.message;
//     });
//   },
// });

// export default lastDateSlice.reducer;
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { getCookie, setCookie } from "../../../utils/helpers";
import { AUTH_HOST, TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

const initiallastDateState = {
  lastDateLoader: false,
  lastDate: "",
  currentLastDate: "",
  error: "",
};

export const fetchLastDate = createAsyncThunk(
  "lastDate/fetchlastDate",
  (datee = `${getCurrentDate()}`) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/last_date/position_history/?report_date=${datee}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => {
        const lastDate = response.data.data;
        return lastDate;
      }).catch((error) => {
        // window.location.href = "/upload";
        // window.location.replace("/upload");
      });
  }
);
const removeAllCookies = () => {
  const cookies = Cookies.get();
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const cookieName in cookies) {
    Cookies.remove(cookieName);
  }
  // const cookies = document.cookie.split(";");

  // for (let i = 0; i < cookies.length; i++) {
  //   const cookie = cookies[i];
  //   const eqPos = cookie.indexOf("=");
  //   const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //   document.cookie = `${name  }=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  // }
};

// // Define an Axios interceptor for handling expired access tokens
axios.interceptors.response.use(
  (response) => response,
  async(error) => {
    const ethan_token = getCookie("ethan_token");
    const refresh_token = getCookie("refresh_token");

    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      if (originalRequest.url === `${AUTH_HOST}/api/token/refresh/`) {
      // Refresh token is invalid, log out user
        // console.error("Refresh token is invalid, logging out user.");
        await removeAllCookies()
        window.location.href = "/sign-in"
        // const navigate = useNavigate(); // initialize useNavigate
        // navigate("/sign-in"); // navigate to the sign-in page
        return Promise.reject(error);
      }
    }

    if (error.response.status ===401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        return axios
          .post(`${AUTH_HOST}/api/token/refresh/`, { refresh: refresh_token })
          .then((response) => {
            const newToken = response?.data?.access;
            setCookie("ethan_token", `Bearer ${newToken}`, 1 / 24);
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalRequest);
          })
          .catch((error) => {
            // console.log("handle error")
            // removeAllCookies()
            // window.location.href = "/sign-in"
            // removeAllCookies()
            // Dispatch the logout action to log the user out
            // dispatch(logout());
            Promise.reject(error)
          }
          );
      } catch (error) {
        // console.log("handle error")
        // dispatch(logout());
        // deleteCookies()
        // removeAllCookies()
        // window.location.href = "/sign-in"
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
const lastDateSlice = createSlice({
  name: "lastDate",
  initialState: initiallastDateState,
  extraReducers: (builder) => {
    builder.addCase(fetchLastDate.pending, (state) => {
      state.lastDateLoader = true;
    });
    builder.addCase(fetchLastDate.fulfilled, (state, action) => {
      state.lastDateLoader = false;
      state.lastDate = action.payload;
      state.currentLastDate = action.payload;
      state.error = "";
    });
    builder.addCase(fetchLastDate.rejected, (state, action) => {
      state.lastDateLoader = false;
      state.lastDate = "";
      state.error = action.error.message;
    });
  },
});

export default lastDateSlice.reducer;
