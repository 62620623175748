import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { positionStatementReducer } from "../features/positionStatements/positionStatement.reducer";
import colorCodesReducer from "../features/colorCodes";
import miniStatsReducer from "../features/miniStats";
import firstLevelQtdReducer from "../features/positions/firstLevelQtdSlice";
import firstLevelYtdReducer from "../features/positions/firstLevelYtdSlice";
import tableauIDReducer from "../features/reports/tableauIDSlice";
import tableauCredReducer from "../features/reports/tableauCredSlice";
import tableauSignInReducer from "../features/reports/tableauSignInSlice";
import tableauViewListReducer from "../features/reports/tableauViewListSlice";
import tableauFirstImgReducer from "../features/reports/tableauFirstImgSlice";
import tableauFirstTicketReducer from "../features/reports/tableauFirstTicketSlice";
import tableauFetchTicketReducer from "../features/reports/tableauFetchTicketSlice";
import blotterReducer from "../features/blotter/blotterSlice";
import securitySlice from "../features/blotter/securitySlice";
import performanceSlice from "../features/performance/performanceSlice";
import performanceRangeSlice from "../features/performance/performanceRangeSlice";
import benchmarkSlice from "../features/benchmark/benchmarkSlice";
import benchmarkRangeSlice from "../features/benchmark/benchmarkRangeSlice";
import firstLevelDtdSlice from "../features/positions/firstLevelDtdSlice";
import dtdPerformance from "../features/performance/dtdPerformance";
import mtdPerformance from "../features/performance/mtdPerformance";
import qtdPerformance from "../features/performance/qtdPerformance";
import ytdPerformance from "../features/performance/ytdPerformance";
import historicalSlice from "../features/positions/historicalSlice";
import chartParamsSlice from "../features/riskManagement/LineChart/chartParamsSlice";
import allStatementsSlice from "../features/uploads/allStatementsSlice";
import topContributorDtd from "../features/positions/topContributorDtd";
import topContributorMtd from "../features/positions/topContributorMtd";
import topContributorQtd from "../features/positions/topContributorQtd";
import topContributorYtd from "../features/positions/topContributorYtd";
import allUploads from "../features/uploads/allUploads";
import maxQtySlice from "../features/blotter/maxQtySlice";
import structuredProductsSlice from "../features/structuredProduct/structuredProduct";
import allClientSlice from "../features/uploads/allClientSlice";
import clientStatus from "../features/clientStatus";
import processingStatus from "../features/clientMonthly/processingStatus";
import batchRun from "../features/batchRun";
import scenarioUpload from "../features/scenarioUpload/uploadSlice";
import fourthLevelReducer from "../features/positions/fourthLevelSlice";
import thirdLevelReducer from "../features/positions/thirdLevelSlice";
import secondLevelReducer from "../features/positions/secondLevelSlice";
import firstLevelMtdReducer from "../features/positions/firstLevelMtdSlice";
import firstLevelReducer from "../features/positions/firstLevelSlice";
import tradeDataReducer from "../features/summary/trades/tradeSlice";
import chartDataReducer from "../features/summary/chart/chartSlice";
import netWorthReducer from "../features/summary/netWorth/netWorthSlice";
import lastDateReducer from "../features/summary/statistics/lastDateSlice";
import tradesReducer from "../features/summary/statistics/tradesSlice";
import realisedPLReducer from "../features/summary/statistics/realisedPLSlice";
import plAttributionReducer from "../features/summary/statistics/plAttributionsSlice";
import allocationsReducer from "../features/summary/statistics/allocationsSlice";
import networthDateReducer from "../features/summary/newNetworth/networthDateSlice";
import CurrencyRPTReducer from  "../features/companyCurrency/clientCurrency"
import ClientCcyReducer from "../features/clientCurrency/clientCurrency"
import SelectedClientReducer from "../features/selectedClient/selectedClientSlice"
import SelectedCustodian from "../features/selectedCustodian/selectedCustodianSlice"


const combinedReducer = combineReducers({
  SelectedCustodian,
  SelectedClientReducer,
  ClientCcyReducer,
  CurrencyRPTReducer,
  networthDateReducer,
  clientStatus,
  positionStatements: positionStatementReducer,
  allocations: allocationsReducer,
  plAttributions: plAttributionReducer,
  realisedPLs: realisedPLReducer,
  trades: tradesReducer,
  lastDate: lastDateReducer,
  netWorth: netWorthReducer,
  chartData: chartDataReducer,
  tradeData: tradeDataReducer,
  firstLevel: firstLevelReducer,
  firstLevelMtd: firstLevelMtdReducer,
  firstLevelQtd: firstLevelQtdReducer,
  firstLevelYtd: firstLevelYtdReducer,
  firstLevelDtd: firstLevelDtdSlice,
  secondLevel: secondLevelReducer,
  thirdLevel: thirdLevelReducer,
  fourthLevel: fourthLevelReducer,
  colorCodes: colorCodesReducer,
  miniStats: miniStatsReducer,
  tableauID: tableauIDReducer,
  tableauCred: tableauCredReducer,
  tableauSignIn: tableauSignInReducer,
  tableauViewList: tableauViewListReducer,
  tableauFirstImg: tableauFirstImgReducer,
  tableauFirstTicket: tableauFirstTicketReducer,
  tableauFetchTicket: tableauFetchTicketReducer,
  blotter: blotterReducer,
  security: securitySlice,
  performance: performanceSlice,
  performanceRange: performanceRangeSlice,
  benchmark: benchmarkSlice,
  benchmarkRange: benchmarkRangeSlice,
  dtdPerformance,
  mtdPerformance,
  qtdPerformance,
  ytdPerformance,
  historical: historicalSlice,
  chartParams: chartParamsSlice,
  allStatements: allStatementsSlice,
  topContributorDtd,
  topContributorMtd,
  topContributorQtd,
  topContributorYtd,
  allUploads,
  maxQty: maxQtySlice,
  structuredProducts: structuredProductsSlice,
  allClients: allClientSlice,
  processedFiles: processingStatus,
  batchRun,
  scenarioUpload,

});
const rootReducer = (state :any, action: any) => {
  if (action.type === "reset") {
    return combinedReducer({
      ...state,
      miniStats: { ...state.miniStats },
      lastDate: {
        lastDateLoader: false,
        lastDate: action.payload,
        currentLastDate: state.lastDate.currentLastDate,
        error: "",
      },
      networthDateReducer: {
        networthDateLoader: false,
        networthDate: action.payload,
      },
    }, action);
  }
  if (action.type === "LOGOUT") {
    return combinedReducer({...state}, action);
  }
  return combinedReducer(state, action);
};
const store = configureStore({
  reducer: rootReducer,
});

export default store;
export type AppDispatch = typeof store.dispatch

type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
