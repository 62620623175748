/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialMaxQtyState = {
  maxqtyLoader: false,
  maxqty: "",
  error: "",
};

export const fetchmaxqty = createAsyncThunk(
  "maxqty/fetchmaxqty",
  ({ client_name, security_id, asset_class, custodian }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/position_qty/`,
        {
          client_name,
          security_id,
          asset_class,
          custodian,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.position_qty);
  }
);
const maxqtySlice = createSlice({
  name: "maxqty",
  initialState: initialMaxQtyState,
  extraReducers: (builder) => {
    builder.addCase(fetchmaxqty.pending, (state) => {
      state.maxqtyLoader = true;
    });
    builder.addCase(fetchmaxqty.fulfilled, (state, action) => {
      state.maxqtyLoader = false;
      state.maxqty = action.payload;
      state.error = "";
    });
    builder.addCase(fetchmaxqty.rejected, (state, action) => {
      state.maxqtyLoader = false;
      state.maxqty = "";
      state.error = action.error.message;
    });
  },
});

export default maxqtySlice.reducer;
