/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialHistoricalState = {
  historicalLoader: false,
  historical: [],
  error: "",
};

export const fetchhistorical = createAsyncThunk(
  "historical/fetchhistorical",
  ({ ticker, avg_price, market_price }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/historical/`,
        {
          ticker,
          avg_price,
          mkt_price: market_price,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data.data);
  }
);
const historicalSlice = createSlice({
  name: "historical",
  initialState: initialHistoricalState,
  extraReducers: (builder) => {
    builder.addCase(fetchhistorical.pending, (state) => {
      state.historicalLoader = true;
    });
    builder.addCase(fetchhistorical.fulfilled, (state, action) => {
      state.historicalLoader = false;
      state.historical = action.payload;
      state.error = "";
    });
    builder.addCase(fetchhistorical.rejected, (state, action) => {
      state.historicalLoader = false;
      state.historical = [];
      state.error = action.error.message;
    });
  },
});

export default historicalSlice.reducer;
