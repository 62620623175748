/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialAllocationsState = {
  allocationLoader: false,
  allocations: {},
  error: "",
};

export const fetchAllocations = createAsyncThunk(
  "allocations/fetchAllocations",
  (date) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/base_ccy_total/`,
        {
          report_date: date,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => {
        const allocations = Math.round(response.data.data);
        const aum = Math.round(response.data.sum_mtm_base_ccy);
        return { allocations, aum };
      });
  }
);

const allocationsSlice = createSlice({
  name: "allocations",
  initialState: initialAllocationsState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllocations.pending, (state) => {
      state.allocationLoader = true;
    });
    builder.addCase(fetchAllocations.fulfilled, (state, action) => {
      state.allocationLoader = false;
      state.allocations = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAllocations.rejected, (state, action) => {
      state.allocationLoader = false;
      state.allocations = "";
      state.error = action.error.message;
    });
  },
});

export default allocationsSlice.reducer;
