/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../Reports.css";

const Card = ({
  onClick,
  title,
  itemId,
  token,
  contentUrl,
  site_id,
  type,
  showSelection,
}) => {
  const [img, setImg] = useState("");
  const textBg = useColorModeValue("#F0F7FF", "#424141");
  const [checked, setChecked] = useState(false);

  const getImage = () => {
    fetch(
      `https://reports.ethan-ai.com/api/3.12/sites/${site_id}/views/${itemId}/image`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "X-Tableau-Auth": token,
        },
      }
    ).then(async (response) => {
      const imageBlob = await response.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImg(imageObjectURL);
    });
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  const handleSelection = () => {
    onClick(itemId, title);
    handleChange();
  };
  useEffect(() => {
    getImage();
  }, []);

  switch (type) {
    case 1:
      return (
        <div
          className="scroll__view"
          tabIndex={0}
          style={{
            borderRadius: "50%",
            width: "150px",
          }}
        >
          {showSelection ? (
            <div>
              <label htmlFor="checked">
                <input type="checkbox" id="checked" value={checked} />
              </label>
              <div
                className="card"
                onClick={() =>
                  showSelection
                    ? handleSelection()
                    : onClick(img, title, contentUrl)
                }
                style={{
                  boxShadow: "0px 0px 2px 3px #edeef0",
                  borderRadius: "50%",
                  width: "150px",
                  cursor: "pointer",
                  opacity: showSelection ? 0.5 : 1,
                }}
              >
                <img
                  src={img}
                  className={
                    showSelection ? "card__img_non_click" : "card__img"
                  }
                  style={{ borderRadius: "50%", width: "150px" }}
                  alt="Loading..."
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "#615F5F",
                  marginTop: "10px",
                }}
              >
                {title}
              </div>
            </div>
          ) : (
            <div>
              <div
                className="card"
                onClick={() =>
                  showSelection
                    ? handleSelection()
                    : onClick(img, title, contentUrl)
                }
                style={{
                  boxShadow: "0px 0px 2px 3px #edeef0",
                  borderRadius: "50%",
                  width: "150px",
                  cursor: "pointer",
                  opacity: showSelection ? 0.5 : 1,
                }}
              >
                <img
                  src={img}
                  className={
                    showSelection ? "card__img_non_click" : "card__img"
                  }
                  style={{ borderRadius: "50%", width: "150px" }}
                  alt="Loading..."
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "#615F5F",
                  marginTop: "10px",
                }}
              >
                {title}
              </div>
            </div>
          )}
        </div>
      );
    case 2:
      return (
        <div
          className="scroll__view__hovered"
          tabIndex={0}
          style={{
            width: "150px",
            height: "90px",
            flexDirection: "column",
            // opacity: showSelection ? 0.5 : 1,
          }}
        >
          <div
            className="card__hovered"
            onClick={() =>
              showSelection ? onClick(itemId) : onClick(img, title, contentUrl)
            }
            style={{
              boxShadow: "0px 0px 2px 3px #edeef0",
              height: "10px",
              width: "150px",
              cursor: "pointer",
              backgroundColor: textBg,
              border: `0.4px solid ${textBg}`,
              opacity: showSelection ? 0.5 : 1,
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#0fabe0",
                fontSize: "14px",
              }}
            >
              {title}
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
};
export default Card;
