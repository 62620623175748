import { Button, Pagination, Typography, Tag } from "antd";
import { useState } from "react";
import { IClientInformation } from "redux/features/uploads/allClientSlice";
import AddBlotterTrade from "../components/AddBlotterTrade";
import ActivePositions from "../components/ActivePosition";
import {
  IActivePositionFetchPayload,
  IActivePositionFilterParams,
  IActivePositionTable,
  ICustodianInformation,
  IRelationInformation,
  ISecurityObject,
} from "../interfaces";
import PositionsFilter from "../components/PositionsFilter";

interface IActivePositionsPageProps {
  selectedClient: IClientInformation | null;
  custodianList: ICustodianInformation[];
  clientRelations: IRelationInformation[];
  securities: ISecurityObject[];
  assetClassList: string[];
  activePositions: IActivePositionTable[];
  setTotalPositionsCount: React.Dispatch<React.SetStateAction<number>>;
  totalPositionsCount: number;
  updateActivePositions: (
    payload: IActivePositionFetchPayload
  ) => Promise<number>;
  updateBlotterTrades: (page: number) => Promise<number>;
  positionFilterActive: IActivePositionFilterParams | undefined;
  setPositionFilterActive: React.Dispatch<
    React.SetStateAction<IActivePositionFilterParams | undefined>
  >;
}

const ActivePositionsPage = ({
  selectedClient,
  custodianList,
  clientRelations,
  securities,
  assetClassList,
  activePositions,
  totalPositionsCount,
  setTotalPositionsCount,
  updateActivePositions,
  updateBlotterTrades,
  positionFilterActive,
  setPositionFilterActive,
}: IActivePositionsPageProps) => {
  const [addTradePopupVisible, setAddTradePopupVisible] = useState<boolean>(
    false
  );
  const [searchPopupVisible, setSearchPopupVisible] = useState<boolean>(false);

  if (!selectedClient) {
    return <div />;
  }

  return (
    <>
      <Typography.Title level={3}>Active Positions</Typography.Title>

      <Button
        onClick={() => setAddTradePopupVisible(true)}
        style={{ marginBottom: "10px" }}
      >
        Add Blotter Trade
      </Button>
      <AddBlotterTrade
        client={selectedClient}
        custodianList={custodianList}
        relationsList={clientRelations}
        formOpen={addTradePopupVisible}
        onClose={() => {
          setAddTradePopupVisible(false);
        }}
        updateActivePositions={updateActivePositions}
        updateBlotterTrades={updateBlotterTrades}
        securities={securities}
      />

      <Button
        onClick={() => setSearchPopupVisible(!searchPopupVisible)}
        style={{ marginLeft: "10px" }}
        type={
          searchPopupVisible || positionFilterActive ? "primary" : "default"
        }
      >
        Filter Active Positions
      </Button>

      {positionFilterActive && (
        <Tag style={{ marginLeft: "10px" }} color="volcano">
          Filter Active
        </Tag>
      )}

      {searchPopupVisible && (
        <PositionsFilter
          setTotalPositionsCount={setTotalPositionsCount}
          custodianList={custodianList}
          assetClassList={assetClassList}
          positionFilterActive={positionFilterActive}
          setPositionFilterActive={setPositionFilterActive}
          updateActivePositions={updateActivePositions}
        />
      )}

      <ActivePositions
        client={selectedClient}
        custodianList={custodianList}
        clientRelations={clientRelations}
        activePositions={activePositions}
        updateBlotterTrades={updateBlotterTrades}
        updateActivePositions={updateActivePositions}
        securities={securities}
      />
      {totalPositionsCount > 0 && (
        <Pagination
          onChange={(page) => updateActivePositions({ page })}
          total={totalPositionsCount}
          showSizeChanger={false}
          pageSize={10}
        />
      )}
    </>
  );
};

export default ActivePositionsPage;
