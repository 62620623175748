/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialQtdPerformance = {
  qtdPerformanceLoader: false,
  qtdPerformance: [],
  error: "",
};
export const fetchqtdPerformance = createAsyncThunk(
  "qtdPerformance/fetchqtdPerformance",
  ({ startDate, lastDate }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/performance/?start_date=${startDate}&end_date=${lastDate}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const qtdPerformanceSlice = createSlice({
  name: "qtdPerformance",
  initialState: initialQtdPerformance,
  extraReducers: (builder) => {
    builder.addCase(fetchqtdPerformance.pending, (state) => {
      state.qtdPerformanceLoader = true;
    });
    builder.addCase(fetchqtdPerformance.fulfilled, (state, action) => {
      state.qtdPerformanceLoader = false;
      state.qtdPerformance = action.payload;
      state.error = "";
    });
    builder.addCase(fetchqtdPerformance.rejected, (state, action) => {
      state.qtdPerformanceLoader = false;
      state.qtdPerformance = [];
      state.error = action.error.message;
    });
  },
});

export default qtdPerformanceSlice.reducer;
