/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialrealisedPLState = {
  realisedPLLoader: false,
  realisedPLs: "",
  error: "",
};

export const fetchrealisedPL = createAsyncThunk(
  "realisedPLs/fetchrealisedPL",
  (date) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(`${TRANSACTIONS_API_ROOT}/relised_pl/?report_date=${date}`, {
        headers: {
          "Authorization": ethan_token,
        },
      })
      .then((response) => {
        const realisedPLs = Math.round(response.data.data);
        const totalRealisedPL = response.data.total_realised_pl;
        return { realisedPLs, totalRealisedPL };
      });
  }
);
const realisedPLSlice = createSlice({
  name: "realisedPLs",
  initialState: initialrealisedPLState,
  extraReducers: (builder) => {
    builder.addCase(fetchrealisedPL.pending, (state) => {
      state.realisedPLLoader = true;
    });
    builder.addCase(fetchrealisedPL.fulfilled, (state, action) => {
      state.realisedPLLoader = false;
      state.realisedPLs = action.payload;
      state.error = "";
    });
    builder.addCase(fetchrealisedPL.rejected, (state, action) => {
      state.realisedPLLoader = false;
      state.realisedPLs = "";
      state.error = action.error.message;
    });
  },
});

export default realisedPLSlice.reducer;
