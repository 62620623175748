/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/tabindex-no-positive */
// Chakra imports
// import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { Area, AreaConfig } from "@ant-design/plots";
import { Button, Typography } from "antd";
import { EthanTrackerEvent } from "redux/utils/helpers";
import { DownloadOutlined } from "@ant-design/icons";
import { integerValueShorter } from "views/Dashboard/Tables/statementPosition/components/CurrencyComponent";

const { Title } = Typography;

type GraphBoxProps = {
  data: any;
  graphLoader: boolean;
  type?: any;
  yfield?: any;
  totalVals?: any;
  name?: string;
};



const GraphBox = (props: GraphBoxProps) => {
  const { data, graphLoader, name } = props;

  const sumKey = name === "Networth" ? "asset_networth" : "total_pl";

  const sum = data?.reduce((acc:any, item:any) => acc + (item?.[sumKey] || 0), 0) || 0;

  const sumFormatted = integerValueShorter(sum);

  const downloadExcel = (chartData: any) => {
    EthanTrackerEvent({
      category: "Summary - Chart",
      action: `Download Chart Data`,
      label: `Download Chart Data`,
    });
    const worksheet = XLSX.utils.json_to_sheet(chartData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ChartData.xlsx");
  };

  const config: AreaConfig = {
    data,
    xField: "report_date",
    yField: name === "Networth" ? "asset_networth" : "total_pl",
    seriesField: "asset_class",
    legend: {
      flipPage: false,
    },
    tooltip: {
      title: (title: string, datum: any) =>
        `Sum : ${sumFormatted}`,
      formatter: (datum: any) => ({
        name: `${datum?.asset_class} -${datum?.report_date}`,
        value:
          name === "Networth"
            ? integerValueShorter(datum?.asset_networth)
            : integerValueShorter(datum?.total_pl),
      }),
    },
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Title level={4} style={{ textAlign: "left" }}>
          {name}
        </Title>
        <Area loading={graphLoader} {...config} style={{ flex: 1 }} />
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          style={{ float: "right", marginTop: "1.5em", alignSelf: "flex-end" }}
          onClick={() => downloadExcel(data)}
        >
          Download CSV
        </Button>
      </div>
    </>
  );
};

export default GraphBox;
