/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { EthanTrackerEvent } from "../../../redux/utils/helpers";
import CustomButton from "../../../components/custom-button/custom-button";
import styles from "./Auth.module.css";
import BrandingComponent from "./BrandingComponent";
import Otp from "../Otp";

const OtpComponent = (props) => {
  const [otp, setOtp] = useState("");
  const { verifyOtp } = props;
  const handleOtp = (e) => {
    e.preventDefault();
    EthanTrackerEvent({
      category: "User",
      action: "OTP",
      label: "OTP",
    });
    verifyOtp(otp);
  };

  return (
    <div className={styles.parent}>
      <BrandingComponent />
      <div className={styles.auth__section__otp}>
        <div className={styles.welcome__section}>
          <span className={styles.title__regular}>
            Welcome to <span className={styles.title__bold}>ethan</span>{" "}
          </span>
          <span className={styles.subtitle}>Please enter the OTP</span>
        </div>
        <div className={styles.otp__section}>
          <Otp
            otp={otp}
            setOtp={(e) => {
              setOtp(e);
            }}
          />

          <form className={styles.auth__form}>
            <div className={styles.group__box}>
              <CustomButton
                buttonType="authButton"
                onClick={(e) => handleOtp(e)}
              >
                Verify
              </CustomButton>
            </div>
          </form>
        </div>
        <div />
      </div>
    </div>
  );
};

export default OtpComponent;
