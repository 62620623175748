/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";

const initialtableauIDState = {
  tableauIDLoader: false,
  tableauID: "",
  error: "",
};

export const fetchtableauID = createAsyncThunk(
  "tableauID/fetchtableauID",
  () => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `https://login-server.ethan-ai.com/api/tableau_data/?token=${ethan_token}`
      )
      .then((response) => response.data)
      .then((data) => data.response[data.response.length - 1].tableau_id);
  }
);
const tableauIDSlice = createSlice({
  name: "tableauID",
  initialState: initialtableauIDState,
  extraReducers: (builder) => {
    builder.addCase(fetchtableauID.pending, (state) => {
      state.tableauIDLoader = true;
    });
    builder.addCase(fetchtableauID.fulfilled, (state, action) => {
      state.tableauIDLoader = false;
      state.tableauID = action.payload;
      state.error = "";
    });
    builder.addCase(fetchtableauID.rejected, (state, action) => {
      state.tableauIDLoader = false;
      state.tableauID = "";
      state.error = action.error.message;
    });
  },
});

export default tableauIDSlice.reducer;
