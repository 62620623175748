/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialtableauSignInState = {
  tableauSignInLoader: false,
  tableauToken: "",
  tableauSiteID: "",
  error: "",
};

export const fetchtableauSignIn = createAsyncThunk(
  "tableauSignIn/fetchtableauSignIn",
  (tableauCred) => axios
    .post(`https://reports.ethan-ai.com/api/3.12/auth/signin`, tableauCred, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .then((data) => ({
      token: data.credentials.token,
      siteID: data.credentials.site.id,
    }))
);
const tableauSignInSlice = createSlice({
  name: "tableauSignIn",
  initialState: initialtableauSignInState,
  extraReducers: (builder) => {
    builder.addCase(fetchtableauSignIn.pending, (state) => {
      state.tableauSignInLoader = true;
    });
    builder.addCase(fetchtableauSignIn.fulfilled, (state, action) => {
      state.tableauSignInLoader = false;
      state.tableauToken = action.payload.token;
      state.tableauSiteID = action.payload.siteID;
      state.error = "";
    });
    builder.addCase(fetchtableauSignIn.rejected, (state, action) => {
      state.tableauSignInLoader = false;
      state.tableauToken = "";
      state.tableauSiteID = "";
      state.error = action.error.message;
    });
  },
});

export default tableauSignInSlice.reducer;
