
export const PortfolioIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}}  viewBox="0 0 88 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M81.25 18.75H62.5V6.25C62.5 4.5924 61.8415 3.00268 60.6694 1.83058C59.4973 0.65848 57.9076 0 56.25 0H31.25C29.5924 0 28.0027 0.65848 26.8306 1.83058C25.6585 3.00268 25 4.5924 25 6.25V18.75H6.25C4.5924 18.75 3.00268 19.4085 1.83058 20.5806C0.65848 21.7527 0 23.3424 0 25V68.75C0 70.4076 0.65848 71.9973 1.83058 73.1694C3.00268 74.3415 4.5924 75 6.25 75H81.25C82.9076 75 84.4973 74.3415 85.6694 73.1694C86.8415 71.9973 87.5 70.4076 87.5 68.75V25C87.5 23.3424 86.8415 21.7527 85.6694 20.5806C84.4973 19.4085 82.9076 18.75 81.25 18.75ZM31.25 6.25H56.25V18.75H31.25V6.25ZM6.25 68.75V25H81.25V68.75H6.25Z" fill="#AEAEAE"/>
  </svg>
)

export const PortfolioSelectedIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}}  viewBox="0 0 88 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M81.25 18.75H62.5V6.25C62.5 4.5924 61.8415 3.00268 60.6694 1.83058C59.4973 0.65848 57.9076 0 56.25 0H31.25C29.5924 0 28.0027 0.65848 26.8306 1.83058C25.6585 3.00268 25 4.5924 25 6.25V18.75H6.25C4.5924 18.75 3.00268 19.4085 1.83058 20.5806C0.65848 21.7527 0 23.3424 0 25V68.75C0 70.4076 0.65848 71.9973 1.83058 73.1694C3.00268 74.3415 4.5924 75 6.25 75H81.25C82.9076 75 84.4973 74.3415 85.6694 73.1694C86.8415 71.9973 87.5 70.4076 87.5 68.75V25C87.5 23.3424 86.8415 21.7527 85.6694 20.5806C84.4973 19.4085 82.9076 18.75 81.25 18.75ZM31.25 6.25H56.25V18.75H31.25V6.25ZM6.25 68.75V25H81.25V68.75H6.25Z" fill="#0FABE0"/>
  </svg>
)
