/* eslint-disable no-unneeded-ternary */
import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
// import { getCookie } from "redux/utils/helpers";
import request from "axios";
// import { TRANSACTIONS_API_ROOT } from "api/api-config";
import React from "react";
import { Form } from 'antd';
import { BaseOptionType } from "antd/lib/select";

export interface IClientFormFieldProps {
  name?: string;
  shouldHaveClientName?: boolean;
  disabledLabel?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
}

export interface IClient {
  client_id: string;
  name: string;
}


const SecurityId = (props: IClientFormFieldProps) => {
  const {shouldHaveClientName, required, disabledLabel, onChange, name} = props;
  const form = Form.useFormInstance();
  const changeClientName = (value: string,) => {
   
    
    onChange?.(value);
    
  }




  return (<ProFormText
    name={name? name : "search"}
    label={null}
    fieldProps={{
      onChange: (e:any)=> changeClientName(e.target.value),
    }}
    placeholder="Search "
    rules={[{ required, message: 'Security Id' }]}
  />)
}

export default SecurityId;