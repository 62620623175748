/* eslint-disable arrow-body-style */
import { DatePicker} from "antd";
import moment, { Moment } from "moment";

interface IDatePickerComponent{
  handleChangeDate: (_value: Moment | null,dateString: string) => void;
  value?: string;
}

const TimeMachineDatePickerComponent = (props: IDatePickerComponent) => {
  const {handleChangeDate, value} = props;
  // The takes every day and check with the condition and disables that days from present date.

  const disableFutureDates = (checkEveryDate: Moment) => {return checkEveryDate > moment().add(-1,'days');}
  const onChange = (_value: Moment | null, dateString: string) => {
    handleChangeDate(_value,dateString);
  }
  const dateValue:any = value ? moment(value) : moment().subtract(1, 'days');

  return(
    <DatePicker
      size="large"
      onChange={onChange}
      disabledDate={disableFutureDates}
      value={dateValue}
      style={{
        borderRadius: "9px"
      }}
      allowClear={false}
    />
  )
}

export default TimeMachineDatePickerComponent;