/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType, LightFilter, ProColumns, ProForm, ProFormDatePicker, ProFormDateRangePicker, ProFormText } from '@ant-design/pro-components';
import {  Button, DatePicker, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useStatePersist } from 'use-state-persist';
import Table from "components/Table/Table";
import { TRANSACTIONS_API_ROOT } from 'api/api-config';
import { getCookie } from 'redux/utils/helpers';
import ClientFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientFormField';
import CustodianFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientCustodianFormField';
import moment from 'moment';
import { SortOrder } from 'antd/lib/table/interface';
import PreLoader from 'components/PreLoader/PreLoader';

import request from 'axios';
import { useForm } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppDispatch } from 'redux/app/store';
import { fetchLastDate } from 'redux/features/summary/statistics/lastDateSlice';
import SecurityId from './SecurityId';
import DateRangeSelector from './DateRangeSelector';
// import { Input, Space } from 'antd';

const { Search } = Input;


type TradeTableData = {
    id: number;
    security_id: string;
    client_name: string;
    ccy: string;
    trade_type: string;
    trade_qty: number;
    trade_price: number;
    trade_date: Date;
    action: string;
    bank_narration: string;
    asset_class: string;
    sett_amt: number;
    custodian: string;
    trade_id: string;
    client_id: number;
}





const columns: ProColumns<TradeTableData>[] = [
  {
    dataIndex: "client_name",
    title: "Client Name",
    fixed: "left",
    key: "client_name",
    renderFormItem: (item, { onChange }) => (
      <ClientFormSelect onChange={onChange} disabledLabel />
    ),
  },
  {
    dataIndex: "custodian",
    title: "Custodian Name",
    key: "custodian",
    renderFormItem: (item, { onChange }) => (
      <CustodianFormSelect onChange={onChange} disabledLabel />
    ),
    hideInTable: true,
  },
  {
    dataIndex: "trade_date",
    title: "Trade Date",
    sorter: true,
    // Format the date to be displayed in the table
    renderText: (text) => moment(text).format("DD MMM YYYY"),
    key: "trade_date",
    renderFormItem: (_item, _empty, form) => (
      <>
        <DatePicker.MonthPicker
          placeholder="Select Month"
          format="MMM-YY"
          onChange={(value) => {
            form.setFieldValue(
              "trade_date_from",
              value?.startOf("month").format("YYYY-MM-DD")
            );
            form.setFieldValue(
              "trade_date_to",
              value?.endOf("month").format("YYYY-MM-DD")
            );
          }}
        />
        <ProFormText name="trade_date_from" hidden />
        <ProFormText name="trade_date_to" hidden />
      </>
    ),
  },
  {
    key: "trade_qty",
    dataIndex: "trade_qty",
    title: "Trade Qty",
    search: false,
    renderText: (qty: string) => Number(qty),
  },
  {
    dataIndex: "security_id",
    title: "Security ID",
    key: "security_id",
    search: false,
  },
  {
    title: "Security Name",
    dataIndex: "security_name",
    key: "security_name",
    search: false,
  },
  {
    dataIndex: "currency",
    title: "Currency",
    key: "currency",
    search: false,
  },
  {
    dataIndex: "trade_price",
    title: "Trade Price",
    key: "trade_price",
    search: false,
  },
  {
    key: "trade_action",
    search: false,
    title: "Action",
    dataIndex: "trade_action",
    fixed: "right",
    width: 150,
  },
  {
    key: "asset_class",
    search: false,
    title: "Asset Class",
    dataIndex: "asset_class",
  },
  {
    key: "trade_id",
    search: false,
    title: "Trade ID",
    dataIndex: "trade_id",
  },
];

interface TradesPageProps {
  showDrawer: (a:any) => void;
}

const TradesPage = (props: TradesPageProps) => {
  const [isLoading, setIsLoading]=useState<boolean>(true);
  const {showDrawer} = props;
  const [filterValue, setFilterValue] = useStatePersist<any>('@StatementTableFilterValue', {});
  const netWorthLastDateValue= useSelector((state:any) => state?.networthDateReducer?.networthDate?.last_date);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();


  const [firstDate,setFirstDate]  = useState<any>('');
  const [lastDate,setLastDate]  = useState<any>('');
  const [searchForm] = useForm();
  const pageSize = 10;
  const actionRef = useRef<ActionType>();
  const ethanToken = getCookie("ethan_token");
  const[clientId,setClientId] = useState<any>();

  const setSearchFilter = async (_: any, allValues: any) => {
    
    if(allValues?.client !== undefined){
      setClientId(allValues?.client);
    }
    if(allValues?.client === undefined){
      setClientId(null);
    }

    if(allValues?.search){
      setTimeout(() => {
        setFilterValue({
          ...allValues,
          search: allValues?.search,
        });
        actionRef.current?.reload();
      }, 1000);

    }
    else{
      setFilterValue({
        ...allValues,
      });
      actionRef.current?.reload();
    }
    
    if(allValues?.search === '' || allValues?.search === undefined || allValues?.search === null){
      setFilterValue({
        ...allValues,
        search: null,
      });
    }
    

  }

  const fetchDates = async() => {
    const response = await fetch(`${TRANSACTIONS_API_ROOT}/trade/statements/clients/date_parser`,{
      method: "GET",
      headers: {
        Accept: "*/*",
        "Authorization": ethanToken as unknown as string,
      },
    });
    const data = await response.json();
    setFirstDate(data?.data?.first_date);
    setLastDate(data?.data?.last_date);
    searchForm.setFieldsValue({
      trade_date_from: moment(data?.data?.first_date).format("YYYY-MM-DD"),
      trade_date_to: moment(data?.data?.last_date).format("YYYY-MM-DD"),
    });
    

    setIsLoading(false);
  } 

  useEffect(() => {
    fetchDates()
    actionRef.current?.reload();
  }, []);

  useEffect(() => {
    if(actionRef?.current?.reset){
      actionRef.current?.reset();
    }

  }, [filterValue])

  return (
    <>
      {/* {isLoading &&   <PreLoader isLoaderOpen={isLoading}/>} */}
      <Table<TradeTableData>
        columns={columns}
        actionRef={actionRef}
        cardBordered
        scroll={{ x: columns.length * 200, y:700 }}
        // eslint-disable-next-line default-param-last
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
          const ordering = Object.keys(sortObject).map((key) => {
            const value = sortObject[key];
            return `${value === "ascend" ? "" : "-"}${key}`;
          })?.join();

          const { pageSize: pageSizeLimit, current, ...customParams} = params;
          const response = await request.get(`${TRANSACTIONS_API_ROOT}/trades/`, {
            params: {
              ...customParams,
              ...filterValue,
              limit: pageSizeLimit,
              ordering,
              offset: ((current as number) - 1) * (pageSizeLimit as number),
            },
            headers: {
              Accept: "*/*",
              "Authorization": ethanToken as unknown as string,
            },
          })
          const obj = {
            total: response.data.count,
            data: response.data.results,
          }
          setIsLoading(false);
          return obj;
        }}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'statement-table',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === 'get') {
              return {
                ...values,
                created_at: [values.startTime, values.endTime],
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize,
          showSizeChanger: false,
        }}
        dateFormatter="string"
        search={{
          filterType: 'light',
          collapsed: false,
          collapseRender: false,
        }}
        
        toolbar={{
          title: 'Blotter Trades',
          filter: (
            <LightFilter

              initialValues={{
                ...filterValue
              }}
              form={searchForm}
              preserve
              onValuesChange={setSearchFilter}
              
            > 
              <SecurityId name="search" />
              <ClientFormSelect name="client" />
              <CustodianFormSelect name="custodian" dependencies={['client']} clientId={clientId}/>
              
              <DateRangeSelector onChange={(changedDate:any[])=> {
                if (changedDate === null || changedDate === undefined){
                  searchForm.setFieldValue("trade_date_from",null);
                  searchForm.setFieldValue("trade_date_to", null);
                  setFilterValue({
                    ...filterValue,
                    trade_date_from: null,
                    trade_date_to: null,
                    month: null,
                  });
                }
                else {
                  searchForm.setFieldValue("trade_date_from",moment(changedDate?.[0]).format("YYYY-MM-DD"));
                  searchForm.setFieldValue("trade_date_to",moment(changedDate?.[1]).format("YYYY-MM-DD") );
                  setFilterValue({
                    ...filterValue,
                    trade_date_from: moment(changedDate?.[0]).format("YYYY-MM-DD"),
                    trade_date_to:moment(changedDate?.[1]).format("YYYY-MM-DD"),
                  });
                }
                
              } } />
              <ProFormText name="trade_date_from" hidden />
              <ProFormText name="trade_date_to" hidden />
            </LightFilter>
          )
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if(record?.trade_id){
              showDrawer(record)
            }
          },
        })}
      />
    </>
  )
}

export default TradesPage;
