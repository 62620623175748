import React from "react";

const Arrow = ({ children, disabled, onClick }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    type="button"
    style={{
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      right: "1%",
      opacity: disabled ? "0" : "1",
      userSelect: "none",
      paddingBottom: "50px",
      paddingRight: "10px",
      paddingLeft: "10px",
    }}
  >
    {children}
  </button>
);
export default Arrow;
