/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};
const initiallastDateState = {
  lastDateLoader: true,
  networthDate: "",
  error: "",
  lastDate:"",
};


export const netWorthLastDate = createAsyncThunk(
  "networth/netWorthLastDate",
  async(date?: any) => {
    const ethan_token = getCookie("ethan_token");
    if(date){
      return ( {last_date: date})
    }
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/position_history/asset_networth/date_parser/`,
        {
          headers: {
            "Authorization": ethan_token as unknown as string,
          },
        }
      )
      .then((response) => {
        const netWorthLastDateValue = response.data.data;
        if (Object.keys(netWorthLastDateValue).length === 0) {
          throw new Error("No data found");
        }
        return netWorthLastDateValue;
      }).catch((error) => {
        window.location.replace("/upload");
      });
    
  }
);
const netWorthDateSlice = createSlice({
  name: "netWorthLastDate",
  initialState: initiallastDateState,
  extraReducers: (builder) => {
    builder.addCase(netWorthLastDate.pending, (state: any) => {
      state.lastDateLoader = true;
    });
    builder.addCase(netWorthLastDate.fulfilled, (state:any, action:any) => {
      state.lastDateLoader = false;
      state.networthDate = action.payload;
      state.error = "";
    });
    builder.addCase(netWorthLastDate.rejected, (state:any, action:any) => {
      state.lastDateLoader = false;
      state.lastDate = "";
      state.error = action.error.message as string;
    });
  },
  reducers: {}
});

export default netWorthDateSlice.reducer;