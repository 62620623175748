/* eslint-disable react/button-has-type */
import './custom-button.css'

const BUTTON_STYLES = {
  green: "green__button",
  download: "download__button",
  authButton: "auth__button",
  resetPass: "reset__password",
  filters: "filters__button"
}

const CustomButton = ({children,buttonType, ...otherProps}) => (
  <button className={`${BUTTON_STYLES[buttonType]}`} {...otherProps}>
    {children}
  </button>
		
)

export default CustomButton;