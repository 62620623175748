import { Modal, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const PreLoader = ({isLoaderOpen,  }) => {
  const messages = [
    "Fetching the right data for the right person... 😇",
    "Fetching 10,000+ Records... 📂",
    "Just a little more... 😄",
    "Almost There... 🙌🏻",
  ];
  const [message, setMessage] = useState(messages[0]);
  useEffect(() => {
    setTimeout(() => setMessage(messages[1]), 4000);
    setTimeout(() => setMessage(messages[2]), 8000);
    setTimeout(() => setMessage(messages[3]), 10000);
  }, []);
  return (
    <Modal isOpen={isLoaderOpen} size="half">
      <ModalOverlay />
      <ModalContent
        style={{
          position: "absolute",
          top: "30%",
          width: "600px",
          padding: "1rem 2rem",
        }}
      >
        <Text fontSize="20px" textAlign="center" fontWeight="500">
          {message}
        </Text>
      </ModalContent>
    </Modal>
  );
};

export default PreLoader;