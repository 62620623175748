/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialfirstLevelMtdState = {
  firstLevelMtdLoader: false,
  firstLevelMtd: [],
  error: "",
};
export const fetchfirstLevelMtd = createAsyncThunk(
  "firstLevelMtd/fetchfirstLevelMtd",
  ({ start_date, end_date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/portfolio_movement/`,
        {
          start_date,
          end_date,
        },
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const firstLevelMtdSlice = createSlice({
  name: "firstLevelMtd",
  initialState: initialfirstLevelMtdState,
  extraReducers: (builder) => {
    builder.addCase(fetchfirstLevelMtd.pending, (state) => {
      state.firstLevelMtdLoader = true;
    });
    builder.addCase(fetchfirstLevelMtd.fulfilled, (state, action) => {
      state.firstLevelMtdLoader = false;
      state.firstLevelMtd = action.payload;
      state.error = "";
    });
    builder.addCase(fetchfirstLevelMtd.rejected, (state, action) => {
      state.firstLevelMtdLoader = false;
      state.firstLevelMtd = [];
      state.error = action.error.message;
    });
  },
});

export default firstLevelMtdSlice.reducer;
