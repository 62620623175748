/* eslint-disable import/no-unresolved */
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "views/Dashboard/Dashboard";
import Reports from "views/Dashboard/Reports";
import Portfolio from "views/Dashboard/Dashboard/PortfolioPage/Portfolio";
import ClientMonthly from "views/Dashboard/ClientMonthly";
import UploadStatement from "views/Dashboard/Upload/Upload";
import Login from "views/Auth/LogIn";
import Submit from "views/Auth/Submit";
import ResetPassword from "views/Auth/ResetPassword";
import PrivateRoutes from "components/Routes/PrivateRoutes";
import TradesPage from "views/Dashboard/Trades/Trades";
import Position from "views/Dashboard/Tables/position";
import TradeStatement from "views/Dashboard/TradesStatements/Trades";
import Cms from "views/Dashboard/CMS/Cms";
import CmsView from "views/Dashboard/CMS/components/CmsView";
import BlotterV2 from "views/Dashboard/Blotter-V2";

const AppRouter = () => (
  <Routes>
    <Route element={<PrivateRoutes />}>
      <Route index element={<Navigate to="/summary" replace />} />
      {/* <Route path="/selection" element={<Selection />} /> */}
      <Route index path="/summary" element={<Dashboard />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/positions/client" element={<Position />} />
      <Route path="/upload" element={<UploadStatement />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/clientMonthly" element={<ClientMonthly />} />
      <Route path="/trades" element={<TradesPage drawerOff />} />
      <Route path="/trade-statements" element={<TradeStatement />} />
      <Route path="/crm" element={<Cms />} />
      <Route path="/crm/:name" element={<CmsView />} />
      <Route path="/blotter" element={<BlotterV2 />} />
    </Route>
    <Route path="/sign-in" element={<Login />} />
    <Route path="/resetpassword" element={<ResetPassword />} />
    <Route path="/signup" element={<Submit />} />
  </Routes>
);
export default AppRouter;
