// library imports
import { Table } from "antd";
import { useState } from "react";
import { IClientInformation } from "redux/features/uploads/allClientSlice";

// source file imports
import AddBlotterTrade from "./AddBlotterTrade";
import {
  IActivePositionFetchPayload,
  IActivePositionTable,
  ICustodianInformation,
  IRelationInformation,
  ISecurityObject,
} from "../interfaces";
import { positionColumns } from "../constants";

interface IActivePositionsProps {
  client: IClientInformation;
  activePositions: IActivePositionTable[];
  securities: ISecurityObject[];

  // need these so as to fill up the add blotter trade form
  custodianList: ICustodianInformation[];
  clientRelations: IRelationInformation[];

  updateBlotterTrades: (page: number) => Promise<number>;
  updateActivePositions: (payload: IActivePositionFetchPayload) => Promise<number>;
}

const ActivePositions = ({
  client,
  activePositions,
  custodianList,
  clientRelations,
  securities,
  updateBlotterTrades,
  updateActivePositions,
}: IActivePositionsProps) => {
  const [
    selectedPosition,
    setSelectedPosition,
  ] = useState<IActivePositionTable | null>(
    activePositions.length > 0 ? null : activePositions[0]
  );

  const [addTradePopupVisible, setAddTradePopupVisible] = useState(false);

  return (
    <>
      {selectedPosition && (
        <AddBlotterTrade
          client={client}
          selectedPosition={selectedPosition}
          setSelectedPosition={setSelectedPosition}
          custodianList={custodianList}
          relationsList={clientRelations}
          formOpen={addTradePopupVisible}
          onClose={() => setAddTradePopupVisible(false)}
          updateBlotterTrades={updateBlotterTrades}
          updateActivePositions={updateActivePositions}
          securities={securities}
        />
      )}
      <Table
        dataSource={activePositions}
        columns={positionColumns}
        onRow={(position) => ({
          onClick: () => {
            setSelectedPosition(position);
            setAddTradePopupVisible(true);
          },
        })}
        scroll={{ x: 400 }}
        rowKey={(record) =>
          `${record.securityID} ${record.customerAccount} ${record.positionID}`
        } // need a unique key
        rowClassName="positionTableRow"
        pagination={false}
      />
    </>
  );
};

export default ActivePositions;
