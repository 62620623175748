/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  stats: {
  }
}

export const fetchMiniStats = createAsyncThunk(
  "miniStats/fetchMiniStats",
  (stats) => new Promise((resolve) => {
    resolve({
      stats
    })
  })
)

const miniStatsSlice = createSlice({
  name: "miniStats",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMiniStats.fulfilled, (state, action) => {
      state.stats = action.payload
    })
  }
})

export default miniStatsSlice.reducer;