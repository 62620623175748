export const SummaryIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.51343 18.0837L1.51343 1" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.51298 18.084L18.1733 18.084" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.25488 11.1768C7.42734 9.14209 8.98561 7.97871 10.7428 6.36328L11.8333 10.7437L16.5853 6.63625" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const SummarySelectedIcon = () => (
  <svg width="14" height="14" viewBox="0 0 20 20" style={{display: 'inline'}} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.30554 18.7739L1.30554 1" stroke="#0FABE0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.30467 18.7734L18.8738 18.7734" stroke="#0FABE0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.25073 11.5881C7.54153 9.47115 9.18469 8.26077 11.0376 6.58008L12.1875 11.1374L17.1984 6.86407" stroke="#0FABE0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)