/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloudOutlined, FilePptOutlined, LogoutOutlined, RiseOutlined, SettingOutlined, StockOutlined, UploadOutlined, BankOutlined } from '@ant-design/icons';
import { DefaultFooter, MenuDataItem ,  ProLayout } from '@ant-design/pro-components';
import { useNavigate } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { SummaryIcon } from "assets/svg/Summary";
import { PortfolioIcon } from 'assets/svg/Portfolio';
import { PositionIcon } from 'assets/svg/Positions';
import { ClientMonthlyIcon } from 'assets/svg/ClientMonthly';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { Button, Dropdown, Form, Input, MenuProps, message, Modal, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { AUTH_HOST } from 'api/api-config';
import axios, { Axios } from 'axios';
import { Logo, ShortLogo } from './logo';


const IconMap : Record<string, ReactElement> = {
  summary: <SummaryIcon />,
  portfolio: <PortfolioIcon />,
  positions: <PositionIcon />,
  cloud: <CloudOutlined />,
  upload: <UploadOutlined  />,
  report: <FilePptOutlined />,
  trades: <StockOutlined />,
  clientMonthly: <ClientMonthlyIcon />,
  blotterTrades: <RiseOutlined />,
  cms: <BankOutlined />
};



const defaultMenus = [
  {
    path: '/summary',
    name: 'Overview (T+1)',
    icon: "summary"
  },
  {
    path: '/portfolio',
    name: 'Analysis (T+1)',
    icon: "portfolio"
  },
  {
    path: '/positions/client',
    name: 'Position Search',
    icon: "positions"
  },
  {
    name:'Trade Search',
    icon:'trades',
    routes: [
      {
        path: '/trades',
        name: 'Blotter Trades',
        icon: "blotterTrades"
      },
      {
        path: '/trade-statements',
        name: 'Trade Statements',
        icon: "trades",
      },
    ]
  },
  {
    name: 'Ethan Cloud',
    icon: 'cloud',
    routes: [
      {
        path: '/upload',
        name: 'Upload',
        icon: 'upload',
      },
      // {
      //   path: '/reports',
      //   name: 'Reports',
      //   icon: "report"
      // },
      {
        path: '/clientMonthly',
        name: 'Client Monthly',
        icon: "clientMonthly",
      },
    ],
  },
  {
    path: '/crm',
    name: 'Client Information',
    icon: "cms"
  },
  {
    path: '/blotter',
    name: 'Blotter',
    icon: "blotterTrades"
  }
];

const loopMenuItem = (menus: any[]): MenuDataItem[] =>
  menus.map(({ icon, routes, ...item }) => ({
    ...item,
    children: routes && loopMenuItem(routes),
    icon: icon && IconMap[icon] as React.ReactNode,
  }));

interface ProLayloadProps {
  children: React.ReactNode;
}


const items: MenuProps['items'] = [
  {
    key: 'logout',
    label: "Logout",
    icon: <LogoutOutlined />,
  },
  {
    key: 'changePass',
    label: 'Change Password',
    icon: <StockOutlined />, 
  }
];

export default (props: ProLayloadProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const [displayLogo, setDisplayLogo] = React.useState(true);
  const [showLayout, setShowLayout] = React.useState(false);
  const [cookies, _setCookie, removeCookie] = useCookies([
    'ethan_token',
    'access',
    'refresh',
    'user_name',
    'preference',
    'upload_preference',
    'refresh_token',
    'access_token',
  ]);
  const currentYear = moment().format('YYYY');
  const dispatch = useDispatch();

  React.useEffect(() => {
    if(cookies?.ethan_token || cookies?.refresh_token ){
      setShowLayout(true);
    }else{
      setShowLayout(false);
    }
  }, [cookies]);


  const logout = () => {
    removeCookie("ethan_token");
    removeCookie("refresh_token");
    removeCookie("access_token");
    removeCookie("access");
    removeCookie("refresh");
    removeCookie("user_name");
    removeCookie("preference");
    removeCookie("upload_preference");
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
    setShowLayout(false);
    navigate("/sign-in");
  };

  const [isModalOpen, setIsModalOpen] = React.useState<any>(false);
  const [submitting, setSubmitting] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

 
  // eslint-disable-next-line no-return-await
  const handleOk = async (values: any) => await axios.put(`${AUTH_HOST}/api/change-password`, {
    old_password: values.old_password,
    new_password: values.new_password,
    confirm_new_password: values.confirm_new_password,
  }, {
    headers: {
      Authorization: `${cookies.ethan_token}`,
    },
  }).then((response) => {
    if(response.status === 200){
      message.success("Password changed successfully");
      setSubmitting(false);
      setIsModalOpen(false);
    }else{
      message.error("Something went wrong");
    }
  }).catch((error) => {
    setSubmitting(false);
    message.error(error.response.data.error || error.response.data.non_field_errors[0]);
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClickMenu = (e: any) => {
    if(e.key === 'logout'){
      logout();
    }
    if(e.key === 'changePass'){
      showModal();
    }
  }

  // show modal over ProLayout
  const modal = (
    <Modal
      title="Change Password"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={(values) => {
          setSubmitting(true);
          handleOk(values);
        }
        }      >
        <Form.Item
          label="Old Password"
          name="old_password"
          rules={[{ required: true, message: 'Please input your old password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="new_password"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirm_new_password"
          rules={[{ required: true, message: 'Please input your confirm password!' }]}
        >
          <Input.Password />
        </Form.Item>
       
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{margin:"10px 0px 0px 0px"}}>
          <Button type="primary" htmlType="submit" typeof='submit' loading={submitting}>
            Submit
          </Button>
          <Button onClick={handleCancel} style={{ marginLeft: '8px' }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );




  if(showLayout){
    return (
      <ProLayout
        contentStyle={{background:'#f0f2f5'}}
        title=""
        logo={displayLogo? Logo : ShortLogo}
        onCollapse={(value: boolean) =>setDisplayLogo(!value)}
        fixSiderbar
        // eslint-disable-next-line react/no-unstable-nested-components
        menuItemRender={(item) => (
          <div
            onClick={() => {navigate(item.path || '/summary')}}
          >
            <span style={{marginRight: "6px"}} >{item.icon}</span>{item.name}
          </div>
        )}
        menu={{ request: async () => loopMenuItem(defaultMenus) }}
        // eslint-disable-next-line react/no-unstable-nested-components
        menuFooterRender={() => 
          // Logout
          (
            <div style={{padding: "0 16px", float: "right"}}>
              <Dropdown menu={{items, onClick: onClickMenu}} placement="topLeft">
                <Space>
                  <SettingOutlined size={24} />
                </Space>
              </Dropdown>
            </div>
          )
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        footerRender={() => (
          <DefaultFooter
            links={[
              { key: 'faq', title: 'FAQ', href: 'https://www.ethan-ai.com/faq2' },
              { key: 'privacy', title: 'Privacy Policy', href: 'https://www.ethan-ai.com/privacy' },
            ]}
            copyright={`ETHAN-AI © ALL RIGHTS RESERVED ${currentYear}`}
          />
        )}
      >
        {modal}
        {children}
      </ProLayout>
    )
  }
  return (<>{children}</>)
  
};