import React, { useState } from "react";
import { Button, Form, Row, Col, Select, Input } from "antd";
import {
  IActivePositionFetchPayload,
  IActivePositionFilterParams,
  ICustodianInformation,
} from "../interfaces";

interface IFilterFormValues {
  assetClass: string;
  custodian: string;
  securityName: string;
  securityID: string;
}

interface IPositionsSearchProps {
  setTotalPositionsCount: React.Dispatch<React.SetStateAction<number>>;
  custodianList: ICustodianInformation[];
  assetClassList: string[];
  positionFilterActive: IActivePositionFilterParams | undefined;
  setPositionFilterActive: React.Dispatch<
    React.SetStateAction<IActivePositionFilterParams | undefined>
  >;
  updateActivePositions: (
    payload: IActivePositionFetchPayload
  ) => Promise<number>;
}

const PositionsFilter: React.FC<IPositionsSearchProps> = ({
  setTotalPositionsCount,
  custodianList,
  assetClassList,
  positionFilterActive,
  setPositionFilterActive,
  updateActivePositions,
}: IPositionsSearchProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: IFilterFormValues) => {
    // do not filter if all the values are empty
    if (
      !values.assetClass &&
      !values.custodian &&
      !values.securityName &&
      !values.securityID
    )
      return;

    const filterPayload = {
      asset_class: values.assetClass,
      custodian: values.custodian,
      security_id: values.securityID,
      security_name: values.securityName,
    };

    setLoading(true);

    updateActivePositions({ ...filterPayload, page: 1 })
      .then((totalCount) => setTotalPositionsCount(totalCount))
      .catch((e) => console.error(e))
      .finally(() => {
        setPositionFilterActive(filterPayload);
        setLoading(false);
      });
  };

  const handleReset = () => {
    form.resetFields();

    updateActivePositions({ page: 1 })
      .then((totalCount) => setTotalPositionsCount(totalCount))
      .catch((e) => console.error(e))
      .finally(() => {
        setPositionFilterActive(undefined);
        setLoading(false);
      });
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onReset={handleReset}
      layout="vertical"
    >
      <Row gutter={8}>
        <Col span={6}>
          <Form.Item
            name="custodian"
            label="Custodian"
            initialValue={positionFilterActive?.custodian}
          >
            <Select placeholder="Select Custodian (optional)" allowClear>
              {custodianList.map((custodian) => (
                <Select.Option
                  key={custodian.custodian_id}
                  value={custodian.custodian_name}
                >
                  {custodian.custodian_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="securityID"
            label="Security ID"
            initialValue={positionFilterActive?.security_id}
          >
            <Input type="text" placeholder="Enter security ID" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="securityName"
            label="Security Name"
            initialValue={positionFilterActive?.security_name}
          >
            <Input type="text" placeholder="Enter security name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="assetClass"
            label="Asset Class"
            initialValue={positionFilterActive?.asset_class}
          >
            <Select placeholder="Select Asset Class (optional)" allowClear>
              {assetClassList.map((assetClass) => (
                <Select.Option key={assetClass} value={assetClass}>
                  {assetClass}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ marginBottom: "10px" }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Filter
        </Button>
        <Button
          htmlType="reset"
          loading={loading}
          style={{ marginLeft: "10px" }}
        >
          Reset
        </Button>
      </div>
    </Form>
  );
};

export default PositionsFilter;
