export const PositionIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}}  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.844 18H4.5C3.121 18 2 16.879 2 15.5C2 14.121 3.121 13 4.5 13H11.5C13.43 13 15 11.43 15 9.5C15 7.57 13.43 6 11.5 6H6.639C6.27175 6.71988 5.81697 7.39164 5.285 8H11.5C12.327 8 13 8.673 13 9.5C13 10.327 12.327 11 11.5 11H4.5C2.019 11 0 13.019 0 15.5C0 17.981 2.019 20 4.5 20H14.093C13.6023 19.3828 13.1833 18.7118 12.844 18ZM3 0C1.346 0 0 1.346 0 3C0 6.188 3 8 3 8C3 8 6 6.187 6 3C6 1.346 4.654 0 3 0ZM3 4.5C2.80295 4.49993 2.60785 4.46106 2.42582 4.38559C2.2438 4.31012 2.07842 4.19954 1.93913 4.06016C1.79984 3.92078 1.68937 3.75533 1.61403 3.57325C1.53868 3.39118 1.49993 3.19605 1.5 2.999C1.50007 2.80195 1.53894 2.60685 1.61441 2.42482C1.68988 2.2428 1.80046 2.07742 1.93984 1.93813C2.07922 1.79884 2.24467 1.68837 2.42675 1.61303C2.60882 1.53768 2.80395 1.49893 3.001 1.499C3.39896 1.49913 3.78056 1.65735 4.06187 1.93884C4.34317 2.22033 4.50113 2.60204 4.501 3C4.50087 3.39796 4.34265 3.77956 4.06116 4.06087C3.77967 4.34217 3.39796 4.50013 3 4.5Z" fill="#AEAEAE"/>
    <path d="M17 12C15.346 12 14 13.346 14 15C14 18.188 17 20 17 20C17 20 20 18.187 20 15C20 13.346 18.654 12 17 12ZM17 16.5C16.803 16.4999 16.6078 16.4611 16.4258 16.3856C16.2438 16.3101 16.0784 16.1995 15.9391 16.0602C15.7998 15.9208 15.6894 15.7553 15.614 15.5733C15.5387 15.3912 15.4999 15.196 15.5 14.999C15.5001 14.802 15.5389 14.6068 15.6144 14.4248C15.6899 14.2428 15.8005 14.0774 15.9398 13.9381C16.0792 13.7988 16.2447 13.6884 16.4267 13.613C16.6088 13.5377 16.804 13.4989 17.001 13.499C17.399 13.4991 17.7806 13.6573 18.0619 13.9388C18.3432 14.2203 18.5011 14.602 18.501 15C18.5009 15.398 18.3427 15.7796 18.0612 16.0609C17.7797 16.3422 17.398 16.5001 17 16.5Z" fill="#AEAEAE"/>
  </svg>
)

export const PositionSelectedIcon = () => (
  <svg width="14" height="14" style={{display: 'inline'}} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.844 18H4.5C3.121 18 2 16.879 2 15.5C2 14.121 3.121 13 4.5 13H11.5C13.43 13 15 11.43 15 9.5C15 7.57 13.43 6 11.5 6H6.639C6.27175 6.71988 5.81697 7.39164 5.285 8H11.5C12.327 8 13 8.673 13 9.5C13 10.327 12.327 11 11.5 11H4.5C2.019 11 0 13.019 0 15.5C0 17.981 2.019 20 4.5 20H14.093C13.6023 19.3828 13.1833 18.7118 12.844 18ZM3 0C1.346 0 0 1.346 0 3C0 6.188 3 8 3 8C3 8 6 6.187 6 3C6 1.346 4.654 0 3 0ZM3 4.5C2.80295 4.49993 2.60785 4.46106 2.42582 4.38559C2.2438 4.31012 2.07842 4.19954 1.93913 4.06016C1.79984 3.92078 1.68937 3.75533 1.61403 3.57325C1.53868 3.39118 1.49993 3.19605 1.5 2.999C1.50007 2.80195 1.53894 2.60685 1.61441 2.42482C1.68988 2.2428 1.80046 2.07742 1.93984 1.93813C2.07922 1.79884 2.24467 1.68837 2.42675 1.61303C2.60882 1.53768 2.80395 1.49893 3.001 1.499C3.39896 1.49913 3.78056 1.65735 4.06187 1.93884C4.34317 2.22033 4.50113 2.60204 4.501 3C4.50087 3.39796 4.34265 3.77956 4.06116 4.06087C3.77967 4.34217 3.39796 4.50013 3 4.5Z" fill="#0FABE0"/>
    <path d="M17 12C15.346 12 14 13.346 14 15C14 18.188 17 20 17 20C17 20 20 18.187 20 15C20 13.346 18.654 12 17 12ZM17 16.5C16.803 16.4999 16.6078 16.4611 16.4258 16.3856C16.2438 16.3101 16.0784 16.1995 15.9391 16.0602C15.7998 15.9208 15.6894 15.7553 15.614 15.5733C15.5387 15.3912 15.4999 15.196 15.5 14.999C15.5001 14.802 15.5389 14.6068 15.6144 14.4248C15.6899 14.2428 15.8005 14.0774 15.9398 13.9381C16.0792 13.7988 16.2447 13.6884 16.4267 13.613C16.6088 13.5377 16.804 13.4989 17.001 13.499C17.399 13.4991 17.7806 13.6573 18.0619 13.9388C18.3432 14.2203 18.5011 14.602 18.501 15C18.5009 15.398 18.3427 15.7796 18.0612 16.0609C17.7797 16.3422 17.398 16.5001 17 16.5Z" fill="#0FABE0"/>
  </svg>
)
