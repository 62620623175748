/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../../api/api-config";

const initialnetWorthState = {
  netWorthLoader: false,
  loaded: false,
  netWorth: [""],
  error: "",
};

export const fetchnetWorth = createAsyncThunk(
  "netWorth/fetchnetWorth",
  (date) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .post(
        `${TRANSACTIONS_API_ROOT}/net_worth/`,
        {
          report_date: date,
        },
        {
          headers: {
            Accept: "*/*",
            "Authorization": ethan_token,
          },
        }
      )
      .then((response) => response.data)
      .then((resJson) => resJson.data);
  }
);
const netWorthSlice = createSlice({
  name: "netWorth",
  initialState: initialnetWorthState,
  extraReducers: (builder) => {
    builder.addCase(fetchnetWorth.pending, (state) => {
      state.netWorthLoader = true;
      state.loaded = false;
    });
    builder.addCase(fetchnetWorth.fulfilled, (state, action) => {
      state.netWorthLoader = false;
      state.netWorth = action.payload;
      state.loaded = true;
      state.error = "";
    });
    builder.addCase(fetchnetWorth.rejected, (state, action) => {
      state.netWorthLoader = false;
      state.netWorth = [];
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export default netWorthSlice.reducer;
