import { Input } from 'antd';

interface ISearchFieldProps {
  onSearch: any
}

const  SearchField =(props:ISearchFieldProps)=>  {
  const {onSearch} = props
  return (

    <Input name="search"  placeholder='Search Securities, description' onChange={onSearch} allowClear/>
    // <div>SearchField</div> 
  )
}

export default SearchField