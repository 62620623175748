import { ProColumns , ActionType } from '@ant-design/pro-components';
import React, { useRef, useState } from 'react';
import ClientFormSelect from 'views/Dashboard/Upload/Components/FormFields/ClientFormField';
import { Button, Tooltip } from 'antd';
import request from 'axios';

import Table from "components/Table/Table";
import { TRANSACTIONS_API_ROOT } from 'api/api-config';
import { getCookie } from 'redux/utils/helpers';
import { SortOrder } from 'antd/lib/table/interface';
import PreLoader from 'components/PreLoader/PreLoader';
import { getDownloadPath } from 'common/getDownloadPath';



export type ClientMonthlyTableData = {
  id: number;
  update_date: string;
  client_id: number;
  client_name: string;
  processing_month: string;
  type_of_process: string;
  num_processed: number;
  num_expected: number;
  process_stage: string;
  processing_status: string;
  download_url: string;
  reason: string;
  status_update_date: string;
  statement_type: string;
}


const columns: ProColumns<ClientMonthlyTableData>[] = [
  {
    dataIndex: 'client_name',
    title: "Client Name",
    width: 100,
    fixed: 'left',
    key: 'client_name',
    renderFormItem: (item, {onChange}) => <ClientFormSelect onChange={onChange} disabledLabel />,
  },
  {
    dataIndex: 'type_of_process',
    title: "Document Type",
    key: 'type_of_process',
    width: 150,
    search: false,
  },
  {
    dataIndex: 'processing_month',
    title: "Month",
    key: 'processing_month',
    width: 150,
    search: false,
  },
  {
    dataIndex: 'download_url',
    title: "Download",
    key: 'download_url',
    search: false,
    width: 150,
    render: (value) => (
      <Tooltip placement="top" title={value}>
        <Button onClick={async() => getDownloadPath(value as string)}>
          Download
        </Button>
      </Tooltip>
    ),
  }
];

const ClientMonthlyTable = () => {

  const pageSize = 10;
  const [isLoading, setIsLoading]=useState<boolean>(true);
  const actionRef = useRef<ActionType>();
  return (
    <>
      {isLoading && <PreLoader isLoaderOpen={isLoading}/>}
      <Table<ClientMonthlyTableData>
        columns={columns}
        cardBordered
        // eslint-disable-next-line default-param-last
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
          const ordering = Object.keys(sortObject).map((key) => {
            const value = sortObject[key];
            return `${value === "ascend" ? "" : "-"}${key}`;
          })?.join();

          const ethanToken = getCookie("ethan_token");
          const { pageSize: pageSizeLimit, current, ...customParams} = params;
          const response = await request.get(`${TRANSACTIONS_API_ROOT}/processing_status/`, {
            params: {
              ...customParams,
              limit: pageSizeLimit,
              ordering,
              offset: ((current as number) - 1) * (pageSizeLimit as number),
            },
            headers: {
              Accept: "*/*",
              "Authorization": ethanToken as unknown as string,
            },
          })
          const obj = {
            total: response.data.count,
            data: response.data.results,
          }
          setIsLoading(false);
          return obj;
        }}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'statement-table',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === 'get') {
              return {
                ...values
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize,
        }}
        dateFormatter="string"
        search={{
          filterType: 'light',
          collapsed: false,
          collapseRender: false,
        }}
        scroll={{ x: 1200 }}
      />
    </>
  );

}

export default ClientMonthlyTable;