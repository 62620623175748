import { ProTable, ActionType } from "@ant-design/pro-components";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { CurrencyValueComponent } from "views/Dashboard/Tables/statementPosition/components/CurrencyComponent";
import request from "axios";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

enum TYPES {
  WEEKLY = "1 Week",
  MONTHLY = "1 Month",
  QUARTERLY = "1 Quarter",
  YEARLY = "1 Year",
  DAILY = "1 Day",
}

interface IStatementLoser {
  active: string;
}

const StatementLoser = ({ active }: IStatementLoser) => {
  const netWorthLastDate = useSelector(
    (state: any) => state.networthDateReducer?.networthDate?.last_date
  );
  const RPTccy = useSelector((state: any) => state?.CurrencyRPTReducer?.rpt);
  const [cookies] = useCookies(["ethan_token"]);
  const [totalLoss, setTotalLoss] = useState(0);

  const { isLoading, data } = useQuery(
    ["loserTable", netWorthLastDate, active],
    async () => {
      const startDate = moment(netWorthLastDate).endOf("day").toISOString();
      let lastDate: any;
      switch (active) {
        case TYPES.WEEKLY:
          lastDate = moment(netWorthLastDate)
            .subtract(7, "days")
            .endOf("day")
            .toISOString();
          break;
        case TYPES.MONTHLY:
          lastDate = moment(netWorthLastDate)
            .subtract(1, "months")
            .endOf("day")
            .toISOString();
          break;
        case TYPES.QUARTERLY:
          lastDate = moment(netWorthLastDate)
            .subtract(3, "months")
            .endOf("day")
            .toISOString();
          break;
        case TYPES.YEARLY:
          lastDate = moment(netWorthLastDate)
            .subtract(1, "years")
            .endOf("day")
            .toISOString();
          break;
        case TYPES.DAILY:
          lastDate = moment(netWorthLastDate)
            .subtract(1, "days")
            .endOf("day")
            .toISOString();
          break;
        default:
          lastDate = moment(netWorthLastDate)
            .subtract(7, "days")
            .endOf("day")
            .toISOString();
      }
      const response = await request.get(
        `${TRANSACTIONS_API_ROOT}/positions/top_loser/`,
        {
          headers: {
            Accept: "*/*",
            Authorization: (cookies.ethan_token as unknown) as string,
          },
          params: {
            to_date: startDate,
            from_date: lastDate,
          },
        }
      );
      setTotalLoss(response.data?.total_loss);
      return response.data?.result;
    }
  );

  return (
    <ProTable
      headerTitle="Losers"
      loading={isLoading}
      options={false}
      search={false}
      rowClassName="h-40"
      dataSource={data}
      toolbar={{
        subTitle: (
          <div className="flex flex-row items-center">
            <span className="text-lg font-semibold">Total Loss: </span>
            <CurrencyValueComponent value={totalLoss as any} currency={RPTccy} />
          </div>
        ),
      }}
      columns={[
        {
          title: "Client",
          dataIndex: "client_name",
          key: "client_name",
        },
        {
          title: "Security",
          dataIndex: "sec_name",
          key: "sec_name",
        },
        {
          title: "MTM",
          dataIndex: "mtm_difference",
          key: "price",
          render: (text, record: any) => (
            <CurrencyValueComponent
              value={record?.mtm_difference}
              currency={RPTccy}
            />
          ),
        },
        {
          title: "P&L",
          dataIndex: "difference",
          key: "total_pl",
          render: (text, record: any) => (
            <CurrencyValueComponent
              value={record?.difference}
              currency={RPTccy}
            />
          ),
        },
      ]}
      pagination={{
        defaultPageSize: 5,
        showSizeChanger: false,
      }}
    />
  );
};

export default StatementLoser;
