/* eslint-disable no-dupe-else-if */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-param-last */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import { ActionType, LightFilter, ProColumns, ProSkeleton, ProTable,ProFormText, PageLoading, ProList, ProFormSelect } from "@ant-design/pro-components";
import {  Card, Col, Descriptions, Menu, Row,message,Input, Skeleton, Spin, Tag, Button, Typography, Space, Table, Statistic } from "antd";
import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import RcResizeObserver from "rc-resize-observer";
import { getCookie } from "redux/utils/helpers";
import CustodianFormSelect from "views/Dashboard/Upload/Components/FormFields/ClientCustodianFormField";
// import Assets from "../components/Assets";
import { TRANSACTIONS_API_ROOT } from "api/api-config";
import { MiniNetWorthPie } from "views/Dashboard/Tables/statementPosition/components/MiniNetWorthPie";
import { useStatePersist } from "use-state-persist";
import { useForm } from "antd/lib/form/Form";
import { SortOrder } from 'antd/lib/table/interface';
import request from 'axios';
import { BankOutlined, LikeOutlined, LoadingOutlined, MessageOutlined, StarOutlined, UserOutlined , PieChartOutlined, TagOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import ClientFormSelect from "views/Dashboard/Upload/Components/FormFields/ClientFormField";
import styled from 'styled-components';
import ClientRelationshipFormSelect from "views/Dashboard/Upload/Components/FormFields/ClientRelationshipFormField";
import SearchField from "./SearchField";
import AssetClassFilter from "./AssetClassFilter";
import {CurrencyValueComponent, NumberValueShortComponent} from "./CurrencyComponent";

const CardContent = styled.div`
  width: 100%;
  display: flex;
  gap: 0 5em;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1em 0;
  }
`;


interface PositionListProps {
  clientName: string;
  clientId: any;
  report_end_date: any;
  report_start_date: any;
  clientCurrency: string;
}

const PositionList = (props: PositionListProps) => {

  enum selected {
    ALL = "ALL",
  }

  const [assetData, setAssetData] = useState<any>([]);
  const ethanToken = getCookie("ethan_token");
  const [totalValue , setTotalValue] = useState(0);
  const [assetDetails, setAssetDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tableLoader, setTableLoader] = useState<boolean>(true);
  const actionRef = useRef<ActionType>();
  const [searchForm] = useForm();
  const [filterValue, setFilterValue] = useState<any>({});
  const [responsive, setResponsive] = useState<any>(false);
  const [selectedClient, setSelectedClient] = useState<any>(props.clientId);
  const [assetList, setAssetList] = useState<any>([]);
  const [currency, setCurrency] = useState<string>('');
  const [sortValue,setSortValue] = useState<any>('');
  const [totalData, setTotalData] = useState<any>([]);

  const sortValues = (value: string) => {
    setSortValue(value);
  }

  const onSearch = (e: any) => {
    if(e.target.value === '' || e.target.value === undefined || e.target.value === null){
      setFilterValue({
        ...searchForm.getFieldsValue(),
        search: null,
      });
    }else{
      setFilterValue({
        ...searchForm.getFieldsValue(),
        search: e.target.value,
      });
    }
  }


  const setSearchFilter = async (_?: any, allValues?: any) => {
    setSelectedClient(allValues?.client);
    if(filterValue?.client === selected.ALL){
      setFilterValue({
        ...allValues,
      });
    }else{
      setFilterValue({
        ...allValues,
      });
    }
  }

  
  const fetchOverAllSummaryData = async() => {
    setLoading(true);
    try{
      const summaryResponse = await request.get(
        `${TRANSACTIONS_API_ROOT}/positions/statements/overview/asset_class/`,
        {
          params: {
            report_end_date: props.report_end_date,
            report_start_date: props.report_start_date,
            custodian: filterValue?.custodian?.value || typeof filterValue?.custodian === 'number' ?  filterValue?.custodian : null,
          },
          headers: {
            Accept: "*/*",
            "Authorization": (ethanToken as unknown) as string,
          },
        }
      );
      setAssetData(summaryResponse.data?.data);
      setTotalData(summaryResponse.data?.data2);
      setCurrency(summaryResponse.data?.meta?.currency);
      setLoading(false);
    }catch(err){
      setLoading(false);
      setAssetData([])
    }
  }

  const fetchClientSummaryData = async() => {
    setLoading(true);
    try{
      if(Object.keys(filterValue).length === 0){
        const summaryResponse = await request.get(
          `${TRANSACTIONS_API_ROOT}/positions/statements/clients/${props.clientId}/net-worth-details/`,
          {
            params: {
              report_end_date: props.report_end_date,
              report_start_date: props.report_start_date,
              custodian: null,
            },
            headers: {
              Accept: "*/*",
              "Authorization": (ethanToken as unknown) as string,
            },
          }
        );
        const data = await summaryResponse.data;
        setCurrency(data?.meta?.currency);
        setAssetData(data?.data);
        setTotalData(data?.data2);
        setLoading(false);
      }else{
        const summaryResponse = await request.get(
          `${TRANSACTIONS_API_ROOT}/positions/statements/clients/${typeof selectedClient === 'object' ? props.clientId : selectedClient}/net-worth-details/`,
          {
            params: {
              report_end_date: props.report_end_date,
              report_start_date: props.report_start_date,
              custodian: filterValue?.custodian?.value || typeof filterValue?.custodian === 'number' ?  filterValue?.custodian : null,
            },
            headers: {
              Accept: "*/*",
              "Authorization": (ethanToken as unknown) as string,
            },
          }
        );
        const data = await summaryResponse.data;
        setCurrency(data?.meta?.currency);
        setAssetData(data?.data);
        setTotalData(data?.data2);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      setAssetData([])
    }
  }

  useEffect(() => {
    const {client} = searchForm.getFieldsValue();
    setSelectedClient(client?.value)
    if(client?.value === selected.ALL){
      fetchOverAllSummaryData();
    }else{
      fetchClientSummaryData();
    }
  },[]);

  useEffect(() => {
    if(filterValue?.client === selected.ALL || selectedClient === null){
      fetchOverAllSummaryData();
    }else if(filterValue?.client?.value === selected.ALL){
      fetchOverAllSummaryData();
    }
    else{
      fetchClientSummaryData();
    }
  },[filterValue.client,filterValue.custodian])

  const fetchOverAllData = async (asset?:any,search?:any) => {
    setTableLoader(true);
    try{
      const dataResponse = await request.get(
        `${TRANSACTIONS_API_ROOT}/positions/statements/overview/securities/`,
        {
          params: {
            report_end_date: props.report_end_date,
            report_start_date: props.report_start_date,
            asset_class: typeof asset === 'string' ? asset : null,
            search: search || null,
            ordering: sortValue,
            custodian: typeof filterValue?.custodian === 'number' ? filterValue?.custodian :  null,
          },
          headers: {
            Accept: "*/*",
            "Authorization": (ethanToken as unknown) as string,
          },
        });
      if(dataResponse){
        setTableLoader(false);
        setAssetDetails(dataResponse.data?.results);  
      }
      const assetClassResponse = await request.get(`${TRANSACTIONS_API_ROOT}/positions/statements/overview/securities/asset-list/`, {
        params: {
          report_end_date: props.report_end_date,
          report_start_date: props.report_start_date,
          asset_class: asset || null,
          search: search || null,
          custodian: filterValue?.custodian?.value || typeof filterValue?.custodian === 'number' ?  filterValue?.custodian : null,
        },
        headers: {
          Accept: "*/*",
          "Authorization": (ethanToken as unknown) as string,
        },
      });
      setAssetList(assetClassResponse.data?.asset_list)

    }catch(err){
      setTableLoader(false);
      setAssetDetails([]);
    }
  }
  const fetchData = async (asset?:any) => {
    setTableLoader(true);
    try{
      if(Object.keys(filterValue).length === 0){
        const dataResponse = await request.get( `${TRANSACTIONS_API_ROOT}/positions/statements/clients/${props.clientId}/`, {
          params: {
            asset_class: asset || null,
            report_end_date: props.report_end_date,
            report_start_date: props.report_start_date,
            custodian: null,
            ordering: sortValue,
          },  
          headers: {
            Accept: "*/*",
            "Authorization": ethanToken as unknown as string,
          },
        });
        if(dataResponse){
          setTableLoader(false);
          setAssetDetails(dataResponse.data?.results);
        }
        const clientAssetResponse = await request.get(`${TRANSACTIONS_API_ROOT}/positions/statements/clients/${props.clientId}/asset-list/`, {
          params: {
            asset_class: asset || null,
            report_end_date: props.report_end_date,
            report_start_date: props.report_start_date,
            custodian: null,
            search: filterValue?.search,

          },
          headers: {
            Accept: "*/*",
            "Authorization": ethanToken as unknown as string,
          },
        });
        setAssetList(clientAssetResponse.data?.asset_list);
      }else{ 
        const dataResponse = await request.get( `${TRANSACTIONS_API_ROOT}/positions/statements/clients/${typeof selectedClient === 'object' ? props.clientId : selectedClient}/`, {
          params: {
            report_end_date: props.report_end_date,
            report_start_date: props.report_start_date,
            asset_class: filterValue?.asset_class?.value || typeof filterValue?.asset_class === 'string' ?  filterValue?.asset_class : null,
            custodian: filterValue?.custodian?.value || typeof filterValue?.custodian === 'number' ?  filterValue?.custodian : null,
            relationship_number: filterValue?.relationship_no,
            search: filterValue?.search,
            ordering: sortValue,
          },  
          headers: {
            Accept: "*/*",
            "Authorization": ethanToken as unknown as string,
          },
        });
        if(dataResponse){
          setTableLoader(false);
          setAssetList(dataResponse.data?.asset_list)
          setAssetDetails(dataResponse.data?.results);
          const clientAssetResponse = await request.get(`${TRANSACTIONS_API_ROOT}/positions/statements/clients/${typeof selectedClient === 'object' ? props.clientId : selectedClient}/asset-list/`, {
            params: {
              asset_class: asset || null,
              report_end_date: props.report_end_date,
              report_start_date: props.report_start_date,
              custodian: filterValue?.custodian?.value || typeof filterValue?.custodian === 'number' ?  filterValue?.custodian : null,
              search: filterValue?.search,
            },
            headers: {
              Accept: "*/*",
              "Authorization": ethanToken as unknown as string,
            },
          });
          setAssetList(clientAssetResponse.data?.asset_list);
        }
      }  
    }catch(err){
      setTableLoader(false);
      setAssetList([]);
      setAssetDetails([]);
    }
    
  }


  useEffect(() => {
    const {client,asset_class} = searchForm.getFieldsValue();
    setSelectedClient(client?.value)

    if(client?.value === selected.ALL){
      fetchOverAllData(asset_class?.value);
    }else{
      fetchData(asset_class?.value);
    }
  },[])


  useEffect(() => {
    const {client,asset_class} = searchForm.getFieldsValue();
    const {search} = filterValue;
    if(filterValue?.client === selected.ALL || selectedClient === null){  
      fetchOverAllData(filterValue?.asset_class,search);
    }
    else if(filterValue?.client?.value === selected.ALL){
      fetchOverAllData(filterValue?.asset_class,search);
    }
    else if(client?.value === selected.ALL){
      fetchOverAllData(asset_class?.value);
    }
    else{
      fetchData();
    }
  },[filterValue,sortValue])



  

  function truncateString(str:string, num:number) {
    if (str?.length > num) {
      return `${str.slice(0, num)  }...`;
    } 
    return str;
    
  }

  const filteredData = assetData?.filter((item:any) => item?.asset_class !== 'Loan');
  const filteredData2 = assetData?.filter((item:any) => item?.asset_class === 'Loan');

  const columns = [
    {
      key: "asset_class",
      title: "Asset Class",
      dataIndex: "asset_class",
      render: (text:string,record: any) => (
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{text}</span>
          </div>
        </div>
      ) 
    },
    {
      key: "total_value",
      title: "Total Value",
      dataIndex: "total_value",
      render: (text:string,record :any ) => (
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm font-semibold">
              <CurrencyValueComponent
                currency={currency}
                value={text}
              /></span>
          </div>
        </div>
      )
     
    },
  ];

  const columns2 = [
    {
      key: "asset_class",
      title: "Liabilties",
      dataIndex: "asset_class",
      render: (text:string,record: any) => (
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{text}</span>
          </div>
        </div>
      ) 
    },
    {
      key: "total_value",
      title: "Total Value",
      dataIndex: "total_value",
      render: (text:string,record :any ) => (
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm font-semibold">
              <CurrencyValueComponent
                currency={currency}
                value={text}
              /></span>
          </div>
        </div>
      )
     
    },
  ];

  const columns3 = [
    {
      dataIndex: "type",
      title: "Total",
      key: "type",
    },
    {
      dataIndex: 'value',
      title: "Value",
      key: "value",
      render: (text:string,record :any ) => (
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm font-semibold">
              <CurrencyValueComponent
                currency={currency}
                value={text}
              /></span>
          </div>
        </div>
      )
    },
  ];

  return (
    <>
      <RcResizeObserver
        key="resize-observer"
        onResize={(offset) => {
          setResponsive(offset.width < 596);
        }}
      >
        <LightFilter
          form={searchForm}
          preserve
          onValuesChange={setSearchFilter}
          initialValues={{
            client: {
              value: props?.clientId ? props?.clientId : props?.clientName,
              label: props?.clientName,
            },
            custodian: { value: null, label: selected.ALL },
            asset_class: { value: null, label: selected.ALL },
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1em",
          }}
        >
          <Space direction={responsive ? "vertical" : "horizontal"}>
            <ClientFormSelect name="client" type={selected.ALL} />
            <CustodianFormSelect
              name="custodian"
              dependencies={["client"]}
              clientId={selectedClient}
              type={selected.ALL}
              // disable={selectedClient?.value === selected.ALL || selectedClient === selected.ALL}
            />
            <ClientRelationshipFormSelect
              dependencies={["client", "custodian"]}
              clientId={typeof selectedClient === 'object' ? props.clientId : selectedClient}
              custodianId={
                typeof filterValue?.custodian === "number"
                  ? filterValue?.custodian
                  : null
              }
              disable={
                selectedClient?.value === selected.ALL ||
                selectedClient === selected.ALL   
              }
            />
            <SearchField onSearch={onSearch} />
            <AssetClassFilter assetData={assetList} />
          </Space>
        </LightFilter>
      </RcResizeObserver>
      {/* <Card loading={loading} style={{ marginBottom: "1em" }}>
        <Row gutter={16}>
          <Col span={16}>
            <Row gutter={16} >
              <Col span={12}>
                <Table 
                  columns={columns}
                  dataSource={filteredData}
                  pagination={false}
                  size="small"
                />
              </Col>
              <Col span={12}>
                <Table 
                  columns={columns2}
                  dataSource={filteredData2}
                  pagination={false}
                  size="small"
                />
               
                <Table 
                  columns={columns3}
                  dataSource={totalData}
                  pagination={false}
                  size="small"
                  scroll={{ y: 240 }}
                  style={{
                    marginTop: '1em',
                  }}
                />

                {/* </Space> */}
      {/* </Col>
            </Row>
          </Col>
          <Col span={8}>
            <MiniNetWorthPie data={assetData} />
          </Col>
        </Row>
      </Card> */}
      <Card loading={loading} style={{ marginBottom: "1em" }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} xl={16}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} xl={12}>
                <Table 
                  columns={columns}
                  dataSource={filteredData}
                  pagination={false}
                  size="small"
                  style={{ marginBottom: '1em' }}
                  scroll={{ y: 240 }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} xl={12}>
                <Table 
                  columns={columns2}
                  dataSource={filteredData2}
                  pagination={false}
                  size="small"
                  scroll={{ y: 240 }}
                />
                <Table 
                  columns={columns3}
                  dataSource={totalData}
                  pagination={false}
                  size="small"
                  scroll={{ y: 240 }}
                  style={{ marginTop: '1em' }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} xl={8}>
            <MiniNetWorthPie data={assetData} />
          </Col>
        </Row>
      </Card>

      <ProList<{ title: string }>
        loading={tableLoader}
        itemLayout="vertical"
        rowKey="id"
        headerTitle="Position List"
        dataSource={assetDetails}
        pagination={{
          pageSize: 10,
        }}
        toolBarRender={() => [
          <ProFormSelect
            name="sort"
            label="Sort By"
            width={250}
            showSearch
            options={[
              {
                value: "rpt_ccy_unrealisedpl",
                label: "Unrealised Profit (low-high)",
              },
              {
                value: "-rpt_ccy_unrealisedpl",
                label: "Unrealised Profit (high-low)",
              },
              {
                value: "mtm_rpt_ccy",
                label: "Market Value (low-high)",
              },
              {
                value: "-mtm_rpt_ccy",
                label: "Market Value (high-low)",
              },
            ]}
            fieldProps={{
              onChange: sortValues,
            }}
          />,
        ]}
        metas={{
          extra: {
            render: (_text: any, record: any) => <></>,
          },
          content: {
            render: (_text: any, record: any) => (
              <CardContent>
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: "400px" }}
                >
                  <Typography.Text strong>{record?.sec_name}</Typography.Text>
                  <Space>
                    <Tag icon={<BankOutlined />}>
                      {truncateString(record?.custodian_name, 10)}
                    </Tag>
                    <Tag color="blue" icon={<UserOutlined />}>
                      {truncateString(record?.client_name, 10)}
                    </Tag>
                    <Tag color="gold" icon={<PieChartOutlined />}>
                      {truncateString(record?.asset_class, 10)}
                    </Tag>
                    <Tag color="volcano" icon={<TagOutlined />}>
                      {truncateString(record?.security_id, 10)}
                    </Tag>
                  </Space>
                </Space>
                <div>
                  <Row>
                    <Col>
                      <Typography.Text>
                        Quantity:{" "}
                        <NumberValueShortComponent
                          value={record.position_qty}
                        />
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row style={{ margin: "1em 0" }}>
                    <Col style={{ marginRight: "4em" }}>
                      <Typography.Text>
                        Average Price:{" "}
                        <CurrencyValueComponent
                          value={record.local_ccy_position_avg_price}
                          currency={record.ccy}
                        />
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        Market Price:{" "}
                        <CurrencyValueComponent
                          value={record.mtm_price}
                          currency={record.ccy}
                        />
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginRight: "4em" }}>
                      <Typography.Text>
                        Unrealised Profit:{" "}
                        <CurrencyValueComponent
                          value={record.rpt_ccy_unrealisedpl}
                          currency={record.rpt_ccy}
                        />
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        Market Value:{" "}
                        <CurrencyValueComponent
                          value={record.mtm_rpt_ccy}
                          currency={record.rpt_ccy}
                        />
                      </Typography.Text>
                    </Col>
                  </Row>
                </div>
              </CardContent>
            ),
          },
        }}
      />
    </>
  );
}

export default PositionList;


function integerValueShorter(qty: string): React.ReactNode | { children: React.ReactNode; props: any; } {
  throw new Error("Function not implemented.");
}
