/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
import { useEffect, useState } from "react";
import {  useSelector,useDispatch } from "react-redux";
import {Flex} from "@chakra-ui/react";
import { netWorthLastDate } from "redux/features/summary/newNetworth/networthDateSlice";
import TimeMachineDatePickerComponent from "components/Navbars/TimeMachineDatePickerComponent";
import { Col, Row } from "antd";
import { currencyRPT } from "redux/features/companyCurrency/clientCurrency";
import {clientCcy} from "redux/features/clientCurrency/clientCurrency";
import TradesPage from "views/Dashboard/Trades/Trades";
import SummaryGainerLoser from "views/Dashboard/Dashboard/SummaryPage/SummaryGainerLoser/SummaryGainerLoser";
import PreLoader from "components/PreLoader/PreLoader";
import { Navigate } from "react-router-dom";
import SummaryNetWorth from "./SummaryPage/SummaryNetWorthComponent/SummaryNetWorth";

export default function Dashboard() {
  const dispatch = useDispatch(); 
  const netWorthLastDateValue= useSelector((state) => state?.networthDateReducer?.networthDate?.last_date);
  const isLoading = useSelector((state) => state?.networthDateReducer?.lastDateLoader);

  useEffect(() => {

    // Other Redux calls...
    if (!netWorthLastDateValue) {
      dispatch(netWorthLastDate());
    }
    dispatch(currencyRPT());
    dispatch(clientCcy());

  }, []);

  if (isLoading) {
    return <PreLoader isLoaderOpen={isLoading} />;
  }

  return (
    <Flex flexDirection="column" pt={{ base: "2rem", md: "1rem" }} gap="1.5rem">
        
      <>
        <Row>
          <Col span={8} offset={16}>
            <div style={{float:"right"}}>
              <TimeMachineDatePickerComponent handleChangeDate={(val,changedDate)=> {
                dispatch(netWorthLastDate(changedDate));
                dispatch({ type: "reset", payload: changedDate });
              }} value={netWorthLastDateValue}/>
            </div> 
          </Col>
        </Row>
        <SummaryNetWorth />
        <SummaryGainerLoser />
        <TradesPage drawerOff={false}/>
      </>
    </Flex> 
  );
}

