import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { generate, presetDarkPalettes } from '@ant-design/colors';
// eslint-disable-next-line import/no-unresolved
import { integerValueShorter, NumberValueShortComponent } from 'views/Dashboard/Tables/statementPosition/components/CurrencyComponent';
import { Button, Empty, Modal, Spin, Typography } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCookie } from 'redux/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { ANALYTICS_HOST } from "api/api-config";

const { Title } = Typography;

interface Props {
  data2: any;
  color?: any;
  showLegend?: boolean;
  title: string;
  loading?: boolean;
}


const columns: ProColumns[] = [
  {
    title: 'Client Name',
    dataIndex: 'client_name',
  },
  {
    title: 'Security ID',
    dataIndex: 'security_id',
  },
  {
    title: 'Security Name',
    dataIndex: 'sec_name',
  },
  {
    title: 'Position Quantity',
    dataIndex: 'position_qty',
    valueType: 'digit',
  },
  {
    title: 'Position Avg Price',
    dataIndex: 'position_avg_price',
    valueType: 'money',
  },
  {
    title: 'MTM Price',
    dataIndex: 'mtm_price',
    valueType: 'money',
  },
  {
    title: 'MTM Local CCY',
    dataIndex: 'mtm_local_ccy',
    valueType: 'money',
  },
  {
    title: 'MTM RPT CCY',
    dataIndex: 'mtm_rpt_ccy',
    valueType: 'money',
  },
  {
    title: 'Realised PL',
    dataIndex: 'realisedpl',
    valueType: 'money',
  },
  {
    title: 'Unrealised PL',
    dataIndex: 'unrealisedpl',
    valueType: 'money',
  },
  {
    title: 'Custodian',
    dataIndex: 'custodian',
  },
  {
    title: 'Accrued Interest',
    dataIndex: 'accrued_interest',
    valueType: 'money',
  },
];


const DemoPie = (props: Props) => {
  const { data2, showLegend = false, title, loading } = props;
  const ethanToken = getCookie("ethan_token");
  const [assetType, setAssetType] = useState("");
  const selectedClientId = useSelector((state: any) => state?.SelectedClientReducer?.client?.selectedClientId)
  const selectedCustodianId = useSelector((state: any) => state?.SelectedCustodian?.custodian)
  // const lastDate = useSelector((state: any) => state.lastDate.lastDate);
  const lastDate = useSelector((state: any) => state?.networthDateReducer?.networthDate?.last_date)
  let legend;
  let label;


  if (showLegend === true) {
    legend = {
      visible: true,
      position: 'right',
      flipPage: false,
    } as any;
    label = {
      type: 'spider',
      offset: '1%',
    } as any;
  }
  else {
    legend = false as any;
    label = false as any;
  }


  const graphData = data2?.map((item: any) => ({
    type: item?.asset_class || item?.industry || item?.region,
    value: Math.abs(parseInt(item?.value || `0`, 10)),
  })) || [];

  const graphTotal = data2?.reduce((acc: number, item: Record<string, number> | undefined) => acc + (item?.value || 0), 0) || 0

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const getDrillDownData = async () => {
    const response = await axios.post(
      `${ANALYTICS_HOST}/api/gross_allocation/drilldown`,
      {
        "parameter": {
          report_date: lastDate,
          client_id: selectedClientId,
          custodian_id: selectedCustodianId,
          gross_allocation_type: title === 'Region' ? 'country' : title.toLowerCase().replace(/ /g, "_"),
          asset_type: assetType
        },
      },
      {
        headers: {
          ethantoken: (ethanToken as unknown) as string,
        },
      }
    );
    return response?.data?.result[0]?.drilldown_data || [];
  }

  const {
    isLoading: drillDownLoader,
    data: drillDownData,
    isError: drillDownError,
  } = useQuery({
    queryKey: [
      "DrillDown",
      selectedClientId,
      selectedCustodianId,
      lastDate,
      title,
      assetType,
      isModalOpen
    ],
    queryFn: getDrillDownData,
    enabled: isModalOpen && !!selectedClientId && !!selectedCustodianId && !!lastDate && !!title && !!assetType
  });


  const config = {
    // title,
    // header: title,
    title: {
      visible: true,
      text: title,
      style: {
        fontSize: '0.8rem',
        color: 'Black',
      },
    },
    tooltip: {
      fields: ['x', 'y'],
    } as any,
    header: {
      visible: true,
      title: {
        visible: true,
        text: title,
        style: {
          fontSize: '0.8rem',
          color: 'Black',
        },
      },
    },
    appendPadding: 10,
    data: graphData,
    angleField: 'value',
    colorField: `type`,
    radius: 0.75,
    innerRadius: 0.64,
    label,
    legend,
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontSize: '0.8rem',
          color: 'Black',
        },
      },
      content: {
        offsetY: 4,
        formatter(datum?: Record<string, string | number>) {
          return integerValueShorter(datum?.value || graphTotal)
        },
        style: {
          fontSize: '0.8rem',
          color: 'Black',
        },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
      {
        type: 'tooltip',
      }
    ],

    onReady: (plot: any) => {
      plot.on('element:click', (evt: any) => {
        setAssetType(evt.data.data.type)
        // eslint-disable-next-line no-lone-blocks, no-unused-expressions
        { !showLegend && showModal() }
        // showModal();
      });
    },

  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Title level={4} style={{ textAlign: "center" }}>
            {title}
          </Title>
          <Pie {...config} />
        </div>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1200}
          bodyStyle={{ height: "80vh" }}
          footer={null}
        >
          <ProTable
            headerTitle={assetType}
            columns={columns}
            dataSource={drillDownData}
            loading={drillDownLoader}
            toolBarRender={false}
            search={false}
            scroll={{ y: 500 }}
            pagination={{
              pageSize: 4,
            }}
          />
        </Modal>
      </>
    </>
  );
};



export default DemoPie;
