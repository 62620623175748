/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/helpers";
import { TRANSACTIONS_API_ROOT } from "../../../api/api-config";

const initialDtdPerformance = {
  dtdPerformanceLoader: false,
  dtdPerformance: [],
  error: "",
};
export const fetchdtdPerformance = createAsyncThunk(
  "dtdPerformance/fetchdtdPerformance",
  ({ date }) => {
    const ethan_token = getCookie("ethan_token");
    return axios
      .get(
        `${TRANSACTIONS_API_ROOT}/performance/?start_date=${date}&end_date=${date}`,
        {
          headers: {
            "Authorization": ethan_token,
          },
        }
      )
      .then(async (response) => response.data.data);
  }
);
const dtdPerformanceSlice = createSlice({
  name: "dtdPerformance",
  initialState: initialDtdPerformance,
  extraReducers: (builder) => {
    builder.addCase(fetchdtdPerformance.pending, (state) => {
      state.dtdPerformanceLoader = true;
    });
    builder.addCase(fetchdtdPerformance.fulfilled, (state, action) => {
      state.dtdPerformanceLoader = false;
      state.dtdPerformance = action.payload;
      state.error = "";
    });
    builder.addCase(fetchdtdPerformance.rejected, (state, action) => {
      state.dtdPerformanceLoader = false;
      state.dtdPerformance = [];
      state.error = action.error.message;
    });
  },
});

export default dtdPerformanceSlice.reducer;
